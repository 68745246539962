import Entity from "./entity.model";

const SUPPORTED_ATTRS = [
  "id",
  "address",
  "_destroy",
  "clientId",
  "createdAt",
  "updatedAt",
];

export class EmailModel extends Entity {
  constructor(attrs = {}) {
    super(attrs, SUPPORTED_ATTRS);
  }

  toJSON() {
    return {
      id: this.id,
      address: this.address,
    };
  }
}
