
import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  set_regions: [],
  create_regions_item: [],
  update_regions_item: [],
  clear_regions: [],
  delete_region: ['value'],
})

const INITIAL_STATE = []

const set_regions = (state = INITIAL_STATE, action) => {
  return action.value
}

const update_regions_item = (state = INITIAL_STATE, action) => {
  return state.map(region => {
    if (region.id === action.value.id) {
      return action.value
    }
    return region
  })
}

const create_regions_item = (state = INITIAL_STATE, action) => {
  return [action.value, ...state]
}

const clear_regions = (state = INITIAL_STATE, action) => {
  return INITIAL_STATE
}

const delete_region = (state = INITIAL_STATE, action) => {
  return state.filter(region => region.id !== action.value)
}

export default createReducer(INITIAL_STATE,
  {
    [Types.SET_REGIONS]: set_regions,
    [Types.CREATE_REGIONS_ITEM]: create_regions_item,
    [Types.UPDATE_REGIONS_ITEM]: update_regions_item,
    [Types.CLEAR_REGIONS]: clear_regions,
    [Types.DELETE_REGION]: delete_region
  })
