
import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  set_stores: [],
  create_stores_item: [],
  update_stores_item: [],
  clear_stores: [],
  delete_store: ['value'],
})

const INITIAL_STATE = []

const set_stores = (state = INITIAL_STATE, action) => {
  return action.value
}

const update_stores_item = (state = INITIAL_STATE, action) => {
  return state.map(store => {
    if (store.id === action.value.id) {
      return action.value
    }
    return store
  })
}

const create_stores_item = (state = INITIAL_STATE, action) => {
  return [action.value, ...state]
}

const clear_stores = (state = INITIAL_STATE, action) => {
  return INITIAL_STATE
}

const delete_store = (state = INITIAL_STATE, action) => {
  return state.filter(store => store.id !== action.value)
}

export default createReducer(INITIAL_STATE,
  {
    [Types.SET_STORES]: set_stores,
    [Types.CREATE_STORES_ITEM]: create_stores_item,
    [Types.UPDATE_STORES_ITEM]: update_stores_item,
    [Types.CLEAR_STORES]: clear_stores,
    [Types.DELETE_STORE]: delete_store
  })
