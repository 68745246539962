import React from "react";
import { Table, TableRow, TableBody, TableCell, TableHead, TableContainer, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faSortAsc, faSortDesc } from "@fortawesome/free-solid-svg-icons";


import {
  AddIconButton,
  DeleteIconButton,
  EditIconButton,
  TablePaginationComponent,
  TableRowLoadingComponent
} from "../../../shared/components";
import { RestrictedDatesRepository } from "../../../shared/repositories";
import { RestrictedDateModel } from "../../../shared/models";
import { useDrawerRightContext } from "../../../shared/contexts/drawer_right.context";
import { removeSpecialChars, isValidDate } from "../../../shared/helpers";
import Swal from "sweetalert2";

const useStyles = makeStyles(theme => ({
  noWrap: {
    overflow: 'hidden',
    whiteSpace: "nowrap",
    maxWidth: '100px',
    textOverflow: 'ellipsis'
  },
  tableBody: {
    overflowX: "auto",
    maxHeight: 500
  },
  tableCell: {
    whiteSpace: "nowrap",
  },
}));

export function RestrictedDatesListComponent({ loading, restrictedDates = [], filters = {}, setFilters = () => { }, setRestrictedDates = () => { }, setLoading = () => { } }) {
  const restrictedDatesRepository = new RestrictedDatesRepository();
  const [pagination, setPagination] = React.useState({ page: 1, perPage: 10, total: 0 });
  const classes = useStyles();
  const { setNewDrawerValue } = useDrawerRightContext();

  const viewMore = restrictedDate => e => {
    e.preventDefault();
    setNewDrawerValue(restrictedDate.toState())
  }

  const newItem = e => {
    e.preventDefault();
    setNewDrawerValue(new RestrictedDateModel().toState())
  }

  const deleteItem = restrictedDate => e => {
    return Swal.fire({
      title: 'Confirmar?',
      showDenyButton: true,
      showCancelButton: true,
      icon: 'warning',
      confirmButtonText: 'Deletar',
      denyButtonText: 'Cancelar',
      confirmButtonColor: "#DD6B55",
      html: `<b>Tem certeza que deseja deletar o motivo <u>${restrictedDate.reason}</u>?</b>`,
    }).then((result) => {
      if (result.isConfirmed)
        return restrictedDatesRepository.delete(restrictedDate.id)
          .then(_ => {
            Swal.fire({
              title: 'Sucesso!',
              text: 'Data restrita deletada com sucesso!',
              icon: 'success',
              confirmButtonText: 'Fechar',
              confirmButtonColor: "#DD6B55",
            })
              .then(_ => {
                setFilters({ ...filters, page: 1 })
              })
          })
      else
        return Promise.reject('')
    })
  }

  React.useEffect(() => {
    setLoading(true)
    const timer = setTimeout(() => {
      getRestrictedDates()
    }, 1000);
    return () => clearTimeout(timer);
  }, [pagination.page, pagination.perPage, filters]);

  const getRestrictedDates = () => {
    restrictedDatesRepository.getAll({
      queryParams: resolveFilters(),
      page: pagination.page,
      perPage: pagination.perPage,
      orderBy: filters.orderBy,
      orderDirection: filters.orderDirection
    })
      .then(result => {
        setRestrictedDates(RestrictedDateModel.fromArray(result.data))
        setPagination({ ...pagination, total: result.total })
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => setLoading(false))
  }

  const resolveFilters = () => {
    let query = [];

    if (filters.reason) {
      query.push(`reason=${removeSpecialChars(filters.reason)}`)
    }

    if (isValidDate(filters.startDate)) {
      query.push(`start_date=${filters.startDate.toLocaleDateString("pt-BR")}`)
    }

    if (isValidDate(filters.endDate)) {
      query.push(`end_date=${filters.endDate.toLocaleDateString("pt-BR")}`)
    }

    if (filters.status !== "all") {
      query.push(`status=${filters.status}`)
    }

    if (filters.regionId !== "all") {
      query.push(`region_id=${filters.regionId}`)
    }

    return query.join("|");
  }

  const orderBy = (orderBy) => e => {
    setFilters({ ...filters, orderBy: orderBy, orderDirection: filters.orderDirection === 'asc' ? 'desc' : 'asc' })
  }

  const SortIcon = ({ orderBy }) => {
    if (filters.orderBy === orderBy) {
      if (filters.orderDirection === 'asc') {
        return <FontAwesomeIcon icon={faSortAsc} />
      } else {
        return <FontAwesomeIcon icon={faSortDesc} />
      }
    } else {
      return <FontAwesomeIcon icon={faSort} />
    }
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left" colSpan={1}><AddIconButton onClick={newItem} /></TableCell>
            <TableCell align="right" colSpan={4}>{restrictedDates.length} itens</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCell}></TableCell>
            <TableCell align="center" onClick={orderBy('name')} className={classes.tableCell}>Nome <SortIcon orderBy="name" /></TableCell>
            <TableCell align="center" onClick={orderBy('start_date')} className={classes.tableCell}>Data Início <SortIcon orderBy="start_date" /></TableCell>
            <TableCell align="center" onClick={orderBy('end_date')} className={classes.tableCell}>Data Fim <SortIcon orderBy="end_date" /></TableCell>
            <TableCell align="center" onClick={orderBy('status')} className={classes.tableCell}>Status <SortIcon orderBy="status" /></TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {loading ?
            <TableRowLoadingComponent cols={5} />
            : restrictedDates.map(row => (
              <TableRow key={row.id} className={classes.tableRow}>
                <TableCell className={classes.tableCell}>
                  <DeleteIconButton onClick={deleteItem(row)} />
                  <EditIconButton onClick={viewMore(row)} />
                </TableCell>
                <TableCell className={classes.noWrap}><b>{row.reason}</b></TableCell>
                <TableCell className={classes.noWrap}>{row.startDateFormatted}</TableCell>
                <TableCell className={classes.noWrap}>{row.endDateFormatted}</TableCell>
                <TableCell className={classes.noWrap}>{row.resolvedStatusName}</TableCell>
              </TableRow>
            ))}
          {!loading && !restrictedDates[0] ? <TableRow key="none" className={classes.tableRow}>
            <TableCell align="center" component="th" scope="row" colSpan="5" style={{ height: "300px" }}>
              <b>Não há datas cadastradas</b>
            </TableCell>
          </TableRow> : null}
        </TableBody>
        <TablePaginationComponent
          colSpan={5}
          count={pagination.total}
          rowsPerPage={pagination.perPage}
          page={pagination.page}
          loading={loading}
          onPageChange={value => { setPagination({ ...pagination, page: value }) }}
          onRowsPerPageChange={value => { setPagination({ ...pagination, page: 1, perPage: value }) }}
        />
      </Table>
    </TableContainer >)
}
