import React from "react";
import { Table, TableRow, TableBody, TableCell, TableHead, TableContainer, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faSortAsc, faSortDesc } from "@fortawesome/free-solid-svg-icons";

import {
  TableRowLoadingComponent,
  EditIconButton
} from "../../../shared/components";
import { ClientsRepository } from "../../../shared/repositories";
import { useDrawerRightContext } from "../../../shared/contexts/drawer_right.context";
import { removeSpecialChars } from "../../../shared/helpers";
import { ClientModel } from "../../../shared/models";

const useStyles = makeStyles(theme => ({
  noWrap: {
    whiteSpace: "nowrap"
  },
  tableBody: {
    overflowX: "auto",
    maxHeight: 500
  }
}));

export function ClientsListComponent({ loading, clients = [], filters = {}, setFilters = () => { }, setClients = () => { }, setLoading = () => { } }) {
  const clientsRepository = new ClientsRepository();
  const [pagination, setPagination] = React.useState({ page: 1, perPage: 10, total: 0 });
  const classes = useStyles();
  const { setNewDrawerValue } = useDrawerRightContext();

  const viewMore = client => e => {
    e.preventDefault();
    setNewDrawerValue(client.toState())
  }

  React.useEffect(() => {
    setLoading(true)
    const timer = setTimeout(() => {
      getClients()
    }, 1000);
    return () => clearTimeout(timer);
  }, [pagination.page, pagination.perPage, filters]);

  const getClients = () => {
    clientsRepository.getAll({
      queryParams: resolveFilters(),
      page: pagination.page,
      perPage: pagination.perPage,
      orderBy: filters.orderBy,
      orderDirection: filters.orderDirection
    })
      .then(result => {
        setClients(ClientModel.fromArray(result.data))
        setPagination({ ...pagination, total: result.total })
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => setLoading(false))
  }

  const resolveFilters = () => {
    let query = [];

    if (filters.name) {
      query.push(`name=${removeSpecialChars(filters.name)}`)
    }

    if (filters.phoneNumber) {
      query.push(`phone_number=${filters.phoneNumber}`)
    }

    if (filters.status && filters.status !== "all") {
      query.push(`status=${filters.status}`)
    }

    return query.join("|");
  }

  const orderBy = (orderBy) => e => {
    setFilters({ ...filters, orderBy: orderBy, orderDirection: filters.orderDirection === 'asc' ? 'desc' : 'asc' })
  }

  const SortIcon = ({ orderBy }) => {
    if (filters.orderBy === orderBy) {
      if (filters.orderDirection === 'asc') {
        return <FontAwesomeIcon icon={faSortAsc} />
      } else {
        return <FontAwesomeIcon icon={faSortDesc} />
      }
    } else {
      return <FontAwesomeIcon icon={faSort} />
    }
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="right" colSpan={5}>{clients.length} itens</TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="left" onClick={orderBy('name')} className={classes.noWrap}>Nome <SortIcon orderBy="name" /></TableCell>
            <TableCell align="left">Telefone</TableCell>
            <TableCell align="left">E-mail</TableCell>
            <TableCell align="left" onClick={orderBy('status')} className={classes.noWrap}>Status <SortIcon orderBy="status" /></TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {loading ?
            <TableRowLoadingComponent cols={5} />
            : clients.map(row => (
              <TableRow key={row.id} className={classes.tableRow}>
                <TableCell className={classes.tableCell}>
                  <EditIconButton onClick={viewMore(row)} />
                </TableCell>
                <TableCell align="left" className={classes.noWrap}><b>{row.name}</b></TableCell>
                <TableCell align="left" className={classes.noWrap}>{row.phoneNumber}</TableCell>
                <TableCell align="left" className={classes.noWrap}>{row.emailAddress}</TableCell>
                <TableCell align="left" className={classes.noWrap}>{row.statusName}</TableCell>
              </TableRow>
            ))}
          {!loading && !clients[0] ? <TableRow key="none" className={classes.tableRow}>
            <TableCell component="th" scope="row" colSpan="5" align="center" style={{ height: "300px" }}>
              <b>Não há dados para exibir</b>
            </TableCell>
          </TableRow> : null}
        </TableBody>
      </Table>
    </TableContainer >)
}
