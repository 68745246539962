import translations from './string.js'

export const t = (path) => {
  let lang = translations['pt-br'];
  path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  path = path.replace(/^\./, '');           // strip a leading dot
  var a = path.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i];
      if (k in lang) {
          lang = lang[k];
      } else {
          return;
      }
  }
  return lang;
}
