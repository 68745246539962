import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom"
import { List, ListItem, ListItemIcon, ListItemText, Divider, Tooltip, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruckLoading, faFileExcel, faCalendar, faBuilding, faSitemap, faVectorSquare, faUsers, faIdCard, faGauge, faTruckRampBox, faStore, faMapLocation, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { grey } from "@material-ui/core/colors";

import { IconButton } from "../";
import { Constants } from "../../helpers";

const enterDelay = 500;

const useStyles = makeStyles(theme => ({
  listItem: {
    height: "50px",
    "&:hover": {
      backgroundColor: grey[100],
      color: "#377CC9",
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  inline: {
    display: "inline-flex",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "-webkit-fill-available",
  },
  gridText: {
    display: "flex",
    alignItems: "center",
  },
  reportIcon: {
    fontSize: "1.5em"
  },
  icon: {
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  bottom: {
    position: "fixed",
    bottom: 0,
    textAlign: "center",
    paddingBottom: 10,
  }
}));

export function LeftNavbarComponent({ open = true, handleDrawer = () => { } }) {
  const auth = useSelector(state => state.auth);
  const classes = useStyles();
  const items = [
    { name: "Dashboard", icon: <FontAwesomeIcon icon={faGauge} fixedWidth className={classes.reportIcon} />, link: "/" },
    { name: "Entregas", icon: <FontAwesomeIcon icon={faTruckLoading} fixedWidth className={classes.reportIcon} />, link: "/entregas" },
    { name: "Separações", icon: <FontAwesomeIcon icon={faSitemap} fixedWidth className={classes.reportIcon} />, link: "/separacoes" },
    { name: "Veículos", icon: <FontAwesomeIcon icon={faTruckRampBox} fixedWidth className={classes.reportIcon} />, link: "/veiculos" },
    { name: "Clientes", icon: <FontAwesomeIcon icon={faUsers} fixedWidth className={classes.reportIcon} />, link: "/clientes" },
    { name: "Lojas", icon: <FontAwesomeIcon icon={faStore} fixedWidth className={classes.reportIcon} />, link: "/lojas" },
    { name: "Regiões", icon: <FontAwesomeIcon icon={faMapLocation} fixedWidth className={classes.reportIcon} />, link: "/regioes" },
    { name: "Datas Bloqueadas", icon: <FontAwesomeIcon icon={faCalendar} fixedWidth className={classes.reportIcon} />, link: "/datas-bloqueadas" },
    { name: "Relatórios", icon: <FontAwesomeIcon icon={faFileExcel} fixedWidth className={classes.reportIcon} />, link: "/relatorios" },
    { name: "Setores", icon: <FontAwesomeIcon icon={faVectorSquare} fixedWidth className={classes.reportIcon} />, link: "/setores" },
    { name: "Usuários", icon: <FontAwesomeIcon icon={faIdCard} fixedWidth className={classes.reportIcon} />, link: "/usuarios" },
    { name: "Empresas", icon: <FontAwesomeIcon icon={faBuilding} fixedWidth className={classes.reportIcon} />, link: "/empresas" },
  ];

  const authorizedItems = {
    [Constants.User.Role.System]: items,
    [Constants.User.Role.Owner]: items,
    [Constants.User.Role.Admin]: items.filter(item => !["Empresas"].includes(item.name)),
    [Constants.User.Role.Checker]: items.filter(item => !["Empresas"].includes(item.name)),
    [Constants.User.Role.Seller]: items.filter(item => ["Dashboard", "Entregas"].includes(item.name)),
    [Constants.User.Role.Separator]: items.filter(item => ["Dashboard", "Separações"].includes(item.name)),
    [Constants.User.Role.ChiefDriver]: items.filter(item => ["Dashboard", "Entregas", "Veículos", "Usuários"].includes(item.name)),
    [Constants.User.Role.Driver]: [],
  }

  const resolveListItem = (item) => {
    return <Tooltip key={item.name} title={item.name} enterDelay={enterDelay}>
      <ListItem button component={Link} to={item.link} className={classes.listItem}>
        <Grid container spacing={1} style={{ display: 'contents' }}>
          <Grid item xs={3}>
            <ListItemIcon className={classes.icon}>{item.icon}</ListItemIcon>
          </Grid>
          <Grid item xs={9} className={classes.gridText}>
            <ListItemText primary={open ? item.name : ''} />
          </Grid>
        </Grid>
      </ListItem>
    </Tooltip>
  }

  return (
    <div>
      <div className={classes.toolbar}>
        <IconButton>{open ? <FontAwesomeIcon icon={faChevronLeft} onClick={handleDrawer} /> : null}</IconButton>
      </div>
      <Divider />
      <List>
        {authorizedItems[auth.role].map(item => resolveListItem(item))}
        <Divider />
      </List>
    </div>
  )
}
