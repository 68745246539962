import React from "react";
import { Table, TableRow, TableBody, TableCell, TableHead, Typography, Card, CardActions, CardContent, Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { green, red } from "@material-ui/core/colors";
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from "notistack";
import clsx from "clsx";
import Axios from "axios";

import {
  AddIconButton,
  DeleteIconButton,
  HistoryIconButton,
  IconButton,
  EditIconButton,
  NumberZipCodeInputFieldComponent,
  TextInputFieldComponent
} from "../";

const useStyles = makeStyles(theme => ({
  root: {
  },
  card: {
    maxWidth: '100%',
  },
  cardDelete: {
    backgroundColor: red[900],
    color: 'white',
  },
  inputAdd: {
    color: green[900],
  },
  noWrap: {
    flexWrap: 'nowrap',
  },
  cell: {

  },
  actions: {
    marginLeft: 'auto',
  },
}));

export function AddressList(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { addresses } = props;
  const handleChangeModel = props.handleChange;
  const classes = useStyles();
  const [disableZipCode, setDisableZipCode] = React.useState(false);

  const handleChangeAddresses = (addresses) => {
    handleChangeModel(addresses);
  }

  const handleChange = (index, name) => event => {
    const newAddressList = [...addresses];
    newAddressList[index][name] = event.target.value;
    handleChangeAddresses(newAddressList);
  }

  const addAddress = () => {
    handleChangeAddresses([...(addresses || []), { edit: true }]);
  }

  const rollbackAddress = (index) => {
    const newAddressList = [...addresses];
    newAddressList[index]._destroy = null;
    handleChangeAddresses(newAddressList);
  }

  const editAddress = (index) => {
    const newAddressList = [...addresses];
    delete newAddressList[index]._destroy;
    newAddressList[index].edit = true;
    handleChangeAddresses(newAddressList);
  }

  const removeAddress = (index) => {
    const newAddressList = [...addresses];
    delete newAddressList[index].edit
    newAddressList[index].id ? newAddressList[index]._destroy = true : newAddressList.splice(index, 1);
    handleChangeAddresses(newAddressList);
  }

  const getZipCode = index => {
    const newAddressList = [...addresses];
    const address = newAddressList[index]
    const zipcode = address.zip_code.replace(/\D/g, "");
    setDisableZipCode(true);
    Axios.get(`https://api.hubcoapp.com.br/zipcodes/${zipcode}`)
      .then(response => {
        if (!response.data.erro) {
          newAddressList[index] = {
            ...address,
            street: response.data.place,
            neighborhood: response.data.neighborhood,
            city: response.data.city.name,
            state: response.data.city.state.acronym,
          }
          handleChangeAddresses(newAddressList);
          setDisableZipCode(false);
        } else {
          enqueueSnackbar("Cep não encontrado", { variant: "error" });
          setDisableZipCode(false);
        }
      })
      .catch(error => {
        enqueueSnackbar("Cep não encontrado", { variant: "error" });
        setDisableZipCode(false);
      })
  }

  function CardContentAddress(props) {
    const { address, index } = props;
    if (address.edit) {
      return (
        <CardContent>
          <Tooltip title="buscar CEP" aria-label="buscar CEP" enterDelay={500} placement="top">
            <IconButton className={classes.iconSearch} onClick={() => getZipCode(index)} disabled={disableZipCode}>
              <FontAwesomeIcon icon={faSearch} />
            </IconButton>
          </Tooltip>
          <NumberZipCodeInputFieldComponent
            value={address.zip_code}
            onChange={handleChange('zip_code')}
            onKeyDown={(e) => { if (e.key === "Enter") getZipCode() }}
          />
          <TextInputFieldComponent
            id="street"
            label="Logradouro"
            value={address.street}
            onChange={handleChange('street')}
          />
          <TextInputFieldComponent
            id="number"
            label="Número"
            value={address.number}
            onChange={handleChange('number')}
          />
          <TextInputFieldComponent
            id="complement"
            label="Complemento"
            value={address.complement}
            onChange={handleChange('complement')}
          />
          <TextInputFieldComponent
            id="neighborhood"
            label="Bairro"
            value={address.neighborhood}
            onChange={handleChange('neighborhood')}
          />
          <TextInputFieldComponent
            id="state"
            label="Estado"
            value={address.state}
            onChange={handleChange('state')}
          />
          <TextInputFieldComponent
            id="city"
            label="Cidade"
            value={address.city}
            onChange={handleChange('city')}
          />
        </CardContent >
      );
    } else {
      return (
        <CardContent>
          <Typography className={classes.title}>
            {address.street} - {address.number} - {address.city} - {address.state}
          </Typography>
          <Typography className={classes.pos} color="textSecondary">
            {address.zip_code}
          </Typography>
          <Typography variant="body2" component="p">
            {address.complement} - {address.neighborhood}
          </Typography>
        </CardContent>
      )
    }
  }

  return (
    <Table className={classes.root}>
      <TableHead>
        <TableRow>
          <TableCell><Typography variant="body1" gutterBottom>Endereços</Typography></TableCell>
          <TableCell colSpan={2} align="right">
            <AddIconButton onClick={addAddress} />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {addresses?.map((address, index) => (
          <TableRow key={index}>
            <TableCell colSpan={3}>
              <Card className={clsx({
                [classes.card]: true,
                [classes.cardDelete]: address._destroy
              })}>
                <CardContentAddress address={address} />
                <CardActions className={classes.actions}>
                  <EditIconButton onClick={() => editAddress(index)} />
                  {address._destroy ?
                    <HistoryIconButton onClick={() => rollbackAddress(index)} ></HistoryIconButton>
                    :
                    <DeleteIconButton onClick={() => removeAddress(index)} ></DeleteIconButton>
                  }
                  {address._destroy && <Typography variant="body2" className={classes.noWrap}>  Marcado para deleção</Typography>}
                </CardActions>
              </Card>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table >

  );
}
