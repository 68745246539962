import { ClientModel } from "../models";
import { AuthRepository } from "./auth.repository";
import { Repository } from "./repository";

export class ClientsRepository extends Repository {
  constructor() {
    super();
    this.authRepository = new AuthRepository();
  }

  async getAll({ queryParams = "", page = 1, perPage = 10, orderBy = "created_at", orderDirection = "asc" }) {
    return this.api.get({ path: `clients?q=${queryParams}&page=${page}&per_page=${perPage}&order_by=${orderBy}&order_direction=${orderDirection}`, headers: this.headers() })
      .then(response => {
        this.dispatch({ type: "SET_CLIENTS", value: ClientModel.fromArray(response.data) });
        return Promise.resolve({ data: response.data, total: response.headers['total'] });
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.getAll.bind(this),
        callbackOnError: () => { this.dispatch({ type: "CLEAR_CLIENTS" }) }
      }));
  }

  async get(id) {
    return this.api.get({ path: `clients/${id}`, headers: this.headers() })
      .then(response => {
        this.dispatch({ type: "SET_CLIENT", value: ClientModel.fromJSON(response.data).toState() });
        return Promise.resolve(response.data);
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.get.bind(this, id),
        callbackOnError: () => { this.dispatch({ type: "CLEAR_CLIENT" }) }
      }));
  }

  async create(client) {
    return this.api.post({ path: "clients", headers: this.headers(), body: { client } })
      .then(response => {
        this.newSnackbarSuccess("Cliente criado com sucesso");
        this.dispatch({ type: "CREATE_USERS_ITEM", value: ClientModel.fromJSON(response.data) });
        this.dispatch({ type: "SET_CLIENT", value: ClientModel.fromJSON(response.data).toState() });
        return Promise.resolve(response.data);
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.create.bind(this),
      }));
  }

  async update(client) {
    return this.api.put({ path: `clients/${client.id}`, headers: this.headers(), body: this.omitKeys(client, ["id"]) })
      .then(response => {
        this.newSnackbarSuccess("Cliente atualizado com sucesso");
        this.dispatch({ type: "UPDATE_CLIENTS_ITEM", value: ClientModel.fromJSON(response.data) });
        this.dispatch({ type: "SET_CLIENT", value: ClientModel.fromJSON(response.data).toState() });
        return Promise.resolve(response.data);
      }
      ).catch(error => this.resolveErrorResponse({
        error,
        callback: this.update.bind(this),
      }));
  }
}
