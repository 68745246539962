import Entity from "./entity.model";
import { Constants, PhoneNumberHelper } from "../helpers";

const SUPPORTED_ATTRS = [
  "id",
  "name",
  "username",
  "legacyUserId",
  "document",
  "phoneNumber",
  "emailAddress",
  "status",
  "role",
  "password",
  "passwordConfirmation",
  "parentId",
  "cnhNumber",
  "cnhAcc",
  "cnhA",
  "cnhA1",
  "cnhB",
  "cnhB1",
  "cnhC",
  "cnhC1",
  "cnhD",
  "cnhD1",
  "cnhBe",
  "cnhCe",
  "cnhC1e",
  "cnhDe",
  "cnhD1e",
  "sectors",
  "createdAt",
  "updatedAt"
];

export class UserModel extends Entity {
  constructor(attrs = {}) {
    super(attrs, SUPPORTED_ATTRS);
  }

  get phoneNumberFormatted() {
    return new PhoneNumberHelper(this.phoneNumber).formatted;
  }

  get phoneNumberWithoutDDI() {
    return new PhoneNumberHelper(this.phoneNumber).phoneNumberWithoutDDI;
  }

  get roleName() {
    var item = Constants.User.Roles.find(role => role.label === this.role)
    return item ? item.value : this.role;
  }

  get statusName() {
    var item = Constants.User.Statuses.find(status => status.label === this.status)
    return item ? item.value : this.status;
  }

  get createdAtFormatted() {
    return this.createdAt ? new Date(`${this.createdAt}`).toLocaleString() : null;
  }

  get createdAtFormatted() {
    return this.createdAt ? new Date(`${this.createdAt} `).toLocaleString() : null;
  }

  get updatedAtFormatted() {
    return this.updatedAt ? new Date(`${this.updatedAt} `).toLocaleString() : null;
  }

  toJSON() {
    return {
      id: this.id,
      name: this.name,
      username: this.username,
      legacy_user_id: this.legacyUserId,
      document: this.document,
      phone_number: this.phoneNumber,
      email_address: this.emailAddress,
      status: this.status,
      role: this.role,
      password: this.password,
      password_confirmation: this.passwordConfirmation,
      cnh_number: this.cnhNumber,
      cnh_acc: this.cnhAcc,
      cnh_a: this.cnhA,
      cnh_a1: this.cnhA1,
      cnh_b: this.cnhB,
      cnh_b1: this.cnhB1,
      cnh_c: this.cnhC,
      cnh_c1: this.cnhC1,
      cnh_d: this.cnhD,
      cnh_d1: this.cnhD1,
      cnh_be: this.cnhBe,
      cnh_ce: this.cnhCe,
      cnh_c1e: this.cnhC1e,
      cnh_de: this.cnhDe,
      cnh_d1e: this.cnhD1e,
      sector_ids: this.sectors?.map(i => i.id),
      parent_id: this.parentId,
    };
  }

  toState() {
    return super.toState({
      phoneNumber: this.phoneNumberWithoutDDI,
      roleName: this.roleName
    });
  }

  copyWith(attrs = {}) {
    return new UserModel({ ...this.toJSON(), ...attrs });
  }
}
