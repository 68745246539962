import React from "react";
import Carousel from "react-material-ui-carousel";
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, Fade, Grid, Menu, MenuItem, Paper } from "@material-ui/core";
import { ArrowBack, ArrowForward, Close, GetApp, SettingsOverscan } from "@material-ui/icons";
import Swal from "sweetalert2";

import { Constants } from "../../../../shared/helpers";
import { DeliveriesRepository } from "../../../../shared/repositories";
import {
  DeleteIconButton,
  IconButton
} from "../../../../shared/components";

const useStyles = makeStyles(theme => ({
  root: {
  },
  container: {
    borderRadius: "5px 5px 0 0",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
  image: {
    height: "300px",
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  pathDownload: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  button: {
    borderRadius: "50%",
    color: "white",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    cursor: "pointer",
    '&:hover': {
      backgroundColor: "rgba(0, 0, 0, 0.7)"
    },
  },
  dialogImage: {
    width: "100%",
    height: "100%",
    maxHeight: "800px"
  }
}));

export function DeliveriesDetailImagesComponent(props) {
  const { delivery } = props;
  const classes = useStyles();
  const [index, setIndex] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const deliveriesRepository = new DeliveriesRepository();

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const resolveImagePath = (url) => {
    if (process.env.NODE_ENV === "development") {
      return `${Constants.development.apiUrl}${url}`;
    } else {
      return url;
    }
  }

  const downloadImage = () => {
    const image = delivery.images[index];
    const imagePath = resolveImagePath(image.default);
    var xhr = new XMLHttpRequest();
    xhr.open("GET", imagePath, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
      var urlCreator = window.URL || window.webkitURL;
      var imageUrl = urlCreator.createObjectURL(this.response);
      var tag = document.createElement('a');
      tag.href = imageUrl;
      tag.download = imagePath.split('/').pop();
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    }
    xhr.send();
  }

  const downloadAllImages = () => {
    deliveriesRepository.downloadImages(delivery.id)
  }

  const DownloadMenu = () => {
    return <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={closeMenu}
    >
      <MenuItem onClick={() => { downloadImage(); closeMenu() }}>Baixar</MenuItem>
      <MenuItem onClick={() => { downloadAllImages(); closeMenu() }}>Baixar todas</MenuItem>
    </Menu>
  }

  const deleteImage = () => {
    Swal.fire({
      title: 'Você tem certeza?',
      showDenyButton: true,
      showCancelButton: true,
      icon: 'warning',
      confirmButtonText: 'Deletar',
      denyButtonText: 'Cancelar',
      confirmButtonColor: "#DD6B55",
      html: '<b>Ao deletar uma imagem, ela não poderá ser recuperada.</b>',
    }).then((result) => {
      if (result.isConfirmed)
        return deliveriesRepository.deleteImage(delivery.id, delivery.images[index].id)
    })
  }

  return <div className={classes.root}>
    <Dialog open={openModal} onClose={() => { setOpenModal(false) }} TransitionComponent={Fade} scroll="body" maxWidth="lg">
      <DialogContent>
        <Close className={classes.button} onClick={() => { setOpenModal(false) }} style={{ position: "absolute", top: "30px", right: "30px" }} />
        <img src={resolveImagePath(delivery.images[index].default)} alt={delivery.images[index].id} className={classes.dialogImage} />
      </DialogContent>
    </Dialog>
    <Grid Container>
      <Grid item xs={12} className={classes.container}>
        <Grid container>
          <Grid item xs={6}>
            {index + 1}/{delivery.images.length}
          </Grid>
          <Grid item xs={6} className={classes.pathDownload}>
            <IconButton onClick={() => { setOpenModal(true) }}> <SettingsOverscan /> </IconButton>
            <IconButton onClick={openMenu}> <GetApp /> </IconButton>
            <DownloadMenu />
            <DeleteIconButton onClick={deleteImage} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Carousel
          autoPlay={false}
          animation="fade"
          NextIcon={<ArrowForward />}
          PrevIcon={<ArrowBack />}
          changeOnFirstRender={true}
          onChange={setIndex}
          navButtonsAlwaysVisible
        >
          {delivery.images.map(image =>
            <Paper key={image.id} className={classes.image} style={{ backgroundImage: `url(${resolveImagePath(image.default)})` }} />
          )}
        </Carousel>
      </Grid>
    </Grid>
  </div>
}
