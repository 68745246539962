import Entity from "./entity.model";
import { PhoneNumberHelper } from "../helpers";

const SUPPORTED_ATTRS = [
  "id",
  "number",
  "clientId",
  "_destroy",
  "createdAt",
  "updatedAt",
];

export class PhoneModel extends Entity {
  constructor(attrs = {}) {
    super(attrs, SUPPORTED_ATTRS);
  }

  get numberFormatted() {
    return new PhoneNumberHelper(this.number).formatted;
  }

  get numberWithoutDDI() {
    return new PhoneNumberHelper(this.number).phoneNumberWithoutDDI;
  }

  toJSON() {
    if (this._destroy) {
      return {
        id: this.id,
        _destroy: true,
      };
    }
    return {
      id: this.id,
      number: this.number,
    };
  }

  toState() {
    return super.toState({
      number: this.numberWithoutDDI,
      numberFormatted: this.numberFormatted,
    });
  }
}
