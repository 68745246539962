
import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  set_deliveries: [],
  create_deliveries_item: [],
  update_deliveries_item: [],
  delete_deliveries_item: ['value'],
  clear_deliveries: [],
  delete_delivery: ['value'],

})

const INITIAL_STATE = []

const set_deliveries = (state = INITIAL_STATE, action) => {
  return action.value
}

const create_deliveries_item = (state = INITIAL_STATE, action) => {
  return [action.value, ...state]
}

const update_deliveries_item = (state = INITIAL_STATE, action) => {
  return state.map(delivery => {
    if (delivery.id === action.value.id) {
      return action.value
    }
    return delivery
  })
}

const delete_deliveries_item = (state = INITIAL_STATE, action) => {
  return state.filter(delivery => delivery.id !== action.value)
}

const clear_deliveries = (state = INITIAL_STATE, action) => {
  return INITIAL_STATE
}

const delete_delivery = (state = INITIAL_STATE, action) => {
  return state.filter(delivery => delivery.id !== action.value)
}

export default createReducer(INITIAL_STATE,
  {
    [Types.SET_DELIVERIES]: set_deliveries,
    [Types.CREATE_DELIVERIES_ITEM]: create_deliveries_item,
    [Types.UPDATE_DELIVERIES_ITEM]: update_deliveries_item,
    [Types.DELETE_DELIVERIES_ITEM]: delete_deliveries_item,
    [Types.CLEAR_DELIVERIES]: clear_deliveries,
    [Types.DELETE_DELIVERY]: delete_delivery
  })
