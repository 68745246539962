import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import Close from "@material-ui/icons/Close";
import { IconButton } from "./";

const useStyles = makeStyles(theme => ({
  button: {
    color: "white",
    '&:hover': {
      backgroundColor: red[700],
    },
  },
}));

export function CloseIconButton(props) {
  const classes = useStyles();
  return (
    <IconButton
      {...props}
      className={classes.button}
    >
      <Close />
    </IconButton>
  );
}
