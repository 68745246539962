import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { blue, green, red } from "@material-ui/core/colors";
import clsx from "clsx";
import { DefaultButton } from "./";

const useStyles = makeStyles(theme => ({
  buttonSuccess: {
    borderRadius: "20px",
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  button: {
    borderRadius: "20px",
    backgroundColor: blue[500],
    '&:hover': {
      backgroundColor: blue[700],
    },
  },
  buttonProgress: {
    color: green[800],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  buttonError: {
    borderRadius: "20px",
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  }
}));

export function SaveButton(props) {
  const { onClick = (callback) => { callback() }, disabled } = props;
  const classes = useStyles();
  const [status, setStatus] = React.useState('');
  const buttonClassname = clsx({
    [classes.button]: true,
    [classes.buttonSuccess]: status === 'success',
    [classes.buttonError]: status === 'error',
  });

  const handleClick = e => {
    e.preventDefault();
    try {
      setStatus('loading');
      onClick()
        .then((a) => {
          setStatus('success')
        })
        .catch((e) => {
          setStatus('error')
        })
        .finally(() => {
          setTimeout(() => setStatus(''), 1000);
        });
    }
    catch (error) {
      console.log(error);
      setStatus('');
    }
  }

  return (
    <div>
      <DefaultButton
        className={buttonClassname}
        startIcon={<SaveIcon />}
        {...props}
        disabled={disabled || status === 'loading'}
        onClick={handleClick}
      >
        {props.children || "Salvar"}
        {status === 'loading' && <CircularProgress size={24} className={classes.buttonProgress} />}
      </DefaultButton>
    </div>
  );
}
