import React from 'react';
import { Close } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { makeStyles } from "@material-ui/core/styles";
import { grey } from '@material-ui/core/colors';

import { IconButton } from './';

const useStyles = makeStyles(theme => ({
	button: {
		borderRadius: "20px",
		color: "white",
		maxHeight: "30px",
		maxWidth: "30px",
		'&:hover': {
			backgroundColor: grey[800],
			color: 'white',
		},
	},
}));

export const SnackbarCloseIconButton = ({ snackbarKey }) => {
	const classes = useStyles();
	const { closeSnackbar } = useSnackbar();

	return (
		<IconButton onClick={() => closeSnackbar(snackbarKey)} className={classes.button}>
			<Close />
		</IconButton>
	);
};
