import { Repository } from "./repository";

export class MeRepository extends Repository {
  async get() {
    return this.api.get({ path: "me", headers: this.headers() })
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.get.bind(this),
      }));
  }

  async update(me) {
    return this.api.patch({ path: "me", headers: this.headers(), body: this.omitKeys(me, ["id"]) })
      .then(response => {
        this.newSnackbarSuccess("Dados atualizados!");
        return Promise.resolve(response.data);
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.update.bind(this),
      }));
  }

  async updatePassword(body) {
    return this.api.patch({ path: "me/password", headers: this.headers(), body })
      .then(response => {
        this.newSnackbarSuccess("Senha atualizada!");
        return Promise.resolve(response.data);
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.updatePassword.bind(this),
      }));
  }
}
