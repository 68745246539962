import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, CardHeader, Grid, List, ListItem, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import { Constants } from "../../../../shared/helpers";
import { DeliveriesRepository } from "../../../../shared/repositories";
import {
  BackButton,
  CreateXlsxButton
} from "../../../../shared/components";
import { useDrawerRightContext } from "../../../../shared/contexts/drawer_right.context";
import { DeliveryModel } from "../../../../shared/models";

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '400px',
      maxWidth: '400px',
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '500px',
      maxWidth: '500px',
    }

  },
  rootCard: {
    margin: "10px 10px 0px 10px",
    overflowX: 'auto'
  },
  tableCell: {
    whiteSpace: "nowrap"
  }
}));

export function DeliveryLogsComponent({ loading = false }) {
  const classes = useStyles();
  const deliveriesRepository = new DeliveriesRepository();
  const { drawerValue, toggleDrawerRightOpen } = useDrawerRightContext();

  const goBack = () => {
    toggleDrawerRightOpen()
  };

  const downloadFile = () => {
    if (drawerValue[0]) {
      let delivery = DeliveryModel.fromJSON(drawerValue[0].body.data)
      return deliveriesRepository.getLogs(delivery, 'xlsx')
    }
  };

  const localizeKey = (key) => {
    const keys = {
      "id": "ID",
      "created_at": "Criado em",
      "updated_at": "Atualizado em",
      "legacy_order_id": "Pedido",
      "status": "Status",
      "lasted_status": "Status anterior",
      "lasted_status_update": "Última atualização de status",
      "client_id": "Cliente",
      "client": "Cliente",
      "phone_number": "Telefone",
      "email_address": "E-mail",
      "driver_id": "Motorista",
      "driver": "Motorista",
      "seller_id": "Vendedor",
      "seller": "Vendedor",
      "full_address": "Endereço",
      "freight": "Frete",
      "freight_type": "Tipo do Frete",
      "weight": "Peso",
      "remark": "Observação",
      "problem_description": "Descrição do problema",
      "checker_id": "Conferente",
      "checker": "Conferente",
      "separated_at": "Separado em",
      "delivered_at": "Entregue em",
      "checked_at": "Conferido em",
      "delivered_by_id": "Entregue por",
      "delivered_by": "Entregue por",
      "delivered_to": "Entregue para",
      "parent_id": "Responsável",
      "parent": "Responsável",
      "address_zip_code": "Cep",
      "address_street": "Rua",
      "address_number": "Número",
      "address_complement": "Complemento",
      "address_neighborhood": "Bairro",
      "address_city": "Cidade",
      "address_state": "Estado",
      "region_id": "Região",
      "expected_delivery_in": "Data de entrega prevista",
      "expected_delivery_in_between": "Entrega prevista entre",
      "store_id": "Loja",
      "store": "Loja",
      "duplicate_reason": "Motivo de Duplicar",
      "duplicate_remark": "Motivo de Duplicar - Observações",
      "status_updated_at": "Status atualizado em",
      "sms_will_be_delivered_today_at": "Sms será entregue hoje em",
      "sms_out_for_delivery_at": "Sms saiu para entrega em",
      "sms_delivered_at": "Sms entregue em",
      "out_for_delivery_at": "Saiu para entrega em",
      "company_id": "Empresa",
      "company": "Empresa",
    }
    return keys[key]
  }

  const resolveValue = (key, value) => {
    if (value) {
      if (typeof value === "object")
        return value.name || [value.first_name, value.last_name].join(" ")
      if (key.includes("_at"))
        return new Date(value).toLocaleString('pt-br')
      if (key === "status" || key === "lasted_status")
        return Constants.Delivery.Statuses.find(i => i.label == [value]).value
      if (key === "freight_type")
        return Constants.Delivery.FreightTypes.find(i => i.label == [value]).value
      if (key === "duplicate_reason")
        return Constants.Delivery.DeliveryReasons.find(i => i.label == [value]).value
    }
    return value
  }

  return (
    <Grid className={classes.root}>
      <List>
        <ListItem className={classes.gridItem} style={{ display: "flex", justifyContent: "space-between" }}>
          <BackButton onClick={goBack} />
          <CreateXlsxButton onClick={downloadFile} disabled={loading || !drawerValue?.length} >Baixar Logs</CreateXlsxButton>
        </ListItem>
        <ListItem>
          <Typography variant="h6">Pedido: {(drawerValue[0] && drawerValue[0].body.data.legacy_order_id)}</Typography>
        </ListItem>
        {!loading ?
          drawerValue?.length ? drawerValue.map((log, index) =>
            <Card className={classes.rootCard} elevation={3} >
              <CardHeader title={`${log.body.new_record ? "Criado por " : "Atualizado por "} ${log.user?.name || "Sistema"}`} subheader={log.createdAt ? `${log.body.new_record ? "Criado em" : "Atualizado em"} ${new Date(log.createdAt).toLocaleString()}` : ''} />
              {!log.body.new_record && <CardContent>
                <Table size="small">
                  <TableHead>
                    <TableCell>Campo</TableCell>
                    <TableCell>De</TableCell>
                    <TableCell>Para</TableCell>
                  </TableHead>
                  <TableBody>
                    {log.body && Object.keys(log.body.changes)
                      .map(key => <TableRow>
                        <TableCell className={classes.tableCell}><b>{localizeKey(key)}</b></TableCell>
                        <TableCell className={classes.tableCell}>{resolveValue(key, log.body.changes[key][0])}</TableCell>
                        <TableCell className={classes.tableCell}>{resolveValue(key, log.body.changes[key][1])}</TableCell>
                      </TableRow>
                      )}
                  </TableBody>
                </Table>
              </CardContent>}
            </Card>
          )
            : <Grid container display='flex' justifyContent="center"><Typography color="textSecondary">Não há logs</Typography></Grid>
          : [0, 0, 0].map((i) =>
            <Card className={classes.rootCard} elevation={3} >
              <CardHeader title={<Skeleton />} subheader={<Skeleton />} />
              <CardContent><Table size="small">
                <TableHead><Skeleton /></TableHead>
                <TableBody><Skeleton /></TableBody>
              </Table></CardContent>
            </Card>
          )}
      </List>
    </Grid >
  );
}



