import React from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Typography,
  Grid,
  Paper,
  useMediaQuery,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import {
  TextInputFieldComponent,
  EditIconButton,
  SaveButton
} from '../../shared/components';
import {
  AuthRepository,
  CompaniesRepository
} from '../../shared/repositories'
import { resolveImagePath } from '../../shared/helpers';

const useStyles = makeStyles(theme => ({
  bgLogin: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto 500px',
    borderRadius: '10px 0px 0px 10px',
    minHeight: '500px',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '10px 10px 0px 0px',
      minHeight: '0px',
      height: '200px',
      width: '500px'
    }
  },
  bgLogo: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '55% 80%',
    // height: '150px',
  },
  loginBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '480px',
    [theme.breakpoints.down('sm')]: {
      height: '300px',
      width: '500px'
    }
  },
  loginGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    borderRadius: '0px 10px 10px 0px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0px 0px 10px 10px',
    }
  }
}))

export function SignInPage() {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar()
  const { auth } = useSelector(state => state);
  const [username, setUsername] = React.useState()
  const [password, setPassword] = React.useState()
  const [cidSlug, setCidSlug] = React.useState()
  const [loading, setLoading] = React.useState(false);
  const [company, setCompany] = React.useState({});
  const authRepository = new AuthRepository();
  const companyRepository = new CompaniesRepository();
  const smDown = useMediaQuery("(min-width:960px)");

  React.useEffect(() => {
    if (auth['token'] && auth['token'].length)
      history.push('/')
    setCompany(localStorage.getItem("c") ? JSON.parse(localStorage.getItem("c")) : {})
  }, [auth, history])

  const resolveCid = () => {
    if (isNaN(cidSlug))
      return company?.cid
    return cidSlug
  }

  const getCompany = async () => {
    if (cidSlug?.length > 1)
      setCompany(await companyRepository.getByCidSlug(cidSlug))
  }

  const getLogo = () => {
    if (loading) {
      if (company?.logo_gif?.url)
        return resolveImagePath(company.logo_gif.url);
      else
        return '/assets/img/loading.gif'
    } else if (company?.logo?.url)
      return resolveImagePath(company.logo.url);
    else
      return '/assets/img/logo.png'
  }

  const signIn = async (e) => {
    try {
      setLoading(true);
      if (username?.length && password?.length) {
        authRepository.signIn(resolveCid(), username, password)
        .then(() => {
          history.push('/')
        })
        .catch(() => {
          enqueueSnackbar("Usuário ou senha inválidos.", { variant: "error" })
        })
        .finally(() => setLoading(false))
      } else {
        setTimeout(() => {
          setLoading(false);
          enqueueSnackbar("Usuário ou senha inválidos.", { variant: "error" })
        }, 1000);
      }
    } catch (error) {
      console.log(error)
      setLoading(false);
      enqueueSnackbar("Usuário ou senha inválidos.", { variant: "error" })
    }
  }

  return (
    <Grid container style={{ minHeight: '100vh', justifyContent: 'center' }}>
      <Grid container xs={8} style={{ display: 'flex', direction: smDown ? 'row' : "column", justifyContent: "center", alignItems: 'center', alignContent: 'center' }}>
        <Grid
          item
          component={Paper}
          elevation={10}
          xs={false}
          sm={12}
          md={6}
          xl={4}
          className={classes.bgLogin}
          style={{ backgroundImage: `url(/assets/img/sign-in-image.jpg)` }}
        >
          <Grid
            item
            component={Paper}
            elevation={10}
            xs={4}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              objectFit: "contain",
              // backgroundImage: `url(/assets/img/${loading ? 'loading.gif' : 'logo.png'})`,
            }}
            className={classes.bgLogo}
          >
            <img
              style={{
                transition: 'animation 150ms ease',
                animation: "is-rotating 1s infinite",
              }}
              width="80%"
              src={getLogo()} alt="img-login" />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} xl={4} component={Paper} elevation={6} square className={classes.loginGrid}>
          <Box className={classes.loginBox}>
            <Typography component="h1" variant="h5">Entregas</Typography>
            <form autoComplete="off" onSubmit={signIn}>
              <Grid item md={12}>
                <FormControl size='small' fullWidth variant="outlined" margin="dense" disabled={loading || company?.cid}>
                  <InputLabel htmlFor="cidSlug">Empresa</InputLabel>
                  <OutlinedInput
                    id="cidSlug"
                    value={company?.slug || cidSlug}
                    onChange={e => setCidSlug(e.target.value)}
                    onBlur={e => getCompany()}
                    endAdornment={
                      <InputAdornment position="end">
                        <EditIconButton
                          onClick={_ => { setCompany({}); setCidSlug(null) }}
                          onMouseDown={e => e.preventDefault()}
                        />
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                </FormControl>
              </Grid>
              <Grid item md={12}>
                <TextInputFieldComponent
                  margin="dense"
                  required
                  id="username"
                  label="Usuário"
                  name="username"
                  autoComplete="username"
                  autoFocus
                  value={username}
                  onChange={e => setUsername(e.target.value)}
                  disabled={loading}
                />
              </Grid>
              <Grid item md={12}>
                <TextInputFieldComponent
                  margin="dense"
                  required
                  name="password"
                  label="Senha"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  disabled={loading}
                />
              </Grid>
              <Grid item md={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <SaveButton
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  type="submit"
                  onClick={signIn}
                />
              </Grid>
            </form>
          </Box>
        </Grid>
      </Grid>
    </Grid >
  );
}

