/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Typography } from "@material-ui/core";

import {
  SelectionInputFieldComponent,
  TextInputFieldComponent,
  DefaultButton
} from "../../../shared/components";
import { Constants } from "../../../shared/helpers";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    // maxHeight: 500
  },
  gridItem: {
    padding: theme.spacing(1),
  },
  container: {
    marginTop: theme.spacing(1),
    overflowX: 'auto',
  },
  textField: {
  }
}));

export function VehiclesSearchComponent({ setFilters, INITIAL_FILTER, setLoading }) {
  const classes = useStyles();
  const [localFilters, setLocalFilters] = React.useState(INITIAL_FILTER)
  const [models, setModels] = React.useState([]);

  const clearFilters = () => {
    setLocalFilters(INITIAL_FILTER)
    setFilters(INITIAL_FILTER);
  }

  React.useEffect(() => {
    setLoading(true)
    let waitSec = setTimeout(() => {
      setFilters(localFilters);
    }, 1000);
    return () => clearTimeout(waitSec);
  }, [localFilters]);

  const handleChange = name => event => {
    setLocalFilters({ ...localFilters, [name]: event.target.value });
  };

  React.useEffect(() => {
    let brands = Constants.Vehicle.Brands.find(brand => brand.label === localFilters.brand);
    setModels(brands ? brands.models : []);
  }, [localFilters.brand])

  return (
    <Paper className={classes.paper}>
      <Grid container >
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>Veículos</Typography>
        </Grid>
      </Grid>
      <Grid container >
        <Grid item xs={12} md={4}>
          <Grid item xs={12} md={12} className={classes.gridItem}>
            <SelectionInputFieldComponent
              id="brand-select"
              label="Marca"
              acceptedAll
              value={localFilters.brand}
              onChange={handleChange("brand")}
              options={Constants.Vehicle.Brands}
            />
          </Grid>
          <Grid item xs={12} md={12} className={classes.gridItem}>
            <SelectionInputFieldComponent
              id="model-select"
              label="Modelo"
              acceptedAll
              value={localFilters.model}
              onChange={handleChange("model")}
              options={models}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid item xs={12} md={12} className={classes.gridItem}>
            <TextInputFieldComponent
              id="plate-input"
              label="Placa"
              value={localFilters.plate}
              onChange={handleChange("plate")}
            />
          </Grid>
          <Grid item xs={12} md={12} className={classes.gridItem}>
            <TextInputFieldComponent
              id="driver-name-input"
              label="Motorista"
              value={localFilters.driverName}
              onChange={handleChange("driverName")} />
          </Grid>
        </Grid>

        <Grid item xs={12} md={4}>
          <Grid item xs={12} md={12} className={classes.gridItem}>
            <SelectionInputFieldComponent
              id="category-select"
              label="Categoria"
              acceptedAll
              value={localFilters.category}
              onChange={handleChange("category")}
              options={Constants.Vehicle.Categories}
            />
          </Grid>
          <Grid item xs={12} md={12} className={classes.gridItem}>
            <SelectionInputFieldComponent
              id="status-select"
              label="Status"
              acceptedAll
              value={localFilters.status}
              onChange={handleChange("status")}
              options={Constants.Vehicle.Statuses}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} style={{ display: "flex", justifyContent: "flex-end" }}>
          <DefaultButton onClick={clearFilters}>Limpar Filtros</DefaultButton>
        </Grid>
      </Grid>
    </Paper>)
}
