import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { IconButton } from "./";

const useStyles = makeStyles(theme => ({
  button: {
    color: "black"
  },
}));
export function VisibilityIconButton(props) {
  const { view } = props;
  const classes = useStyles()

  return (
    <IconButton {...props} className={classes.button}>{view ? <VisibilityOffIcon /> : <VisibilityIcon />}</IconButton>
  );
}
