import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faDownload, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { red } from "@material-ui/core/colors";
import { SaveButton } from "./saveButton.component";

const useStyles = makeStyles(theme => ({
  button: {
    borderRadius: "20px",
    backgroundColor: '#AD0B00',
    '&:hover': {
      backgroundColor: red[400],
    },
  },
  icon: {
    marginLeft: theme.spacing(1),
    borderLeft: `1px solid white`,
    paddingLeft: theme.spacing(1),
    fontSize: '1rem',
  },
}));
export function CreatePdfOrXlsxButton(props) {
  const classes = useStyles();
  const { onClick, disabled, handleMenu } = props;

  return (
    <SaveButton
      className={classes.button}
      onClick={onClick}
      disabled={disabled}
      startIcon={<FontAwesomeIcon icon={faFilePdf} />}
      {...props}
      >
      {props.children || "Baixar Pdf"}

      {/* <FontAwesomeIcon icon={faAngleDown} className={classes.icon} handleMenu={(e) => handleMenu(true)} /> */}
    </SaveButton>
  );
}
