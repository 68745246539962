import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton as TIconButton } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";

const useStyles = makeStyles(theme => ({
  button: {
    borderRadius: "20px",
    color: blue[500],
    maxHeight: "30px",
    maxWidth: "30px",
    '&:hover': {
      backgroundColor: blue[700],
      color: 'white',
    },
  },
}));
export function IconButton({ show, className, onClick = () => { }, disabled = false, children = <></>, ...props }) {
  const classes = useStyles();

  return (
    <TIconButton
      size="small"
      {...props}
      disabled={disabled}
      onClick={onClick}
      className={className || classes.button}
    >
      {children}
    </TIconButton>
  );
}
