import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

import { ClientsRepository } from "../../../shared/repositories";

export const DeliveriesAutoCompleteClientComponent = ({ defaultValue, onSelect }) => {
	const [options, setOptions] = React.useState([]);
	const [search, setSearch] = React.useState("");
	const [loading, setLoading] = React.useState(false);
	const clientsRepository = new ClientsRepository();

	React.useEffect(() => {
		setOptions([defaultValue])
	}, [defaultValue]);

	React.useEffect(() => {
		if (search && (search.length === 0 || search.length > 2)) {
			setLoading(true);
			const waitSec = setTimeout(() => {
				clientsRepository.getAll({ queryParams: `name=${search}` })
					.then((result) => {
						setOptions(result.data);
					})
					.finally(() => setLoading(false));
			}, 300);
			return () => clearTimeout(waitSec);
		};
	}, [search]);

	return (
		<Autocomplete
			style={{ marginRight: 8 }}
			fullWidth
			defaultValue={defaultValue}
			filterOptions={(options) => options}
			getOptionSelected={(option, value) => option.id === value.id}
			getOptionLabel={(option) => option && [option.first_name, option.last_name].join(" ").trim()}
			onInputChange={(_, newValue) => setSearch(newValue)}
			onChange={(_, option) => option && onSelect(option.id, [option.first_name, option.last_name].join(" ").trim())}
			options={options}
			loading={loading}
			renderInput={(params) => (
				<TextField
					{...params}
					size="small"
					label="Cliente"
					variant="outlined"
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<React.Fragment>
								{loading ? (
									<CircularProgress color="inherit" size={20} />
								) : null}
								{params.InputProps.endAdornment}
							</React.Fragment>
						),
					}}
				/>
			)}
		/>
	);
};
