import { CompanyModel } from "../models";
import { AuthRepository } from "./auth.repository";
import { Repository } from "./repository";

export class CompaniesRepository extends Repository {
  constructor() {
    super();
    this.authRepository = new AuthRepository();
  }

  async getAll({ queryParams = "", page = 1, perPage = 10, orderBy = "created_at", orderDirection = "asc" } = {}) {
    return this.api.get({ path: `companies?q=${queryParams}&page=${page}&per_page=${perPage}&order_by=${orderBy}&order_direction=${orderDirection}`, headers: this.headers() })
      .then(response => {
        const companies = CompanyModel.fromArray(response.data)
        this.dispatch({ type: "SET_COMPANIES", value: companies });
        return Promise.resolve({ data: response.data, total: response.headers['total'] });
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.getAll.bind(this),
        callbackOnError: () => { this.dispatch({ type: "CLEAR_COMPANIES" }) }
      }));
  }

  async get(id) {
    return this.api.get({ path: `companies/${id}`, headers: this.headers() })
      .then(response => {
        this.dispatch({ type: "SET_COMPANY", value: CompanyModel.fromJSON(response.data).toState() });
        return Promise.resolve(response.data);
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.getAll.bind(this),
        callbackOnError: () => { this.dispatch({ type: "CLEAR_COMPANIES" }) }
      }));
  }

  async getByCidSlug(cidSlug) {
    return this.api.get({ path: `company?cid_slug=${cidSlug}`, headers: this.headers() })
      .then(response => {
        localStorage.setItem("c", JSON.stringify(response.data))
        return CompanyModel.fromJSON(response.data);
      }).catch(_ => { })
  }

  async create(company) {
    return this.api.post({ path: "companies", headers: this.headers(), body: this.companyParams(company) })
      .then(response => {
        const company = CompanyModel.fromJSON(response.data)
        this.newSnackbarSuccess(["Empresa criada com sucesso"]);
        this.dispatch({ type: "CREATE_COMPANIES_ITEM", value: company });
        this.dispatch({ type: "SET_COMPANY", value: company });
        this.dispatch({ type: 'AUTH_SIGN_IN', value: { ...this.auth, companies: this.auth.companies.map(comp => comp.id === company.id ? response.data : comp) } })
        return Promise.resolve(company);
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.create.bind(this),
      }));
  }

  async update(company) {
    window.e = company
    return this.api.put({ path: `companies/${company.id}`, headers: this.headers(), body: this.companyParams(this.omitKeys(company, ["id"])) })
      .then(response => {
        const company = CompanyModel.fromJSON(response.data)
        this.newSnackbarSuccess(["Empresa atualizada com sucesso"]);
        this.dispatch({ type: "UPDATE_COMPANIES_ITEM", value: company });
        this.dispatch({ type: "SET_COMPANY", value: company });
        this.dispatch({ type: 'AUTH_SIGN_IN', value: { ...this.auth, companies: this.auth.companies.map(comp => comp.id === company.id ? response.data : comp) } })
        return Promise.resolve(company);
      }
      ).catch(error => this.resolveErrorResponse({
        error,
        callback: this.update
      }));
  }

  companyParams(params) {
    const formData = new FormData();
    Object.keys(params).map(key => {
      if (params[key])
        if (!['icon', 'logo', 'logo_gif'].includes(key))
          formData.append(`company[${key}]`, params[key])
    })
    if (params['icon'])
      formData.append(['company[icon]'], params['icon'])
    if (params['logo'])
      formData.append(['company[logo]'], params['logo'])
    if (params['logo_gif'])
      formData.append(['company[logo_gif]'], params['logo_gif'])
    return formData
  }

}
