import Entity from "./entity.model";
import { Constants } from "../helpers";
import { PhoneModel, EmailModel, AddressModel } from ".";

const SUPPORTED_ATTRS = [
  "id",
  "firstName",
  "lastName",
  "document",
  "status",
  "phones",
  "emails",
  "addresses",
  "createdAt",
  "updatedAt",
];

export class ClientModel extends Entity {
  constructor(attrs = {}) {
    super(attrs, SUPPORTED_ATTRS);
  }

  get name() {
    return `${this.firstName} ${this.lastName}`.trim();
  }

  get phoneNumber() {
    return this.phones && this.phones.length > 0 ? this.phones[0].numberFormatted : null;
  }

  get emailAddress() {
    return this.emails && this.emails.length > 0 ? this.emails[0].address : null;
  }

  get statusName() {
    var item = Constants.Client.Statuses.find(status => status.label === this.status)
    return item ? item.value : this.status;
  }

  toJSON() {
    return {
      id: this.id,
      first_name: this.firstName,
      last_name: this.lastName,
      document: this.document,
      status: this.status,
      phones_attributes: this.phones,
      emails_attributes: this.emails,
      addresses_attributes: this.addresses,
    };
  }

  static fromJSON(attrs) {
    return super.fromJSON({
      ...attrs,
      phones: PhoneModel.fromArray(attrs.phones),
      emails: EmailModel.fromArray(attrs.emails),
      addresses: AddressModel.fromArray(attrs.addresses),
    })
  }

  toState() {
    return super.toState({
      phones: this.phones.map(phone => phone.toState()),
      emails: this.emails.map(email => email.toState()),
      addresses: this.addresses.map(address => address.toState()),
    })
  }
}
