import React from "react";
import Carousel from "react-material-ui-carousel";
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper } from "@material-ui/core";

import {
  DefaultButton,
  DeleteIconButton,
  SaveButton
} from "../../../../shared/components";
import { DeliveriesRepository } from "../../../../shared/repositories";

const useStyles = makeStyles(theme => ({
  carouselImages: {
    maxHeight: "500px"
  },
  image: {
    maxHeight: "500px",
    width: "100%",
  },
  pathDownload: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  }
}));

export function DeliveriesAddImagesDialogComponent(props) {
  const { delivery, open, handleClose } = props;
  const classes = useStyles();
  const deliveriesRepository = new DeliveriesRepository();
  const [index, setIndex] = React.useState(0);
  const [images, setImages] = React.useState([]);

  const handleBack = () => {
    setImages([]);
    setIndex(0);
    handleClose();
  }

  const handleChange = e => {
    setImages(e.target.files);
  };

  const addImages = _ => {
    const formData = new FormData();
    for (let i = 0; i < images.length; i++) {
      formData.append("images[]", images[i]);
    }
    deliveriesRepository.addImage(delivery.id, formData).then(() => {
      handleBack();
    });
  }

  const removeImage = () => {
    let arr = Array.from(images)
    arr.splice(index, 1);
    setImages(arr);
  }

  const carouselImages = () => {
    let items = []
    for (let i = 0; i < images.length; i++) {
      var url = URL.createObjectURL(images[i]);
      items.push(
        <Paper key={i} className={classes.carouselImages} >
          <img src={url} alt={i} className={classes.image} />
        </Paper>
      );
    }
    return items;
  }

  return <Dialog open={open} onClose={handleBack}>
    <DialogTitle id="form-dialog-title">Imagens</DialogTitle>
    <DialogContent>
      {images.length ?
        <Grid Container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                {index + 1}/{images.length}
              </Grid>
              <Grid item xs={6} className={classes.pathDownload}>
                <DeleteIconButton onClick={removeImage} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Carousel
              autoPlay={false}
              animation="slide"
              swipe={true}
              NextIcon={<ArrowForward />}
              PrevIcon={<ArrowBack />}
              changeOnFirstRender={true}
              onChange={setIndex}
            >
              {carouselImages()}
            </Carousel>
          </Grid>
        </Grid>
        : null
      }
      <input type="file" id="file" multiple onChange={handleChange} accept=".jpg, .jpeg, .png" />
    </DialogContent>
    <DialogActions style={{ justifyContent: "space-between" }}>
      <DefaultButton onClick={handleBack}>Cancelar</DefaultButton>
      <SaveButton onClick={addImages} />
    </DialogActions>
  </Dialog>
}
