import React from 'react';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {
  makeStyles
} from '@material-ui/core/styles';
import clsx from 'clsx';
import moment from 'moment';
import 'moment/locale/pt-br';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';

import { TextInputFieldComponent } from '../';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  card: {
    flexGrow: 1,
    marginTop: theme.spacing(3)
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  container: {
    borderRadius: "5px",
    justify: "center",
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

export const DateInputFieldComponent = (props) => {
  const classes = useStyles()
  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale="pt-BR">
      <KeyboardDatePicker
        variant="inline"
        size="small"
        format="DD/MM/YYYY"
        className={clsx(classes.textField, classes.fieldDate)}
        inputVariant="outlined"
        fullWidth
        autoOk
        invalidDateMessage="Data inválida"
        invalidLabel="Inválido"
        maxDateMessage="Data deve ser menor que a data maxima"
        minDateMessage="Data deve ser maior que a data minima"
        TextFieldField={TextInputFieldComponent}
        {...props}
        value={props.value ? moment(props.value) : null}
      />
    </MuiPickersUtilsProvider>
  )
}
