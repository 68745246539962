/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { MenuItem, Paper, Grid, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";

import {
  TextInputFieldComponent,
  DefaultButton
} from "../../../shared/components";
import { Constants, authorizedRoles } from "../../../shared/helpers";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    // maxHeight: 500
  },
  gridItem: {
    padding: theme.spacing(1),
  },
  container: {
    marginTop: theme.spacing(1),
    overflowX: 'auto',
  },
}));

export function UsersSearchComponent({ title, setFilters, INITIAL_FILTER, setLoading = () => { } }) {
  const auth = useSelector(state => state.auth);
  const classes = useStyles();
  const [localFilters, setLocalFilters] = React.useState(INITIAL_FILTER);
  const roles = [
    Constants.User.Roles[0],
    ...authorizedRoles(auth.role).map((role) => ({ label: role, value: Constants.User.Roles.find((r) => r.label === role).value }))
  ];

  const clearFilters = () => {
    setLocalFilters(INITIAL_FILTER)
    setFilters(INITIAL_FILTER);
  }

  React.useEffect(() => {
    setLoading(true);
    let waitSec = setTimeout(() => {
      setFilters(localFilters);
      setLoading(false);
    }, 1000);
    return () => clearTimeout(waitSec);
  }, [localFilters]);

  const handleChange = name => event => {
    setLocalFilters({ ...localFilters, [name]: event.target.value });
  };

  return (
    <Paper className={classes.paper}>
      <Grid container >
        <Grid item xs={12} md={12}>
          <Typography variant="h6" gutterBottom>Usuários</Typography>
        </Grid>
      </Grid>
      <Grid container >
        <Grid item xs={12} md={4}>
          <Grid item xs={12} md={12} className={classes.gridItem}>
            <TextInputFieldComponent
              id="name-input"
              label="Nome"
              value={localFilters.name}
              onChange={handleChange("name")}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid item xs={12} md={12} className={classes.gridItem}>
            <TextInputFieldComponent
              id="phoneNumber-input"
              label="Telefone"
              value={localFilters.phoneNumber}
              onChange={handleChange("phoneNumber")}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid item xs={12} md={12} className={classes.gridItem}>
            <TextInputFieldComponent
              id="standard-select-role"
              select
              label="Tipo"
              value={localFilters.role}
              onChange={handleChange("role")}>
              {roles?.map((option) => (
                <MenuItem key={option.label} value={option.label}>
                  {option.value}
                </MenuItem>
              ))}
            </TextInputFieldComponent>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid item xs={12} md={12} className={classes.gridItem}>
            <TextInputFieldComponent
              id="standard-select-status"
              select
              label="Status"
              value={localFilters.status}
              onChange={handleChange("status")}>
              {Constants.User.Statuses?.map((option) => (
                <MenuItem key={option.label} value={option.label}>
                  {option.value}
                </MenuItem>
              ))}
            </TextInputFieldComponent>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} style={{ display: "flex", justifyContent: "flex-end" }}>
          <DefaultButton onClick={clearFilters}>Limpar filtros</DefaultButton>
        </Grid>
      </Grid>
    </Paper >)
}
