import React from 'react';
import { Route } from "react-router-dom";
import {
  ClientsPage,
  CompaniesPage,
  DashboardPage,
  DeliveriesPage,
  MePage,
  MePasswordPage,
  ReportsPage,
  RegionsPage,
  StoresPage,
  RestrictedDatesPage,
  UsersPage,
  VehiclesPage,
  SeparationsPage,
  SectorsPage,
  SeparationsRealTimePage
} from '../pages';

export function PrivateRoutes() {
  return <div>
    <Route exact path="/" component={DashboardPage}></Route>
    <Route exact path="/clientes" component={ClientsPage}></Route>
    <Route exact path="/empresas" component={CompaniesPage}></Route>
    <Route exact path="/entregas" component={DeliveriesPage}></Route>
    <Route exact path="/me" component={MePage}></Route>
    <Route exact path="/me/password" component={MePasswordPage}></Route>
    <Route exact path="/relatorios" component={ReportsPage}></Route>
    <Route exact path="/datas-bloqueadas" component={RestrictedDatesPage}></Route>
    <Route exact path="/lojas" component={StoresPage}></Route>
    <Route exact path="/usuarios" component={UsersPage}></Route>
    <Route exact path="/veiculos" component={VehiclesPage}></Route>
    <Route exact path="/regioes" component={RegionsPage}></Route>
    <Route exact path="/separacoes" component={SeparationsPage}></Route>
    <Route exact path="/separações/tempo-real" component={SeparationsRealTimePage}></Route>
    <Route exact path="/setores" component={SectorsPage}></Route>
  </div>
}
