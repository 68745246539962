
import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  set_clients: [],
  create_clients_item: [],
  update_clients_item: [],
  clear_clients: [],
  delete_client: ['value'],
})

const INITIAL_STATE = []

const set_clients = (state = INITIAL_STATE, action) => {
  return action.value
}

const update_clients_item = (state = INITIAL_STATE, action) => {
  return state.map(client => {
    if (client.id === action.value.id) {
      return action.value
    }
    return client
  })
}

const create_clients_item = (state = INITIAL_STATE, action) => {
  return [action.value, ...state]
}

const clear_clients = (state = INITIAL_STATE, action) => {
  return INITIAL_STATE
}

const delete_client = (state = INITIAL_STATE, action) => {
  return state.filter(client => client.id !== action.value)
}

export default createReducer(INITIAL_STATE,
  {
    [Types.SET_CLIENTS]: set_clients,
    [Types.CREATE_CLIENTS_ITEM]: create_clients_item,
    [Types.UPDATE_CLIENTS_ITEM]: update_clients_item,
    [Types.CLEAR_CLIENTS]: clear_clients,
    [Types.DELETE_CLIENT]: delete_client
  })
