import React from "react";
import { Table, TableRow, TableBody, TableCell, TableHead, TableContainer, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Swal from "sweetalert2";

import {
  AddIconButton,
  DeleteIconButton,
  EditIconButton,
  TablePaginationComponent,
  TableRowLoadingComponent
} from "../../../shared/components";
import { SectorsRepository } from "../../../shared/repositories";
import { faSort, faSortAsc, faSortDesc } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDrawerRightContext } from "../../../shared/contexts/drawer_right.context";
import { removeSpecialChars } from "../../../shared/helpers";
import { SectorModel } from "../../../shared/models";

const useStyles = makeStyles(theme => ({
  noWrap: {
    overflow: 'hidden',
    whiteSpace: "nowrap",
    maxWidth: '100px',
    textOverflow: 'ellipsis'
  },
  tableBody: {
    overflowX: "auto",
    maxHeight: 500
  },
  tableCell: {
    whiteSpace: "nowrap",
  },
}));

export function SectorsListComponent({ loading, sectors = [], filters = {}, setFilters = () => { }, setSectors = () => { }, setLoading = () => { } }) {
  const sectorsRepository = new SectorsRepository();
  const [pagination, setPagination] = React.useState({ page: 1, perPage: 10, total: 0 });
  const classes = useStyles();
  const { setNewDrawerValue } = useDrawerRightContext();

  const viewMore = sector => e => {
    e.preventDefault();
    setNewDrawerValue(sector.toState())
  }

  const newItem = e => {
    e.preventDefault();
    setNewDrawerValue(new SectorModel().toState())
  }

  React.useEffect(() => {
    setLoading(true)
    const timer = setTimeout(() => {
      getSectors()
    }, 1000);
    return () => clearTimeout(timer);
  }, [pagination.page, pagination.perPage, filters]);

  const getSectors = () => {
    sectorsRepository.getAll({
      queryParams: resolveFilters(),
      page: pagination.page,
      perPage: pagination.perPage,
      orderBy: filters.orderBy,
      orderDirection: filters.orderDirection
    })
      .then(result => {
        setSectors(result.data)
        setPagination({ ...pagination, total: result.total })
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => setLoading(false))
  }

  const resolveFilters = () => {
    let query = [];

    if (filters.name) {
      query.push(`name=${removeSpecialChars(filters.name)}`)
    }

    if (filters.legalSectorId) {
      query.push(`legal_sector_id=${filters.legalSectorId}`)
    }

    return query.join("|");
  }

  const orderBy = (orderBy) => e => {
    setFilters({ ...filters, orderBy: orderBy, orderDirection: filters.orderDirection === 'asc' ? 'desc' : 'asc' })
  }

  const SortIcon = ({ orderBy }) => {
    if (filters.orderBy === orderBy) {
      if (filters.orderDirection === 'asc') {
        return <FontAwesomeIcon icon={faSortAsc} />
      } else {
        return <FontAwesomeIcon icon={faSortDesc} />
      }
    } else {
      return <FontAwesomeIcon icon={faSort} />
    }
  }

  const deleteRow = sector => e => {
    return Swal.fire({
      title: 'Confirmar?',
      showDenyButton: true,
      showCancelButton: true,
      icon: 'warning',
      confirmButtonText: 'Deletar',
      denyButtonText: 'Cancelar',
      confirmButtonColor: "#DD6B55",
      html: `<b>Você não poderá reverter esta ação!</b>`,
    }).then((result) => {
      if (result.isConfirmed)
        return sectorsRepository.delete(sector.id).then(() => {
          const index = sectors.findIndex(item => item.id === sector.id)
          if (index > -1) {
            sectors.splice(index, 1)
            setSectors([...sectors])
          }
        })
      else
        return Promise.reject('')
    })
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left" colSpan={1}><AddIconButton onClick={newItem} /></TableCell>
            <TableCell align="right" colSpan={4}>{sectors.length} itens</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCell}></TableCell>
            <TableCell onClick={orderBy('legacy_sector_id')} className={classes.tableCell}>Código <SortIcon orderBy="legacy_sector_id" /></TableCell>
            <TableCell onClick={orderBy('name')} className={classes.tableCell}>Nome <SortIcon orderBy="name" /></TableCell>
            <TableCell>Descrição</TableCell>
            <TableCell onClick={orderBy('created_at')} className={classes.tableCell}>Criado em <SortIcon orderBy="created_at" /></TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {loading ?
            <TableRowLoadingComponent cols={5} />
            : sectors.map(row => (
              <TableRow key={row.id} className={classes.tableRow}>
                <TableCell className={classes.tableCell}>
                  <DeleteIconButton onClick={deleteRow(row)} />
                  <EditIconButton onClick={viewMore(row)} />
                </TableCell>
                <TableCell className={classes.tableCell}>{row.legacySectorId}</TableCell>
                <TableCell className={classes.tableCell}>{row.name}</TableCell>
                <TableCell className={classes.tableCell}>{row.description}</TableCell>
                <TableCell className={classes.tableCell}>{row.createdAt}</TableCell>
              </TableRow>
            ))}
          {!loading && !sectors[0] ? <TableRow key="none" className={classes.tableRow}>
            <TableCell align="center" component="th" scope="row" colSpan="5" style={{ height: "300px" }}>
              <b>Não há dados para exibir</b>
            </TableCell>
          </TableRow> : null}
        </TableBody>
        <TablePaginationComponent
          colSpan={5}
          count={pagination.total}
          rowsPerPage={pagination.perPage}
          page={pagination.page}
          loading={loading}
          onPageChange={value => { setPagination({ ...pagination, page: value }) }}
          onRowsPerPageChange={value => { setPagination({ ...pagination, page: 1, perPage: value }) }}
        />
      </Table>
    </TableContainer >)
}
