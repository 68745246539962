import Entity from "./entity.model";

const SUPPORTED_ATTRS = [
  "id",
  "legacyProductId",
  "ean",
  "dun",
  "dunAmount",
  "description",
  "sku",
  "amount",
  "unit",
  "sectorId",
  "createdAt",
  "updatedAt",
];

export class OrderItemModel extends Entity {
  constructor(attrs = {}) {
    super(attrs, SUPPORTED_ATTRS);
  }

  toJSON() {
    return {
      id: this.id,
      legacy_product_id: this.legacyProductId,
      ean: this.ean,
      dun: this.dun,
      dun_amount: this.dunAmount,
      description: this.description,
      sku: this.sku,
      amount: this.amount,
      unit: this.unit,
      sector_id: this.sectorId,
    };
  }
}
