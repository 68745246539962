
import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  set_me: ['id', 'name', 'username', 'phone_number', 'email_address', 'role', 'updated_at', 'created_at'],
  set_me_details: ['id', 'name', 'username', 'phone_number', 'email_address', 'role', 'updated_at', 'created_at'],
})

const INITIAL_STATE = {
  id: "",
  name: "",
  username: "",
  phone_number: "",
  email_address: "",
  role: "",
  updated_at: "",
  created_at: "",
}

const set_me = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.value
  }
}

const set_me_details = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.value
  }
}

export default createReducer(INITIAL_STATE,
  {
    [Types.SET_ME]: set_me,
    [Types.SET_ME_DETAILS]: set_me_details,
  })
