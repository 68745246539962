import React from "react";

import {
  CompaniesListComponent,
  CompaniesSearchComponent,
  CompaniesDetailComponent
} from "./components";
import { AdminLayout } from "../../shared/layouts/admin.layout";
import { CompanyModel } from "../../shared/models";

const INITIAL_FILTER = {
  name: "",
  document: "",
  orderBy: 'name',
  orderDirection: 'asc',
}

export function CompaniesPage() {
  const [filters, setFilters] = React.useState(INITIAL_FILTER);
  const [companies, setCompanies] = React.useState(CompanyModel.fromArray([]));
  const [loading, setLoading] = React.useState(false);

  const update = (company) => {
    setCompanies(companies.map(s => s.id === company.id ? company : s))
  }

  return (
    <AdminLayout
      DrawerRightComponent={<CompaniesDetailComponent update={update} />}
      HeaderComponent={
        <CompaniesSearchComponent
          setFilters={setFilters}
          INITIAL_FILTER={INITIAL_FILTER}
          setLoading={setLoading} />
      }
      BodyComponent={<CompaniesListComponent
        filters={filters}
        setFilters={setFilters}
        companies={companies}
        setCompanies={setCompanies}
        loading={loading}
        setLoading={setLoading} />
      }
    />
  );

}

