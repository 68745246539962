import { AuthRepository } from "./auth.repository";
import { Repository } from "./repository";
import { VehicleModel } from "../models";

export class VehiclesRepository extends Repository {
  constructor() {
    super();
    this.authRepository = new AuthRepository();
  }

  async getAll({ queryParams = "", page = 1, perPage = 10, orderBy = "id", orderDirection = "asc" }) {
    return this.api.get({ path: `vehicles?q=${queryParams}&page=${page}&per_page=${perPage}&order_by=${orderBy}&order_direction=${orderDirection}`, headers: this.headers() })
      .then(response => {
        return Promise.resolve({ data: VehicleModel.fromArray(response.data), total: response.headers["total"] });
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.getAll.bind(this),
      }));
  }

  async create(vehicle) {
    return this.api.post({ path: "vehicles", headers: this.headers(), body: { vehicle: this.omitKeys(vehicle, ["id"]) } })
      .then(response => {
        this.newSnackbarSuccess("Vehicle criado");
        return Promise.resolve(VehicleModel.fromJSON(response.data));
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.create.bind(this),
      }));
  }

  async update(vehicle) {
    return this.api.put({ path: `vehicles/${vehicle.id}`, headers: this.headers(), body: { vehicle: this.omitKeys(vehicle, ["id"]) } })
      .then(response => {
        this.newSnackbarSuccess("Vehicle atualizado");
        return Promise.resolve(VehicleModel.fromJSON(response.data));
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.update.bind(this),
      }));
  }

  async delete(id) {
    return this.api.delete({ path: `vehicles/${id}`, headers: this.headers() })
      .then(response => {
        this.newSnackbarSuccess([`Veículo deletado`]);
        return Promise.resolve(response.data);
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.delete.bind(this),
      }));
  }

}
