
import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  set_logs: [],
  clear_logs: [],
})

const INITIAL_STATE = []

const set_logs = (state = INITIAL_STATE, action) => {
  return action.value
}

const clear_logs = (state = INITIAL_STATE, action) => {
  return INITIAL_STATE
}

export default createReducer(INITIAL_STATE,
  {
    [Types.SET_LOGS]: set_logs,
    [Types.CLEAR_LOGS]: clear_logs
  })
