import React from "react";
import { Table, TableRow, TableBody, TableCell, TableHead, TableContainer, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Swal from "sweetalert2";

import {
  TableRowLoadingComponent,
  DeleteIconButton,
  EditIconButton,
  LogsIconButton,
  TablePaginationComponent
} from "../../../shared/components";
import { DeliveriesRepository } from "../../../shared/repositories";
import { authorizeAdmin } from "../../../shared/helpers";
import { useDrawerRightContext } from "../../../shared/contexts/drawer_right.context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faSortAsc, faSortDesc } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";


const useStyles = makeStyles(theme => ({
  tableCell: {
    whiteSpace: "nowrap",
    fontSize: "13px",
  },
  tableCellHead: {
    whiteSpace: "nowrap",
    fontSize: "13px",
    fontWeight: "bold",
  },
  tableBody: {
    overflowX: "auto",
    maxHeight: 500
  }
}));

export function DeliveriesListComponent({ filters, setFilters = () => { }, deliveries = [], setDeliveries = () => { }, loading = false, setLoading = () => { }, resolveFilters = () => { } }) {
  const deliveriesRepository = new DeliveriesRepository();
  const auth = useSelector(state => state.auth);
  const [pagination, setPagination] = React.useState({ page: 1, perPage: 100, total: 0 });
  const classes = useStyles();
  const { setNewDrawerValue } = useDrawerRightContext();

  const viewMore = delivery => e => {
    e.preventDefault();
    setNewDrawerValue(delivery.id)
  }

  const viewLogs = delivery => e => {
    e.preventDefault();
    return deliveriesRepository.getLogs(delivery).then(result => {
      setNewDrawerValue(result.data)
    })
  }

  React.useEffect(() => {
    setLoading(true)
    const timer = setTimeout(() => {
      getDeliveries()
    }, 1000);
    return () => clearTimeout(timer);
  }, [pagination.page, pagination.perPage, filters]);

  const getDeliveries = () => {
    const resolvedFilters = resolveFilters();
    let options = {
      page: pagination.page,
      perPage: pagination.perPage,
      orderBy: filters.orderBy,
      orderDirection: filters.orderDirection
    };
    if (resolvedFilters.length > 1) {
      options = { ...options, queryParams: resolvedFilters }
    }
    deliveriesRepository[resolvedFilters.length > 1 ? 'getAll' : 'toBeDeliveredToday'](options)
      .then(result => {
        setDeliveries(result.data)
        setPagination({ ...pagination, total: result.total })
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => setLoading(false))
  }

  const orderBy = (orderBy) => e => {
    setFilters({ ...filters, orderBy: orderBy, orderDirection: filters.orderDirection === 'asc' ? 'desc' : 'asc' })
  }

  const SortIcon = ({ orderBy }) => {
    if (filters.orderBy === orderBy) {
      if (filters.orderDirection === 'asc') {
        return <FontAwesomeIcon icon={faSortAsc} />
      } else {
        return <FontAwesomeIcon icon={faSortDesc} />
      }
    } else {
      return <FontAwesomeIcon icon={faSort} />
    }
  }

  const deleteRow = delivery => e => {
    return Swal.fire({
      title: 'Confirmar?',
      showDenyButton: true,
      showCancelButton: true,
      icon: 'warning',
      confirmButtonText: 'Deletar',
      denyButtonText: 'Cancelar',
      confirmButtonColor: "#DD6B55",
      html: `Pedido <b>${delivery.legacyOrderId}</b> será deletado e <br/><b>Você não poderá reverter esta ação!</b>`,
    }).then((result) => {
      if (result.isConfirmed)
        return deliveriesRepository.delete(delivery.id)
      else
        return Promise.reject('')
    })
  }

  const getWeight = (deliveries) => {
    const totalWeight = deliveries.reduce((acc, delivery) => {
      const weight = parseFloat(delivery.weight);
      return acc + (isNaN(weight) ? 0 : weight);
    }, 0);
    return totalWeight.toFixed(2);
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="right" colSpan={9}>{deliveries.length} itens</TableCell>
            <TableCell align="right" colSpan={1}>{getWeight(deliveries)} kg</TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell className={classes.tableCellHead} align="left" onClick={orderBy("legacy_order_id")}>Pedido <SortIcon orderBy="legacy_order_id" /></TableCell>
            <TableCell className={classes.tableCellHead}>Cliente</TableCell>
            <TableCell className={classes.tableCellHead}>Motorista</TableCell>
            <TableCell className={classes.tableCellHead} align="left" onClick={orderBy("status")}>Status<SortIcon orderBy="status" /></TableCell>
            <TableCell className={classes.tableCellHead}>Data de entrega prevista</TableCell>
            <TableCell className={classes.tableCellHead}>Entregue em</TableCell>
            <TableCell className={classes.tableCellHead} align="left" onClick={orderBy("address_city")}>Cidade / Estado<SortIcon orderBy="address_city" /></TableCell>
            <TableCell className={classes.tableCellHead}>Região</TableCell>
            <TableCell className={classes.tableCellHead}>Peso (Kg)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {loading ?
            <TableRowLoadingComponent cols={10} buttons={3} />
            : deliveries.map(row => (
              <TableRow key={row.id} className={classes.tableRow}>
                <TableCell className={classes.tableCell}>
                  <DeleteIconButton onClick={deleteRow(row)} disabled={!authorizeAdmin(auth)} />
                  <EditIconButton onClick={viewMore(row)} />
                  <LogsIconButton onClick={viewLogs(row)} />
                </TableCell>
                <TableCell className={classes.tableCell}><b>{row.legacyOrderId}</b></TableCell>
                <TableCell className={classes.tableCell}>{row.client?.name}</TableCell>
                <TableCell className={classes.tableCell}>{row.driver?.name}</TableCell>
                <TableCell className={classes.tableCell}>{row.resolvedStatusName}</TableCell>
                <TableCell className={classes.tableCell}>{row.expectedDeliveryInFormatted}</TableCell>
                <TableCell className={classes.tableCell}>{row.deliveredAtFormatted}</TableCell>
                <TableCell className={classes.tableCell}>{row.addressCity}/{row.addressState}</TableCell>
                <TableCell className={classes.tableCell}>{row.region?.name}</TableCell>
                <TableCell className={classes.tableCell}>{row.weightFormatted} Kg</TableCell>
              </TableRow>
            ))}
          {!loading && !deliveries[0] ? <TableRow key="none" className={classes.tableRow}>
            <TableCell component="th" scope="row" colSpan="8" style={{ height: "300px" }}>
            </TableCell>
          </TableRow> : null}
        </TableBody>
        <TablePaginationComponent
          colSpan={10}
          count={pagination.total}
          rowsPerPage={pagination.perPage}
          page={pagination.page}
          loading={loading}
          onPageChange={value => { setPagination({ ...pagination, page: value }) }}
          onRowsPerPageChange={value => { setPagination({ ...pagination, page: 1, perPage: value }) }}
        />
      </Table>
    </TableContainer >)
}
