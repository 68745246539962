import React from "react";
import { TableCell, TableRow } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  tableCell: {
    whiteSpace: "nowrap",
  },
  buttonCell: {
    display: "flex",
    flexWrap: "nowrap",
  }
}));

export function TableRowLoadingComponent({ cols = 1, buttons = 1 }) {
  const classes = useStyles();
  const columns = cols - (buttons ? 1 : 0);

  return [...Array(10)].map((_, index) => (
    <TableRow key={index}>
      {buttons > 0 && <TableCell align="center" className={classes.buttonCell}>
        {[...Array(buttons)].map((_, index) => (
          <Skeleton key={index} variant="circle" width={30} height={30} />
        ))}
      </TableCell>}
      {[...Array(columns)].map((col, index) => <TableCell key={index} align="center" className={classes.tableCell}>
        <Skeleton variant="text" />
      </TableCell>
      )}
    </TableRow>
  ));
}
