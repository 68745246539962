
import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  set_region: ["id", "name", "beginning_zipcode", "end_zipcode", "max_weight_in_day", "updated_at", "created_at"],
  change_region: ["id", "name", "beginning_zipcode", "end_zipcode", "max_weight_in_day", "updated_at", "created_at"],
  clear_region: ["id", "name", "beginning_zipcode", "end_zipcode", "max_weight_in_day", "updated_at", "created_at"],
})

const INITIAL_STATE = {
  id: "",
  name: "",
  beginning_zipcode: "",
  end_zipcode: "",
  max_weight_in_day: "",
  updated_at: "",
  created_at: "",
}

const set_region = (state = INITIAL_STATE, action) => {
  return action.value
}

const change_region = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.value
  }
}

const clear_region = (state = INITIAL_STATE, action) => {
  return INITIAL_STATE
}

export default createReducer(INITIAL_STATE,
  {
    [Types.SET_REGION]: set_region,
    [Types.CHANGE_REGION]: change_region,
    [Types.CLEAR_REGION]: clear_region,
  })
