import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Badge, Typography, Popper, Fade, Paper, ListItem,
  ListItemText, ListSubheader, List
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  title: {
    cursor: "pointer",
    display: 'block'
  },
  popper: {
    zIndex: 1282,
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
    maxHeight: 400,
    width: 400,
    [theme.breakpoints.down('sm')]: {
      width: "95%",
    },
  },
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  marginRight: {
    marginRight: theme.spacing(2)
  },
}));

export function NotificationsNavbarComponent() {
  let [notifications, setNotifications] = React.useState({ content: [] });
  const [open, setOpen] = React.useState(false);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const classes = useStyles();

  const handleClick = event => {
    setAnchorEl2(event.currentTarget);
    setOpen(true);
  };

  const handleClickAway = event => {
    setOpen(false);
  };

  function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }

    return color;
  }

  const removeDuplicates = (arr, lower = false) => {
    if (!Array.isArray(arr))
      return arr;

    let tArr = arr.filter((v, i) => arr.indexOf(v) === i)
    if (lower)
      tArr = tArr.map(i => i.toLowerCase())

    return tArr
  }

  const listItemsNotification = () => {
    if (notifications && notifications.total > 0) {
      let nt = notifications.content.sort((a, b) => a.viewed && new Date(a.created_at) < new Date(b.created_at));
      return nt.map((item) => (
        <ListItem button >
          <Badge color="secondary" badgeContent=" " invisible={item.viewed} variant="dot" showZero className={classes.marginRight}>
          </Badge>
          <ListItemText primary={!item.viewed ? <b>{item.title}</b> : item.title} secondary={!item.viewed ? <b>{new Date(item.created_at).toLocaleString()}</b> : new Date(item.created_at).toLocaleString()} />
        </ListItem>
      ))
    }
    else
      return <ListSubheader className={classes.subheader}>Não há notificaçōes</ListSubheader>
  }

  return (
    <div className={classes.root}>
      <Popper className={classes.popper} open={open} anchorEl={anchorEl2} placement='bottom-end' transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper square className={classes.paper}>
              <Typography className={classes.text} variant="h5" gutterBottom>
                {notifications && notifications.unread} Notificaçōes
              </Typography>
              <List className={classes.list}>
                {listItemsNotification()}
              </List>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>

  );
}
