import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, MenuItem, List, ListItem, Tooltip, Menu } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Axios from "axios";
import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { blue } from "@material-ui/core/colors";
import { MoreVert } from "@material-ui/icons";
import Swal from "sweetalert2";

import {
  DateInputFieldComponent,
  DateTimeInputFieldComponent,
  NumberPhoneInputFieldComponent,
  NumberZipCodeInputFieldComponent,
  TextInputFieldComponent,
  SaveButton,
  BackButton,
  IconButton
} from "../../../shared/components";
import {
  authorizeChecker,
  Constants,
  getDiff,
  isEquals,
  t
} from "../../../shared/helpers";
import {
  DeliveriesRepository,
} from "../../../shared/repositories";
import { DeliveryModel } from "../../../shared/models";
import { DeliveriesAddImagesDialogComponent, DeliveriesDetailImagesComponent } from "./images";
import { DeliveriesAutoCompleteClientComponent } from "./deliveries_auto_complete_client.component";
import { useSnackbar } from "notistack";
import { useDrawerRightContext } from "../../../shared/contexts/drawer_right.context";


const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '400px',
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '500px',
    }
  },
  gridItem: {
  },
  gridItemImages: {
    display: 'inline-block',
  },
  textField: {
    paddingRight: theme.spacing(1),
  },
  iconSearch: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    color: blue[500],
    maxHeight: "30px",
    maxWidth: "30px",
    '&:hover': {
      color: blue[700],
    },
  }
}));

export function DeliveriesDetailComponent({ update, users, stores, regions }) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const auth = useSelector(state => state.auth);
  const [delivery, setDelivery] = React.useState({});
  const [originalDelivery, setOriginalDelivery] = React.useState({});
  const [drivers, setDrivers] = React.useState([]);
  const [sellers, setSellers] = React.useState([]);
  const [checkers, setCheckers] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [disableZipCode, setDisableZipCode] = React.useState(false);
  const deliveriesRepository = new DeliveriesRepository();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openImages, setOpenImages] = React.useState(false);
  const { isDrawerRightOpen, drawerValue, toggleDrawerRightOpen } = useDrawerRightContext();

  React.useEffect(() => {
    setLoading(true);
    deliveriesRepository.get(drawerValue)
      .then(data => {
        setDelivery(data.toState());
        setOriginalDelivery(data.toState());
      })
      .finally(() => setLoading(false));
  }, [drawerValue]);

  const resolveValue = (key, value) => {
    if (key === "freight")
      return value === "R$ NaN" ? "R$ 0,00" : value
    if (value) {
      if (typeof value === "object")
        return value.name || [value.first_name, value.last_name].join(" ")
      if (key === "weight")
        return `${value} kg`
      if (key === "driver_id" || key === "chief_driver_id" || key === "checker_id" || key === "seller_id")
        return users.find(i => i.id === value)?.name
      if (key === "store_id")
        return stores.find(i => i.id === value)?.name
      if (key === "region_id")
        return regions.find(i => i.id === value)?.name
      if (key.includes("_at"))
        return new Date(value).toLocaleString('pt-br')
      if (key === "status" || key === "lasted_status")
        return Constants.Delivery.Statuses.find(i => i.label == [value]).value
      if (key === "freight_type")
        return Constants.Delivery.FreightTypes.find(i => i.label == [value]).value
      if (key === "duplicate_reason")
        return Constants.Delivery.DeliveryReasons.find(i => i.label == [value]).value
    }
    return value || "";
  }

  // html of diferences to show in swal
  const getDiferences = (diferences) => {
    let html = '<div style="text-align: left;">' +
      `<p><b>Alterações:</b></p>` +
      '<table style="width: 100%;" border="1">' +
      '<tr>' +
      '<th style="text-align: left;">Campo</th>' +
      '<th style="text-align: left;">De</th>' +
      '<th style="text-align: left;">Para</th>' +
      '</tr>';
    Object.keys(diferences).forEach(key => {
      if (key === "images") return;
      if (key === "clientId") return;
      html += '<tr>' +
        `<td>${t(`delivery.${key}`)}</td>` +
        `<td>${resolveValue(key, diferences[key].from)}</td>` +
        `<td>${resolveValue(key, diferences[key].to)}</td>` +
        '</tr>';
    });
    html += '</table>' +
      '</div>';
    return html;
  }

  const saveDelivery = _ => {
    const deliveryModel = new DeliveryModel(delivery);
    const originalDeliveryModel = new DeliveryModel(originalDelivery);

    if (isEquals(deliveryModel.toJSON(), originalDeliveryModel.toJSON())) {
      enqueueSnackbar("Nenhuma alteração foi realizada", { variant: "warning" });
      return Promise.resolve();
    }
    const diferences = getDiff(originalDeliveryModel.toJSON(), deliveryModel.toJSON());
    return Swal.fire({
      title: 'Confirma alteração?',
      html: getDiferences(diferences),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, alterar!',
      cancelButtonText: 'Não, cancelar!',
    }).then((result) => {
      if (result.isConfirmed) {
        return deliveriesRepository.update(deliveryModel.toJSON()).then(data => {
          update(data)
          setDelivery(data.toState())
          setOriginalDelivery(data.toState())
        })
      } else {
        return Promise.resolve();
      }
    })
  }

  React.useEffect(() => {
    if (delivery.addressZipCode) {
      const zipcode = delivery.addressZipCode.replace(/\D/g, "");
      setDisableZipCode(zipcode.length < 8);
    }
  }, [delivery.addressZipCode]);

  const getZipCode = _ => {
    const zipcode = delivery.addressZipCode.replace(/\D/g, "");
    setDisableZipCode(true);
    Axios.get(`https://api.hubcoapp.com.br/zipcodes/${zipcode}`)
      .then(response => {
        if (!response.data.erro) {
          setDelivery({
            ...delivery,
            addressStreet: response.data.place,
            addressNeighborhood: response.data.neighborhood,
            addressCity: response.data.city.name,
            addressState: response.data.city.state.acronym
          })
        } else {
          enqueueSnackbar("Cep não encontrado", { variant: "error" });
        }
      })
      .catch(error => {
        enqueueSnackbar("Cep não encontrado", { variant: "error" });
      })
      .finally(() => { setDisableZipCode(false); });
  }

  React.useEffect(() => {
    setDrivers(users.filter(user => [Constants.User.Role.Driver, Constants.User.Role.ChiefDriver].includes(user.role)));
    setCheckers(users.filter(user => user.role === Constants.User.Role.Checker));
    setSellers(users.filter(user => user.role === Constants.User.Role.Seller));
  }, [users]);

  const handleChange = name => event => {
    let change = { [name]: event.target.value };
    if (name === "driverId") {
      const driver = drivers.find(driver => driver.id === event.target.value);
      change = { driver: { id: driver.id, name: driver.name } };
    }
    if (name === "storeId") {
      const store = stores.find(store => store.id === event.target.value);
      change = { store: { id: store.id, name: store.name } };
    }
    if (name === "sellerId") {
      const seller = sellers.find(seller => seller.id === event.target.value);
      change = { seller: { id: seller.id, name: seller.name } };
    }
    if (name === "checkerId") {
      const checker = checkers.find(checker => checker.id === event.target.value);
      change = { checker: { id: checker.id, name: checker.name } };
    }
    if (name === "regionId") {
      const region = regions.find(region => region.id === event.target.value);
      change = { region: { id: region.id, name: region.name } };
    }
    setDelivery((prev) => ({ ...prev, ...change }));
  };

  const handleChangeClient = (clientId, clientName) => {
    setDelivery((prev) => ({ ...prev, client: { id: clientId, first_name: clientName } }));
  };

  const handleDateChange = name => date => {
    setDelivery({ ...delivery, [name]: date ? date._d : null });
  };

  const goBack = () => {
    toggleDrawerRightOpen();
  };

  const sendSms = () => {
    deliveriesRepository.sendSmsWillBeDeliveredTodayOne(delivery.id);
  };

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const MoreOptions = () => {
    return <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={closeMenu}
    >
      <MenuItem onClick={sendSms} disabled={delivery.smsWillBeDeliveredTodayAt || Object.values(Constants.Delivery.Status).indexOf(delivery.status) > Object.values(Constants.Delivery.Status).indexOf(Constants.Delivery.Status.Delivered)}>Enviar SMS</MenuItem>
      <MenuItem onClick={() => { setOpenImages(true); closeMenu() }}>Adicionar Imagens</MenuItem>
      <MenuItem onClick={() => { deleteAllImages(); closeMenu() }}>Excluir Imagens</MenuItem>
      <MenuItem onClick={() => { duplicateDelivery(); closeMenu() }}>Duplicar Entrega</MenuItem>
    </Menu>
  }

  const deleteAllImages = () => {
    Swal.fire({
      title: 'Tem certeza que deseja excluir todas as imagens?',
      html: 'Esta ação não poderá ser desfeita!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, Excluir tudo!',
      cancelButtonText: 'Não, cancelar!',
    }).then((result) => {
      if (result.isConfirmed) {
        return deliveriesRepository.deleteAllImages(delivery.id).then(data => {
          update(data)
          setDelivery(data.toState())
          setOriginalDelivery(data.toState())
        })
      } else {
        return Promise.resolve();
      }
    })
  }


  const duplicateDelivery = () => {
    Swal.fire({
      title: 'Por que voce esta duplicando a entrega?',
      html:
        '<select id="swal-input1" class="swal2-input"> ' +
        '<option value="order_split">Divisão do pedido</option>' +
        '<option value="goods_missing">Falta de mercadoria</option>' +
        '<option value="damage_redelivery">Reentrega por avaria</option>' +
        '<option value="operational_dc_problem">Reentrega por erro operacional cd</option>' +
        '<option value="operational_store_problem">Reentrega por erro operacional loja</option>' +
        '<option value="other">Outro</option>' +
        '</select>' +
        '<label style="float: left;">Observações</label>' +
        '<input id="swal-input2" class="swal2-input" />',
      focusConfirm: false,
      preConfirm: () => {
        return [
          document.getElementById('swal-input1').value,
          document.getElementById('swal-input2').value
        ]
      },
      showDenyButton: true,
      showCancelButton: true,
      icon: 'question',
      confirmButtonText: 'Duplicar',
      denyButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed)
        return deliveriesRepository.duplicateDelivery(delivery, { duplicate_reason: result.value[0], duplicate_remark: result.value[1] })
          .then(data => {
            update(data)
            if (!delivery.legacyOrderId.includes("-")) {
              update({ ...delivery, legacyOrderId: `${delivery.legacyOrderId}-1` })
              setDelivery({ ...delivery, legacyOrderId: `${delivery.legacyOrderId}-1` })
            }
          });
    })
  }

  return (
    <Grid className={classes.root}>
      <DeliveriesAddImagesDialogComponent open={openImages} handleClose={() => setOpenImages(false)} delivery={delivery} />
      <List>
        <ListItem className={classes.gridItem}>
          <Grid item xs={6} md={6} style={{ display: "flex", justifyContent: "flex-start" }}>
            <Typography variant="h6" gutterBottom>Dados da Entrega</Typography>
          </Grid>
          <Grid item xs={6} md={6} style={{ display: "flex", justifyContent: "flex-end" }}>
            {authorizeChecker(auth) &&
              <div>
                <IconButton onClick={openMenu}> <MoreVert /> </IconButton>
                <MoreOptions />
              </div>
            }
          </Grid>
        </ListItem >
        {delivery.images?.length ? <ListItem className={classes.gridItemImages}>
          <DeliveriesDetailImagesComponent delivery={delivery} />
        </ListItem > : null}
        <ListItem className={classes.gridItem}>
          <TextInputFieldComponent
            id="legacyOrderId"
            label="Pedido"
            value={delivery.legacyOrderId}
            readOnly
          />
        </ListItem >
        <ListItem className={classes.gridItem}>
          <TextInputFieldComponent
            id="status"
            select
            label="Status"
            value={delivery.status}
            onChange={handleChange("status")}>
            {Constants.Delivery.Statuses.filter(status => Object.values(Constants.Delivery.Status).includes(status.label)).map((option) => (
              <MenuItem key={option.label} value={option.label}>
                {option.value}
              </MenuItem>
            ))}
          </TextInputFieldComponent>
        </ListItem >
        {delivery.status === Constants.Delivery.Status.Problem ?
          <ListItem className={classes.gridItem}>
            <TextInputFieldComponent
              id="problemDescription"
              label="Observações do problema"
              value={delivery.problemDescription}
              multiline
              minRows={2}
              onChange={handleChange('problemDescription')}
            />
          </ListItem >
          : null}
        {delivery.status === Constants.Delivery.Status.Delivered ?
          <ListItem className={classes.gridItem}>
            <TextInputFieldComponent
              id="deliveredTo"
              label="Entregue para"
              value={delivery.deliveredTo}
              multiline
              minRows={2}
              onChange={handleChange('deliveredTo')}
            />
          </ListItem >
          : null}
        <ListItem className={classes.gridItem}>
          <TextInputFieldComponent
            id="storeId"
            select
            label="Loja"
            value={delivery.store?.id}
            onChange={handleChange("storeId")}>
            {stores.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextInputFieldComponent>
        </ListItem >
        <ListItem className={classes.gridItem}>
          <TextInputFieldComponent
            id="driverId"
            select
            label="Motorista"
            value={delivery.driver?.id}
            onChange={handleChange("driverId")}>
            {drivers.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextInputFieldComponent>
        </ListItem >
        <ListItem className={classes.gridItem}>
          <TextInputFieldComponent
            id="checkerId"
            select
            label="Conferente"
            value={delivery.checker?.id}
            onChange={handleChange("checkerId")}>
            {checkers.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextInputFieldComponent>
        </ListItem >
        <ListItem className={classes.gridItem}>
          {isDrawerRightOpen && <DeliveriesAutoCompleteClientComponent
            key={delivery.client?.id}
            defaultValue={delivery.client}
            onSelect={handleChangeClient}
          />}
        </ListItem >
        <ListItem className={classes.gridItem}>
          <TextInputFieldComponent
            id="sellerId"
            select
            label="Vendedor"
            value={delivery.seller?.id}
            onChange={handleChange("sellerId")}>
            {sellers.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextInputFieldComponent>
        </ListItem >
        <ListItem className={classes.gridItem}>
          <TextInputFieldComponent
            id="freightType"
            select
            label="Tipo de Frete"
            value={delivery.freightType}
            onChange={handleChange("freightType")}>
            {Constants.Delivery.FreightTypes.filter(i => i.label !== "all").map((option) => (
              <MenuItem key={option.label} value={option.label}>
                {option.value}
              </MenuItem>
            ))}
          </TextInputFieldComponent>
        </ListItem >
        <ListItem className={classes.gridItem}>
          <NumberFormat
            id="freight-input"
            label="Valor"
            value={delivery.freight}
            onValueChange={(values) => {
              handleChange("freight")({ target: { value: values.formattedValue.replace("R$ ", "") } })
            }}
            decimalSeparator=","
            thousandSeparator="."
            prefix="R$ "
            customInput={TextInputFieldComponent}
            decimalScale={2}
          />
        </ListItem >
        <ListItem className={classes.gridItem}>
          <NumberFormat
            id="weight-input"
            label="Peso"
            value={delivery.weight}
            onValueChange={(values) => {
              handleChange("weight")({ target: { value: values.formattedValue.replace(" kg", "") } })
            }}
            decimalSeparator=","
            thousandSeparator="."
            suffix=" kg"
            customInput={TextInputFieldComponent}
          />
        </ListItem >
        <ListItem className={classes.gridItem}>
          <Typography variant="body1" gutterBottom>Contato</Typography>
        </ListItem >
        <ListItem className={classes.gridItem}>
          <NumberPhoneInputFieldComponent
            value={delivery.phoneNumber || ""}
            onChange={handleChange('phoneNumber')}
          />
        </ListItem>
        <ListItem className={classes.gridItem}>
          <TextInputFieldComponent
            id="emailAddress"
            label="E-mail"
            value={delivery.emailAddress}
            onChange={handleChange('emailAddress')}
          />
        </ListItem >
        <ListItem className={classes.gridItem}>
          <Typography variant="body1" gutterBottom>Endereço</Typography>
        </ListItem >
        <ListItem className={classes.gridItem}>
          <Tooltip title="buscar CEP" aria-label="buscar CEP" enterDelay={500} placement="top">
            <IconButton className={classes.iconSearch} onClick={getZipCode} disabled={disableZipCode}>
              <FontAwesomeIcon icon={faSearch} />
            </IconButton>
          </Tooltip>
          <NumberZipCodeInputFieldComponent
            value={delivery.addressZipCode}
            onChange={handleChange('addressZipCode')}
            onKeyDown={(e) => { if (e.key === "Enter") getZipCode() }}
          />
        </ListItem >
        <ListItem className={classes.gridItem}>
          <TextInputFieldComponent
            id="addressStreet"
            label="Logradouro"
            value={delivery.addressStreet}
            onChange={handleChange('addressStreet')}
          />
        </ListItem >
        <ListItem className={classes.gridItem}>
          <TextInputFieldComponent
            id="addressNumber"
            label="Número"
            value={delivery.addressNumber}
            onChange={handleChange('addressNumber')}
          />
        </ListItem >
        <ListItem className={classes.gridItem}>
          <TextInputFieldComponent
            id="addressComplement"
            label="Complemento"
            value={delivery.addressComplement}
            onChange={handleChange('addressComplement')}
          />
        </ListItem >
        <ListItem className={classes.gridItem}>
          <TextInputFieldComponent
            id="addressNeighborhood"
            label="Bairro"
            value={delivery.addressNeighborhood}
            onChange={handleChange('addressNeighborhood')}
          />
        </ListItem >
        <ListItem className={classes.gridItem}>
          <TextInputFieldComponent
            id="addressState"
            label="Estado"
            value={delivery.addressState}
            onChange={handleChange('addressState')}
          />
        </ListItem >
        <ListItem className={classes.gridItem}>
          <TextInputFieldComponent
            id="addressCity"
            label="Cidade"
            value={delivery.addressCity}
            onChange={handleChange('addressCity')}
          />
        </ListItem >
        <ListItem className={classes.gridItem}>
          <TextInputFieldComponent
            id="regionId"
            select
            label="Região"
            value={delivery.region?.id}
            onChange={handleChange("regionId")}>
            {regions.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextInputFieldComponent>
        </ListItem >
        <ListItem className={classes.gridItem}>
          <Typography variant="body1" gutterBottom>Datas</Typography>
        </ListItem >
        <ListItem className={classes.gridItem}>
          <DateInputFieldComponent
            id="expectedDeliveryIn"
            label="Data de Entrega prevista"
            value={delivery.expectedDeliveryIn}
            error={false}
            className={classes.textField}
            helperText={null}
            onChange={handleDateChange("expectedDeliveryIn")}
          />
        </ListItem >
        <ListItem className={classes.gridItem}>
          <DateTimeInputFieldComponent
            id="separatedAt"
            label="Separado em"
            value={delivery.separatedAt}
            error={false}
            className={classes.textField}
            helperText={null}
            onChange={handleDateChange("separatedAt")}
          />
        </ListItem >
        <ListItem className={classes.gridItem}>
          <DateTimeInputFieldComponent
            id="deliveredAt"
            label="Entregue em"
            value={delivery.deliveredAt}
            error={false}
            className={classes.textField}
            helperText={null}
            onChange={handleDateChange("deliveredAt")}
          />
        </ListItem >
        <ListItem className={classes.gridItem}>
          <TextInputFieldComponent
            id="remark"
            label="Observações"
            value={delivery.remark}
            multiline
            minRows={2}
            onChange={handleChange('remark')}
          />
        </ListItem >
        <ListItem className={classes.gridItem}>
          <NumberFormat
            id="orderValue-input"
            label="Valor do Pedido"
            value={delivery.orderValue}
            disabled={true}
            decimalSeparator=","
            thousandSeparator="."
            prefix="R$ "
            customInput={TextInputFieldComponent}
            decimalScale={2}
          />
        </ListItem >
        <ListItem className={classes.gridItem}>
          <TextInputFieldComponent
            id="smsWillBeDeliveredTodayAt"
            label="Sms será entregue hoje"
            disabled={true}
            value={delivery.smsWillBeDeliveredTodayAtFormatted}
            error={false}
            className={classes.textField}
          />
        </ListItem >
        <ListItem className={classes.gridItem}>
          <TextInputFieldComponent
            id="smsOutForDeliveryAtFormatted"
            label="Sms Saiu para Entrega"
            disabled={true}
            value={delivery.smsOutForDeliveryAtFormatted}
            error={false}
            className={classes.textField}
          />
        </ListItem >
        <ListItem className={classes.gridItem}>
          <TextInputFieldComponent
            id="smsDeliveredAtFormatted"
            label="Sms Entregue"
            disabled={true}
            value={delivery.smsDeliveredAtFormatted}
            error={false}
            className={classes.textField}
          />
        </ListItem >
        <ListItem className={classes.gridItem}>
          <TextInputFieldComponent
            id="duplicateReason"
            select
            label="Motivo de Duplicar"
            value={delivery.duplicateReason}
            onChange={handleChange("duplicateReason")}>
            {Constants.Delivery.DeliveryReasons.filter((option) => option.label !== "all").map((option) => (
              <MenuItem key={option.label} value={option.label}>
                {option.value}
              </MenuItem>
            ))}
          </TextInputFieldComponent>
        </ListItem >
        <ListItem className={classes.gridItem}>
          <TextInputFieldComponent
            id="duplicateRemark"
            label="Motivo de duplicar - Observações"
            value={delivery.duplicateRemark}
            onChange={handleChange('duplicateRemark')}
          />
        </ListItem >
        <ListItem className={classes.gridItem} style={{ display: "flex", justifyContent: "space-between" }}>
          <BackButton onClick={goBack} loading={loading} />
          <SaveButton onClick={saveDelivery} loading={loading} disabled={!authorizeChecker(auth)} />
        </ListItem >
      </List >
    </Grid >
  );

}

