import React from 'react';

import { ReportsDeliveriesDriversComponent } from './components';
import { AdminLayout } from '../../shared/layouts/admin.layout';

export function ReportsPage() {

  return (
    <AdminLayout
      BodyComponent={<ReportsDeliveriesDriversComponent />}
    />
  )
}
