import React from "react";
import EditIcon from "@material-ui/icons/Edit";
import { IconButton } from "./";
import { Tooltip } from "@material-ui/core";

export function EditIconButton(props) {
  return (
    <IconButton {...props}>
      <Tooltip title="Editar"><EditIcon /></Tooltip>
    </IconButton>
  );
}
