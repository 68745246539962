import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { MenuItem, Paper, Grid } from "@material-ui/core";

import {
  DefaultButton,
  TextInputFieldComponent
} from "../../../../../shared/components";
import { Constants } from "../../../../../shared/helpers";



const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    // maxHeight: 500
  },
  gridItem: {
    padding: theme.spacing(1),
  },
  container: {
    marginTop: theme.spacing(1),
    overflowX: 'auto',
  },
  textField: {
  }
}));

export function TableSearchComponent({ setFilters, INITIAL_FILTERS, setLoading }) {
  const classes = useStyles();
  const [localFilters, setLocalFilters] = React.useState(INITIAL_FILTERS)

  const clearFilters = () => {
    setLocalFilters(INITIAL_FILTERS)
    setFilters(INITIAL_FILTERS);
  }

  React.useEffect(() => {
    setLoading(true)
    let waitSec = setTimeout(() => {
      setFilters(localFilters);
      setLoading(false)
    }, 1000);
    return () => clearTimeout(waitSec);
  }, [localFilters]);

  const handleChange = name => event => {
    setLocalFilters({ ...localFilters, [name]: event.target.value });
  };

  return <Paper className={classes.paper}>
    <Grid container >
      <Grid item xs={12} md={6}>
        <Grid item xs={12} md={12} className={classes.gridItem}>
          <TextInputFieldComponent
            id="order-input"
            label="Pedido"
            value={localFilters.legacyOrderId}
            onChange={handleChange("legacyOrderId")}
          />
        </Grid>
        <Grid item xs={12} md={12} className={classes.gridItem}>
          <TextInputFieldComponent
            id="client-name-input"
            label="Cliente"
            value={localFilters.clientName}
            onChange={handleChange("clientName")}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid item xs={12} md={12} className={classes.gridItem}>
          <TextInputFieldComponent
            id="driver-name-input"
            label="Motorista"
            value={localFilters.driverName}
            onChange={handleChange("driverName")}
          />
        </Grid>
        <Grid item xs={12} md={12} className={classes.gridItem}>
          <TextInputFieldComponent
            id="standard-select-status"
            variant="outlined"
            select
            label="Status"
            value={localFilters.status}
            onChange={handleChange("status")}>
            {Constants.Delivery.Statuses.map((option) => (
              <MenuItem key={option.label} value={option.label}>
                {option.value}
              </MenuItem>
            ))}
          </TextInputFieldComponent>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} style={{ display: "flex", justifyContent: "flex-end" }}>
        <DefaultButton onClick={clearFilters}>Limpar Filtros</DefaultButton>
      </Grid>
    </Grid>
  </Paper >
}
