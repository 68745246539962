import { Constants } from '../helpers';
import Entity from './entity.model';

const SUPPORTED_ATTRS = [
  'id',
  'reason',
  'startDate',
  'endDate',
  'description',
  'status',
  'regions',
  'createdAt',
  'updatedAt',
];

export class RestrictedDateModel extends Entity {
  constructor(attrs = {}) {
    super(attrs, SUPPORTED_ATTRS);
  }

  get startDateFormatted() {
    return this.startDate ? new Date(`${this.startDate} `).toLocaleDateString() : null;
  }

  get endDateFormatted() {
    return this.endDate ? new Date(`${this.endDate} `).toLocaleDateString() : null;
  }

  get _startDate() {
    return this.startDate && typeof this.startDate === 'date' ? this.startDate.toDateString() : this.startDate;
  }

  get _endDate() {
    return this.endDate && typeof this.endDate === 'date' ? this.endDate.toDateString() : this.endDate;
  }

  get resolvedStatusName() {
    var item = Constants.RestrictedDate.Statuses.find(status => status.label === this.status)
    return item ? item.value : this.status;
  }

  toJSON() {
    return {
      id: this.id,
      reason: this.reason,
      start_date: this._startDate,
      end_date: this._endDate,
      description: this.description,
      status: this.status,
      region_ids: this.regions?.map(i => i.id)
    };
  }
}
