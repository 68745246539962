
import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  set_vehicles: [],
  create_vehicles_item: [],
  update_vehicles_item: [],
  delete_vehicles_item: ['value'],
  clear_vehicles: [],
  delete_vehicle: ['value'],
})

const INITIAL_STATE = []

const set_vehicles = (state = INITIAL_STATE, action) => {
  return action.value
}

const create_vehicles_item = (state = INITIAL_STATE, action) => {
  return [action.value, ...state]
}

const update_vehicles_item = (state = INITIAL_STATE, action) => {
  return state.map(vehicle => {
    if (vehicle.id === action.value.id) {
      return action.value
    }
    return vehicle
  })
}

const delete_vehicles_item = (state = INITIAL_STATE, action) => {
  return state.filter(vehicle => vehicle.id !== action.value)
}

const clear_vehicles = (state = INITIAL_STATE, action) => {
  return INITIAL_STATE
}

const delete_vehicle = (state = INITIAL_STATE, action) => {
  return state.filter(vehicle => vehicle.id !== action.value)
}

export default createReducer(INITIAL_STATE,
  {
    [Types.SET_VEHICLES]: set_vehicles,
    [Types.CREATE_VEHICLES_ITEM]: create_vehicles_item,
    [Types.UPDATE_VEHICLES_ITEM]: update_vehicles_item,
    [Types.DELETE_VEHICLES_ITEM]: delete_vehicles_item,
    [Types.CLEAR_VEHICLES]: clear_vehicles,
    [Types.DELETE_VEHICLE]: delete_vehicle
  })
