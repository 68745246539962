import React from "react";
import {
  UsersListComponent,
  UsersSearchComponent,
  UsersDetailComponent,
} from "./components";
import { AdminLayout } from "../../shared/layouts/admin.layout";

const INITIAL_FILTER = {
  name: "",
  phoneNumber: "",
  role: "all",
  status: "all",
  orderBy: '',
  orderDirection: 'asc',
  page: 1,
  perPage: 10,
};

export function UsersPage() {
  const [filters, setFilters] = React.useState(INITIAL_FILTER);
  const [users, setUsers] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const updateOrCreate = (user) => {
    const index = users.findIndex(item => item.id === user.id)
    if (index > -1) {
      users[index] = user
    } else {
      users.unshift(user)
    }
    setUsers([...users])
  }

  return <AdminLayout
    DrawerRightComponent={<UsersDetailComponent update={updateOrCreate} />}
    HeaderComponent={<UsersSearchComponent
      title="Usuários"
      setFilters={setFilters}
      INITIAL_FILTER={INITIAL_FILTER}
      setLoading={setLoading}
    />}
    BodyComponent={<UsersListComponent
      filters={filters}
      setFilters={setFilters}
      loading={loading}
      setLoading={setLoading}
      users={users}
      setUsers={setUsers}
    />}
  />;
}
