import { useDispatch, useSelector } from "react-redux";
import { ApiHelper, Constants, omitKeys } from "../helpers";
import { useSnackbar } from "notistack";

const GetAuthState = () => useSelector(state => state.auth);
const GetDispatch = () => useDispatch();

export class Repository {
  constructor() {
    this.api = new ApiHelper();
    this.auth = GetAuthState();
    this.dispatch = GetDispatch();
    this.enqueueSnackbar = useSnackbar()["enqueueSnackbar"]
    this.Constants = Constants;
    this.omitKeys = omitKeys;
  }

  refreshAuthState() {
    this.auth = GetAuthState();
  }

  setAuthState(auth) {
    this.auth = auth;
  }

  headers() {
    return ({ Token: this.auth.token, Cid: this.auth.cid })
  }

  newSnackbarSuccess(messages) {
    if (messages) {
      if (typeof messages === "string")
        messages = [messages];

      messages.map(message => {
        this.enqueueSnackbar(message, Constants.SnackBar.Success)
      })
    }
  }

  newSnackbarError(messages) {
    if (messages) {
      if (typeof messages === "string")
        messages = [messages];

      messages.map(message => {
        this.enqueueSnackbar(message, Constants.SnackBar.Error)
      })
    }
  }

  resolveResponseBlob({ response, type, callback = () => { }, fileName = "file" }) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.${type}`);
    document.body.appendChild(link);
    link.click();
    this.newSnackbarSuccess(["Arquivo gerado com sucesso!"]);
    callback();
  }

  resolveErrorResponse({ error, callback = () => { }, callbackOnError = () => { } }) {
    try {
      console.log(error)
      if (error.response && error.response.status === 401) {
        if (error.response.data.error === "Token has expired") {
          return this.refreshToken(callback);
        } else {
          return this.newSnackbarError(error.response.data.errors);
        }
      }
      if (error.response && error.response.data && error.response.data.errors) {
        this.newSnackbarError(error.response.data.errors);
      } else {
        this.newSnackbarError(this.Constants.SnackBar.Error.message);
      }
    } catch (error) {
      this.newSnackbarError(this.Constants.SnackBar.Error.message);
    } finally {
      callbackOnError();
      return Promise.reject(error);
    }
  }

  resolveErrorResponseBlob({ error, callback = () => { }, callbackOnError = () => { } }) {
    try {
      if (error.response) {
        switch (error.response.status) {
          case 401:
            if (error.response.data.error === "Token has expired") {
              return this.refreshToken(callback);
            } else {
              return this.newSnackbarError("Não autorizado");
            };
          case 404:
            this.newSnackbarError(["Nenhum resultado encontrado!"]);
            break;
          default:
            this.newSnackbarError("Erro ao gerar arquivo");
            break;

        }
      }
    } catch (error) {
      this.newSnackbarError(this.Constants.SnackBar.Error.message);
    } finally {
      callbackOnError();
      return Promise.reject(error);
    }
  }

  async refreshToken(callback = () => { }) {
    return this.api.put({ path: "auth/refresh_token", headers: this.headers() })
      .then(response => {
        this.dispatch({ type: "AUTH_SIGN_IN", value: response.data });
        this.setAuthState(response.data);
        return callback(); // TODO: callback is not working
      })
      .catch(error => {
        this.dispatch({ type: "AUTH_SIGN_OUT" });
        return Promise.reject(error);
      });
  }
}
