
import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  new_snackbar: ['messages', 'options', 'url'],
})

const INITIAL_STATE = {
  messages: [],
  url: null,
  options: {
    variant: 'info',
    autoHideDuration: 3000,
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
  }
}

const new_snackbar = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.value
  }
}

export default createReducer(INITIAL_STATE,
  {
    [Types.NEW_SNACKBAR]: new_snackbar,
  })
