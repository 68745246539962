
import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  set_delivery: [
    "id", "legacy_order_id", "phone_number", "email_address", "address_zip_code", "address_street", "address_number",
    "address_complement", "address_neighborhood", "address_city", "address_state", "checked_at", "status",
    "freight", "expected_delivery_in", "separated_at", "delivered_at", "delivered_to", "remark", "driver_id", "checker_id",
    "images", "nfe", "city", "driver", "checker", "status_updated_at", "lasted_status", "sms_will_be_delivered_today_at",
    "sms_out_for_delivery_at", "sms_delivered_at", "problem_description", "duplicate_reason", "duplicate_remark"
  ],
  change_delivery: [
    "id", "legacy_order_id", "phone_number", "email_address", "address_zip_code", "address_street", "address_number",
    "address_complement", "address_neighborhood", "address_city", "address_state", "checked_at", "status",
    "freight", "expected_delivery_in", "separated_at", "delivered_at", "delivered_to", "remark", "driver_id", "checker_id",
    "images", "nfe", "city", "driver", "checker", "status_updated_at", "lasted_status", "sms_will_be_delivered_today_at",
    "sms_out_for_delivery_at", "sms_delivered_at", "problem_description", "duplicate_reason", "duplicate_remark"
  ],
  clear_delivery: []
})

const INITIAL_STATE = {
  id: "",
  legacy_order_id: "",
  phone_number: "",
  email_address: "",
  address_zip_code: "",
  address_street: "",
  address_number: "",
  address_complement: "",
  address_neighborhood: "",
  address_city: "",
  address_state: "",
  checked_at: "",
  status: "",
  freight: "",
  expected_delivery_in: "",
  separated_at: "",
  delivered_at: "",
  delivered_to: "",
  remark: "",
  driver_id: "",
  checker_id: "",
  images: [],
  nfe: "",
  city: {},
  driver: {},
  checker: {},
  status_updated_at: "",
  lasted_status: "",
  sms_will_be_delivered_today_at: "",
  sms_out_for_delivery_at: "",
  sms_delivered_at: "",
  problem_description: "",
  duplicate_reason: "",
  duplicate_remark: "",
}

const set_delivery = (state = INITIAL_STATE, action) => {
  return action.value
}

const change_delivery = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.value
  }
}

const clear_delivery = (state = INITIAL_STATE, action) => {
  return INITIAL_STATE
}

export default createReducer(INITIAL_STATE,
  {
    [Types.SET_DELIVERY]: set_delivery,
    [Types.CHANGE_DELIVERY]: change_delivery,
    [Types.CLEAR_DELIVERY]: clear_delivery,
  })
