import Entity from "./entity.model";

const SUPPORTED_ATTRS = [
  "id",
  "logableId",
  "logableType",
  "body",
  "bodyChanges",
  "userId",
  "user",
  "createdAt",
  "updatedAt",
];

export class LogModel extends Entity {
  constructor(attrs = {}) {
    super(attrs, SUPPORTED_ATTRS);
  }

  toJSON() {
    return {
      "id": this.id,
      "logable_id": this.logableId,
      "logable_type": this.logableType,
      "body": this.body,
      "body_changes": this.bodyChanges,
      "user_id": this.userId,
    };
  }
}
