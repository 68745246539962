/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClockFour } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

import {
  TextInputFieldComponent,
  DefaultButton,
  DateInputFieldComponent
} from "../../../shared/components";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  gridItem: {
    padding: theme.spacing(1),
  },
  container: {
    marginTop: theme.spacing(1),
    overflowX: 'auto',
  },
  gridItemDate: {
    display: 'flex',
  },
}));

export function SeparationsSearchComponent({ title, setFilters, INITIAL_FILTER, setLoading }) {
  const classes = useStyles();
  const [localFilters, setLocalFilters] = React.useState(INITIAL_FILTER)
  const history = useHistory();

  const clearFilters = () => {
    setLocalFilters(INITIAL_FILTER)
    setFilters(INITIAL_FILTER);
  }

  React.useEffect(() => {
    setLoading(true)
    let waitSec = setTimeout(() => {
      setFilters(localFilters);
      setLoading(false)
    }, 1000);
    return () => clearTimeout(waitSec);
  }, [localFilters]);

  const handleChange = name => event => {
    setLocalFilters({ ...localFilters, [name]: event.target.value });
  };

  const handleChangeDate = name => date => {
    setLocalFilters({ ...localFilters, [name]: date ? date._d : null });
  };

  return (
    <Paper className={classes.paper}>
      <Grid container >
        <Grid item xs={12} md={12} style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6" gutterBottom>{title}</Typography>

          <DefaultButton
            onClick={() => history.push('/separações/tempo-real')}
            startIcon={<FontAwesomeIcon icon={faClockFour} />}
          >
            Painel tempo real
          </DefaultButton>
        </Grid>
      </Grid>
      <Grid container >
        <Grid item xs={12} md={4}>
          <Grid item xs={12} md={12} className={classes.gridItem}>
            <TextInputFieldComponent
              id="legacyOrderId-input"
              label="Pedido"
              value={localFilters.legacyOrderId}
              onChange={handleChange("legacyOrderId")}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} className={classes.gridItemDate}>
          <Grid item xs={12} md={6} className={classes.gridItem}>
            <DateInputFieldComponent
              id="startedAt"
              label="Iniciado em"
              value={localFilters.startedAt}
              error={false}
              className={classes.textField}
              helperText={null}
              onChange={handleChangeDate("startedAt")}
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.gridItem}>
            <DateInputFieldComponent
              id="finishedAt"
              label="Finalizado em"
              value={localFilters.finishedAt}
              error={false}
              className={classes.textField}
              helperText={null}
              onChange={handleChangeDate("finishedAt")}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} className={classes.gridItemDate}>
          <Grid item xs={12} md={12} className={classes.gridItem}>
            <DateInputFieldComponent
              id="orderDate"
              label="Data do pedido"
              value={localFilters.orderDate}
              error={false}
              className={classes.textField}
              helperText={null}
              onChange={handleChangeDate("orderDate")}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} style={{ display: "flex", justifyContent: "flex-end" }}>
          <DefaultButton onClick={clearFilters}>Limpar Filtros</DefaultButton>
        </Grid>
      </Grid>
    </Paper >)
}
