import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Container, Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import * as Colors from "@material-ui/core/colors";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  alignCenter: {
    textAlign: 'center'
  },
  open: {
    backgroundColor: Colors.green[300],
  },
  inProgress: {
    backgroundColor: Colors.blue[200]
  },
  finshed: {
    backgroundColor: Colors.grey[200]
  }
}));

export function RealTimeCardListComponent({ separations, ...props }) {
  const classes = useStyles();
  const [cards, setCards] = React.useState({});

  const groupByKey = (array, key) => {
    if (!array || !Array.isArray(array) || array.length === 0) return array;
    if (!key || typeof key !== "string") return array;
    const result = [];
    array.forEach(item => {
      const itemKey = item[key];
      item['sector']['totalAmount'] = item['totalAmount'];
      const resultItem = result.find(resultItem => resultItem[key] === itemKey);
      if (resultItem) {
        if (!resultItem['sectors'])
          resultItem['sectors'] = [resultItem['sector']];
        resultItem['sectors'] = [...resultItem['sectors'], item['sector']];
        if ('totalAmount' && typeof resultItem['totalAmount'] === "number") {
          resultItem['totalAmount'] += item['totalAmount'];
        } else {
          resultItem['totalAmount'] = item['totalAmount'];
        }
      } else {
        item['sectors'] = [item['sector']];
        result.push(item);
      }
    });
    return result;
  }


  React.useEffect(() => {
    let notStated = separations.map(s => s.toState()).filter(separation => separation.startedAt === null && separation.finishedAt === null)
    let inProgress = separations.map(s => s.toState()).filter(separation => separation.startedAt !== null && separation.finishedAt === null)
    let finished = separations.map(s => s.toState()).filter(separation => separation.startedAt !== null && separation.finishedAt !== null)
    // agrupar por legacyOrderId somando o setor
    notStated = groupByKey(notStated, 'legacyOrderId', { concat: "sector", sum: "totalAmount" });
    inProgress = groupByKey(inProgress, 'legacyOrderId', { concat: "sector", sum: "totalAmount" });
    finished = groupByKey(finished, 'legacyOrderId', { concat: "sector", sum: "totalAmount" });
    setCards({
      notStarted: notStated,
      inProgress: inProgress,
      finished: finished,
    })
  }, [separations])

  const cardItem = (separation) => {
    const classCard = clsx(classes.root, {
      [classes.open]: separation.startedAt === null && separation.finishedAt === null,
      [classes.inProgress]: separation.startedAt !== null && separation.finishedAt === null,
      [classes.finshed]: separation.startedAt !== null && separation.finishedAt !== null,
    });
    return <Card className={classCard}>
      <CardContent style={{ padding: '6px' }}>
        <Grid container spacing={1} style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
          <Typography variant="h5" component="h2" style={{ whiteSpace: 'nowrap', paddingBottom: '0px' }}>
            <b>Pedido {separation.order.legacyOrderId}</b>
          </Typography>
        </Grid>
        <Typography variant="h6" component="p" style={{ whiteSpace: 'nowrap' }}>
          {separation.totalAmount} itens
        </Typography>
        <Typography variant="h6" component="p" style={{ paddingBottom: '0px' }}>
          Setores: {separation.sectors.map(s => <li>{s.name} ({s.totalAmount})</li>)}
        </Typography>
        <Typography variant="h6" component="p" style={{ whiteSpace: 'nowrap' }}>
          {separation.orderCreatedAtFormatted}
        </Typography>
      </CardContent>
    </Card>
  };

  return <Container>
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <Typography variant="h6" gutterBottom className={classes.alignCenter}>Disponíveis ({cards?.notStarted?.length})</Typography>
        {cards?.notStarted?.map((separation, index) => cardItem(separation))}
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography variant="h6" gutterBottom className={classes.alignCenter}>Em separação ({cards?.inProgress?.length})</Typography>
        {cards?.inProgress?.map((separation, index) => cardItem(separation))}
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography variant="h6" gutterBottom className={classes.alignCenter}>Finalizados Hoje ({cards?.finished?.length})</Typography>
        {cards?.finished?.map((separation, index) => cardItem(separation))}
      </Grid>
    </Grid>
  </Container>
}
