import { SeparationModel } from "../models";
import { AuthRepository } from "./auth.repository";
import { Repository } from "./repository";

export class SeparationsRepository extends Repository {
  constructor() {
    super();
    this.authRepository = new AuthRepository();
  }

  async getAll({ queryParams = "", page = 1, perPage = 10, orderBy = "created_at", orderDirection = "asc" }) {
    return this.api.get({ path: `separations?q=${queryParams}&page=${page}&per_page=${perPage}&order_by=${orderBy}&order_direction=${orderDirection}`, headers: this.headers() })
      .then(response => {
        return Promise.resolve({ data: SeparationModel.fromArray(response.data), total: response.headers['total'] });
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.getAll.bind(this),
        callbackOnError: () => { this.dispatch({ type: "CLEAR_SEPARATION" }) }
      }));
  }

  async getRealTime() {
    return this.api.get({ path: "separations_realtime", headers: this.headers() })
      .then(response => {
        return Promise.resolve({ data: SeparationModel.fromArray(response.data), total: response.headers['total'] });
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.getRealTime.bind(this),
      }));
  }

  async create(separation) {
    return this.api.post({ path: "separations", headers: this.headers(), body: { separation: this.omitKeys(separation, ["id"]) } })
      .then(response => {
        this.newSnackbarSuccess(["Separação criada com sucesso"]);
        return Promise.resolve(SeparationModel.fromJSON(response.data));
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.create.bind(this),
      }));
  }

  async update(separation) {
    return this.api.put({ path: `separations/${separation.id}`, headers: this.headers(), body: { separation: this.omitKeys(separation, ["id"]) } })
      .then(response => {
        this.newSnackbarSuccess(["Separação atualizada com sucesso"]);
        return Promise.resolve(SeparationModel.fromJSON(response.data));
      }
      ).catch(error => this.resolveErrorResponse({
        error,
        callback: this.update
      }));
  }

  async delete(id) {
    return this.api.delete({ path: `separations/${id}`, headers: this.headers() })
      .then(response => {
        this.newSnackbarSuccess([`Deletado com sucesso!`]);
        return Promise.resolve(response.data);
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.delete.bind(this),
      }));
  }
}
