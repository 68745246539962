import React from 'react';
import { createContext, useCallback, useContext, useState } from 'react';

const DrawerRightContext = createContext({
	isDrawerRightOpen: false,
	toggleDrawerRightOpen: () => {},
	drawerValue: {},
	setNewDrawerValue: () => {},
});

export const useDrawerRightContext = () => {
	return useContext(DrawerRightContext);
};

export const DrawerRightProvider = ({ children }) => {
	const [isDrawerRightOpen, setIsDrawerRightOpen] = useState(false);
	const [drawerValue, setDrawerValue] = useState();

	const toggleDrawerRightOpen = useCallback(() => {
		setIsDrawerRightOpen(oldDrawerRightOpen => !oldDrawerRightOpen);
	}, []);

	const setNewDrawerValue = (value) => {
		setIsDrawerRightOpen(true);
		setDrawerValue(value);
	};

	return (
		<DrawerRightContext.Provider value={{ isDrawerRightOpen, toggleDrawerRightOpen, drawerValue, setNewDrawerValue }}>
			{children}
		</DrawerRightContext.Provider>
	);
};
