import React from "react";
import { Table, TableRow, TableBody, TableCell, TableHead, Typography } from "@material-ui/core";
import { NumberPhoneInputFieldComponent } from "../Fields";
import { AddIconButton, DeleteIconButton, EditIconButton, HistoryIconButton } from "../Buttons";
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx";
import { green, red } from "@material-ui/core/colors";

const useStyles = makeStyles(theme => ({
  root: {
  },
  inputAdd: {
    color: green[900],
  },
  inputDelete: {
    textDecoration: 'line-through',
    color: red[900],
  }
}));

export function PhoneList(props) {
  const { phones } = props;
  const handleChangeModel = props.handleChange;
  const classes = useStyles();

  const handleChangePhones = (phones) => {
    handleChangeModel(phones);
  }

  const handleChange = (index, name) => event => {
    const newPhoneList = [...phones];
    newPhoneList[index][name] = event.target.value;
    handleChangePhones(newPhoneList);
  }

  const addPhone = () => {
    handleChangePhones([...(phones || []), { edit: true }]);
  }

  const editPhone = (index) => {
    const newPhoneList = [...phones];
    delete newPhoneList[index]._destroy;
    newPhoneList[index].edit = true;
    handleChangePhones(newPhoneList);
  }


  const rollbackPhone = (index) => {
    const newPhoneList = [...phones];
    delete newPhoneList[index]._destroy;
    handleChangePhones(newPhoneList);
  }

  const removePhone = (index) => {
    const newPhoneList = [...phones];
    delete newPhoneList[index].edit;
    newPhoneList[index].id ? newPhoneList[index]._destroy = true : newPhoneList.splice(index, 1);
    handleChangePhones(newPhoneList);
  }

  return (
    <Table className={classes.root}>
      <TableHead>
        <TableRow>
          <TableCell><Typography variant="body1" gutterBottom>Telefones</Typography></TableCell>
          <TableCell colSpan={2} align="right">
            <AddIconButton onClick={addPhone} />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {phones?.map((phone, index) => (
          <TableRow key={index}>
            <TableCell colSpan={2}>
              {phone.edit ? (
                <NumberPhoneInputFieldComponent
                  value={phone.number}
                  onChange={handleChange(index, 'number')}
                  disabled={phone._destroy}
                  error={phone._destroy}
                  errorMessage="Marcado para deleção"
                  classNameInput={clsx({ [classes.inputAdd]: !phone.id })}
                />) : (
                <Typography variant="body1" gutterBottom className={clsx({ [classes.inputDelete]: phone._destroy })}>{phone.numberFormatted}</Typography>
              )}
            </TableCell>
            <TableCell align="right">
              {!phone.edit && <EditIconButton onClick={() => editPhone(index)} />}
              {phone._destroy ?
                <HistoryIconButton onClick={() => rollbackPhone(index)} ></HistoryIconButton>
                :
                <DeleteIconButton onClick={() => removePhone(index)} ></DeleteIconButton>
              }
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table >

  );
}
