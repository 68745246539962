import Entity from "./entity.model";

const SUPPORTED_ATTRS = [
  "id",
  "legacyOrderId",
  "createdAt",
  "updatedAt",
];

export class OrderModel extends Entity {
  constructor(attrs = {}) {
    super(attrs, SUPPORTED_ATTRS);
  }

  get createdAtFormatted() {
    return this.createdAt ? new Date(`${this.createdAt}`).toLocaleString() : null;
  }

  toJSON() {
    return {
      id: this.id,
      legacy_order_id: this.legacyOrderId,
    };
  }

  static fromJSON(attrs) {
    return super.fromJSON({
      ...attrs,
    })
  }

}
