/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import {
  StoresListComponent,
  StoresSearchComponent,
  StoresDetailComponent
} from "./components";
import { AdminLayout } from "../../shared/layouts/admin.layout";
import { StoreModel } from "../../shared/models";

const INITIAL_FILTER = {
  name: "",
  document: "",
  orderBy: 'name',
  orderDirection: 'asc',
}

export function StoresPage() {
  const [filters, setFilters] = React.useState(INITIAL_FILTER);
  const [stores, setStores] = React.useState(StoreModel.fromArray([]));
  const [loading, setLoading] = React.useState(false);

  const update = (store) => {
    setStores(stores.map(s => s.id === store.id ? store : s))
  }

  return (
    <AdminLayout
      DrawerRightComponent={<StoresDetailComponent update={update} />}
      HeaderComponent={
        <StoresSearchComponent
          setFilters={setFilters}
          INITIAL_FILTER={INITIAL_FILTER}
          setLoading={setLoading} />
      }
      BodyComponent={<StoresListComponent
        filters={filters}
        setFilters={setFilters}
        stores={stores}
        setStores={setStores}
        loading={loading}
        setLoading={setLoading} />
      }
    />
  );

}

