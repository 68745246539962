import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, List, ListItem } from "@material-ui/core";

import {
  BackButton,
  SaveButton,
  TextInputFieldComponent
} from "../../../shared/components";
import { CompaniesRepository } from "../../../shared/repositories";
import { DropzoneFileComponent } from "../../../shared/components/Images";
import { CompanyModel } from "../../../shared/models";
import { useDrawerRightContext } from "../../../shared/contexts/drawer_right.context";

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '400px',
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '500px',
    }
  }
}));

export function CompaniesDetailComponent({ update }) {
  const classes = useStyles();
  const [company, setCompany] = useState({});
  const companyRepository = new CompaniesRepository();
  const { drawerValue, toggleDrawerRightOpen } = useDrawerRightContext();

  const saveCompany = _ => {
    const companyModel = new CompanyModel(company);
    return companyRepository.update(companyModel.toJSON()).then(data => {
      const companyModel = new CompanyModel(data);
      update(companyModel)
      setCompany(companyModel.toState())
    })
  }

  useEffect(() => {
    setCompany(drawerValue)
  }, [drawerValue])

  const handleChange = name => event => {
    setCompany({ ...company, [name]: event.target.value });
  };

  const goBack = () => {
    toggleDrawerRightOpen()
  }

  return (
    <Grid className={classes.root}>
      <List>
        <ListItem>
          <Typography variant="h6" gutterBottom>Dados da Empresa</Typography>
        </ListItem >
        <ListItem>
          <TextInputFieldComponent
            id="cid-select"
            label="CID"
            disabled
            value={company.cid}
          />
        </ListItem >
        <ListItem>
          <TextInputFieldComponent
            id="slug-select"
            label="Slug"
            disabled
            value={company.slug}
          />
        </ListItem >
        <ListItem>
          <TextInputFieldComponent
            id="name-select"
            label="Nome"
            value={company.name}
            onChange={handleChange('name')}
          />
        </ListItem >
        <ListItem>
          <TextInputFieldComponent
            id="friendlyName-select"
            label="Nome Amígavel"
            value={company.friendlyName}
            onChange={handleChange('friendlyName')}
            helperText="Esse nome será usado para envio de email e sms, ex: [nome amígavel] - seu pedido..."
          />
        </ListItem >
        <ListItem>
          <TextInputFieldComponent
            id="emailAddress-select"
            label="Email"
            value={company.emailAddress}
            onChange={handleChange('emailAddress')}
          />
        </ListItem >
        <ListItem>
          <TextInputFieldComponent
            id="document-select"
            label="Documento"
            value={company.document}
            onChange={handleChange('document')}
          />
        </ListItem >
        <ListItem>
          <TextInputFieldComponent
            id="maxDeliveryInDay-select"
            label="Max Entregas / Dia"
            value={company.maxDeliveryInDay}
            onChange={handleChange('maxDeliveryInDay')}
          />
        </ListItem >
        <ListItem>
          <TextInputFieldComponent
            id="maxWeightInDay-select"
            label="Max Peso / Dia"
            value={company.maxWeightInDay}
            onChange={handleChange('maxWeightInDay')}
          />
        </ListItem >
        <ListItem>
          <TextInputFieldComponent
            id="colors-select"
            label="Cor Principal"
            type='color'
            value={company.primaryColor}
            onChange={handleChange('colors')}
            InputLabelProps={{
              shrink: true
            }}
          />
          <TextInputFieldComponent
            id="colors-select"
            label="Cor Secundaria"
            type='color'
            value={company.secondaryColor}
            onChange={handleChange('colors')}
            InputLabelProps={{
              shrink: true
            }}
          />
        </ListItem >
        <ListItem>
          <DropzoneFileComponent
            parentId={company.id}
            showPreviewImage
            label='Ícone'
            name='icon'
            accept={{
              'image/*': ['.jpeg', '.jpg', '.png'],
            }}
            handleChange={handleChange('icon')}
            defaultUrl={company?.icon?.url}
          />
        </ListItem>
        <ListItem>
          <DropzoneFileComponent
            parentId={company.id}
            showPreviewImage
            label='Logo'
            name='logo'
            accept={{
              'image/*': ['.jpeg', '.jpg', '.png'],
            }}
            handleChange={handleChange('logo')}
            defaultUrl={company?.logo?.url}
          />
        </ListItem>
        <ListItem>
          <DropzoneFileComponent
            parentId={company.id}
            showPreviewImage
            label='Gif do logo'
            name='logo_gif'
            accept={{
              'image/*': ['.jpeg', '.jpg', '.png'],
            }}
            handleChange={handleChange('logoGif')}
            defaultUrl={company?.logoGif?.url}
          />
        </ListItem>
        <ListItem style={{ display: "flex", justifyContent: "space-between" }}>
          <BackButton onClick={goBack} />
          <SaveButton onClick={saveCompany} />
        </ListItem >
      </List >
    </Grid >
  );

}

