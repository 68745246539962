/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import {
  ClientsListComponent,
  ClientsSearchComponent,
  ClientsDetailComponent
} from "./components";
import { AdminLayout } from "../../shared/layouts/admin.layout";
import { ClientModel } from "../../shared/models";

const INITIAL_FILTER = {
  name: "",
  phone: "",
  status: "all",
  orderBy: 'name',
  orderDirection: 'asc',
}

export function ClientsPage() {
  const [filters, setFilters] = React.useState(INITIAL_FILTER);
  const [clients, setClients] = React.useState(ClientModel.fromArray([]));
  const [loading, setLoading] = React.useState(false);

  const update = (client) => {
    setClients(clients.map(s => s.id === client.id ? client : s))
  }

  return (
    <AdminLayout
      DrawerRightComponent={<ClientsDetailComponent update={update} />}
      HeaderComponent={
        <ClientsSearchComponent
          setFilters={setFilters}
          INITIAL_FILTER={INITIAL_FILTER}
          setLoading={setLoading} />
      }
      BodyComponent={<ClientsListComponent
        filters={filters}
        setFilters={setFilters}
        clients={clients}
        setClients={setClients}
        loading={loading}
        setLoading={setLoading} />
      }
    />
  );

}

