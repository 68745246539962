import React from 'react'
import { TableCell, TableFooter, TablePagination, TableRow } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '../Buttons';
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

export function TablePaginationComponent({ colSpan = 1, loading = false, count = 0, rowsPerPage = 25, page = 0, onPageChange = () => { }, onRowsPerPageChange = () => { } }) {

  function handleNextButtonClick(page) {
    onPageChange(page + 1);
  }

  if (count <= 0) {
    return null;
  }


  return (
    <TableFooter>
      <TableRow>
        <TableCell colSpan={colSpan}>
          <TablePagination
            ActionsComponent={({ count, onPageChange, page }) => (
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <IconButton
                  onClick={() => handleNextButtonClick(0)}
                  disabled={page === 0}
                  aria-label="first page"
                >
                  <FontAwesomeIcon icon={faAngleDoubleLeft} />
                </IconButton>
                <IconButton
                  onClick={() => handleNextButtonClick(page - 1)}
                  disabled={page === 0}
                  aria-label="previous page">
                  <FontAwesomeIcon icon={faAngleLeft} />

                </IconButton>
                <IconButton
                  onClick={() => handleNextButtonClick(page + 1)}
                  disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                  aria-label="next page"
                >
                  <FontAwesomeIcon icon={faAngleRight} />
                </IconButton>
                <IconButton
                  onClick={() => {handleNextButtonClick(Math.ceil(count / rowsPerPage) - 1)}}
                  disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                  aria-label="last page"
                >
                  <FontAwesomeIcon icon={faAngleDoubleRight} />
                </IconButton>
              </div>
            )}
            component="div"
            count={count}
            page={page - 1}
            onPageChange={handleNextButtonClick}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={event => onRowsPerPageChange(event.target.value)}
            labelRowsPerPage='Itens por página'
            rowsPerPageOptions={[10, 25, 50, 100]}
            labelDisplayedRows={({ from, to, count }) => {
              const totalPages = Math.ceil(count / rowsPerPage);
              return `Total de itens: ${count} (pagina ${totalPages === 0 ? 0 : page} de ${totalPages})`;
            }}
            SelectProps={{
              value: rowsPerPage,
              onChange: event => onRowsPerPageChange(event.target.value + 1)
            }}
          />
        </TableCell>
      </TableRow>
    </TableFooter>
  );
}
