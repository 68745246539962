
import { createActions, createReducer } from 'reduxsauce'
import { Constants } from '../../helpers'

export const { Types, Creators } = createActions({
  set_users: [],
  set_drivers: [],
  set_checkers: [],
  set_chief_drivers: [],
  create_users_item: [],
  update_users_item: [],
  clear_users: [],
  delete_user: ['value'],
})

const INITIAL_STATE = []

const set_users = (state = INITIAL_STATE, action) => {
  return action.value
}

const set_drivers = (state = INITIAL_STATE, action) => {
  return state.length > 0 ?
    [...state.filter(user => user.role !== Constants.User.Role.Driver), ...action.value]
    : action.value
}

const set_checkers = (state = INITIAL_STATE, action) => {
  return state.length > 0 ?
    [...state.filter(user => user.role !== Constants.User.Role.Checker), ...action.value]
    : action.value
}

const set_chief_drivers = (state = INITIAL_STATE, action) => {
  return state.length > 0 ?
    [...state.filter(user => user.role !== Constants.User.Role.ChiefDriver), ...action.value]
    : action.value
}

const update_users_item = (state = INITIAL_STATE, action) => {
  return state.map(user => {
    if (user.id === action.value.id) {
      return action.value
    }
    return user
  })
}

const create_users_item = (state = INITIAL_STATE, action) => {
  return [action.value, ...state]
}

const clear_users = (state = INITIAL_STATE, action) => {
  return INITIAL_STATE
}

const delete_user = (state = INITIAL_STATE, action) => {
  return state.filter(user => user.id !== action.value)
}

export default createReducer(INITIAL_STATE,
  {
    [Types.SET_USERS]: set_users,
    [Types.SET_DRIVERS]: set_drivers,
    [Types.SET_CHECKERS]: set_checkers,
    [Types.SET_CHIEF_DRIVERS]: set_chief_drivers,
    [Types.CREATE_USERS_ITEM]: create_users_item,
    [Types.UPDATE_USERS_ITEM]: update_users_item,
    [Types.CLEAR_USERS]: clear_users,
    [Types.DELETE_USER]: delete_user
  })
