/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import {
  RegionsSearchComponent,
  RegionsDetailComponent,
  RegionsListComponent
} from "./components";
import { AdminLayout } from "../../shared/layouts/admin.layout";
import { RegionModel } from "../../shared/models";

const INITIAL_FILTER = {
  name: "",
  document: "",
};

export function RegionsPage() {
  const [filters, setFilters] = React.useState(INITIAL_FILTER);
  const [regions, setRegions] = React.useState(RegionModel.fromArray([]));
  const [loading, setLoading] = React.useState(false);

  const update = (region) => {
    setRegions(regions.map(r => r.id === region.id ? region : r))
  }

  return (
    <AdminLayout
      DrawerRightComponent={<RegionsDetailComponent update={update} />}
      HeaderComponent={
        <RegionsSearchComponent
          setFilters={setFilters}
          INITIAL_FILTER={INITIAL_FILTER}
          setLoading={setLoading} />
      }
      BodyComponent={<RegionsListComponent
        filters={filters}
        setFilters={setFilters}
        regions={regions}
        setRegions={setRegions}
        loading={loading}
        setLoading={setLoading} />
      }
    />
  );
}

