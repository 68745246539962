
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from "@material-ui/core";
import { omitKeys } from "../../helpers";

const useStyles = makeStyles(theme => ({
  textField: {
    paddingRight: theme.spacing(1),
  },
  textFieldLabel: {
    color: "black"
  },
  textColorError: {
    color: "red"
  }
}));

export function TextInputFieldComponent(props) {
  const classes = useStyles();
  const { children, value = "", classNameInput, error, errorMessage, helperText, InputLabelProps, InputProps } = props;

  return (
    <TextField
      variant="outlined"
      fullWidth
      size="small"
      InputLabelProps={{ shrink: value, classes: { root: classes.textFieldLabel }, ...InputLabelProps }}
      InputProps={{ classes: { input: classNameInput }, ...InputProps }}
      className={classes.textField}
      {...omitKeys(props, ['InputLabelProps', 'InputProps'])}
      value={value ? value : ""}
      error={error}
      helperText={(error && errorMessage) || helperText}
    >
      {children}
    </TextField>
  )
}
