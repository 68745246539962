import { PhoneNumberHelper } from "../helpers";
import Entity from "./entity.model";

const SUPPORTED_ATTRS = [
  "id",
  "name",
  "document",
  "phoneNumber",
  "emailAddress",
  "createdAt",
  "updatedAt",
];

export class StoreModel extends Entity {
  constructor(attrs = {}) {
    super(attrs, SUPPORTED_ATTRS);
  }

  get phoneNumberFormatted() {
    return new PhoneNumberHelper(this.phoneNumber).formatted;
  }

  get phoneNumberWithoutDDI() {
    return new PhoneNumberHelper(this.phoneNumber).phoneNumberWithoutDDI;
  }

  toJSON() {
    return {
      id: this.id,
      name: this.name,
      document: this.document,
      phone_number: this.phoneNumber,
      email_address: this.emailAddress,
    };
  }

  toState() {
    return super.toState({
      phoneNumber: this.phoneNumberWithoutDDI,
    });
  }
}
