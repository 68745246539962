import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, List, ListItem, Box } from "@material-ui/core";

import {
  TextInputFieldComponent,
  BackButton,
  SaveButton
} from "../../../shared/components";
import { SectorsRepository } from "../../../shared/repositories";
import { SectorModel } from "../../../shared/models";
import { useDrawerRightContext } from "../../../shared/contexts/drawer_right.context";

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '400px',
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '500px',
    }
  },
  dateField: {
    paddingRight: theme.spacing(3),
  },
}));

export function SectorsDetailComponent({ update }) {
  const classes = useStyles();
  const [sectorDetails, setSectorDetails] = React.useState({});
  const sectorRepository = new SectorsRepository();
  const { drawerValue, toggleDrawerRightOpen } = useDrawerRightContext();


  const saveSector = _ => {
    const sectorToSave = new SectorModel(sectorDetails);
    return sectorRepository[sectorToSave.id ? 'update' : 'create'](sectorToSave.toJSON())
      .then(sector => {
        update(sector)
        setSectorDetails(sector.toState())
      });
  }

  React.useEffect(() => {
    setSectorDetails(drawerValue)
  }, [drawerValue])

  const handleChange = name => event => {
    setSectorDetails({ ...sectorDetails, [name]: event.target.value });
  };

  const goBack = () => {
    toggleDrawerRightOpen()
  }

  return (
    <Box className={classes.root} onKeyDown={e => { e.stopPropagation(); if (e.ctrlKey && e.key === 'Enter') { saveSector(); } }}>
      <List>
        <ListItem>
          <Typography variant="h6" gutterBottom>Dados do Setor</Typography>
        </ListItem>
        <ListItem>
          <TextInputFieldComponent
            id="legacySectorId-select"
            label="Código"
            value={sectorDetails.legacySectorId}
            onChange={handleChange('legacySectorId')}
          />
        </ListItem>
        <ListItem>
          <TextInputFieldComponent
            id="name-select"
            label="Nome"
            value={sectorDetails.name}
            onChange={handleChange('name')}
          />
        </ListItem>
        <ListItem>
          <TextInputFieldComponent
            id="description-select"
            label="Descrição"
            value={sectorDetails.description}
            onChange={handleChange('description')}
          />
        </ListItem>
        <ListItem style={{ display: "flex", justifyContent: "space-between" }}>
          <BackButton onClick={goBack} />
          <SaveButton onClick={saveSector} />
        </ListItem>
      </List >
    </Box >
  );

}

