import { RegionModel } from "../models";
import { AuthRepository } from "./auth.repository";
import { Repository } from "./repository";

export class RegionsRepository extends Repository {
  constructor() {
    super();
    this.authRepository = new AuthRepository();
  }

  async getAll({ queryParams = '', page = 1, perPage = 10, orderBy = "id", orderDirection = "asc" }) {
    return this.api.get({ path: `regions?${queryParams}&page=${page}&per_page=${perPage}&order_by=${orderBy}&order_direction=${orderDirection}`, headers: this.headers() })
      .then(response => {
        return Promise.resolve({ data: response.data, total: response.headers['total'] });
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.getAll.bind(this),
        callbackOnError: () => { this.dispatch({ type: "CLEAR_REGION" }) }
      }));
  }

  async create(region) {
    return this.api.post({ path: "regions", headers: this.headers(), body: { region: this.omitKeys(region, ["id"]) } })
      .then(response => {
        this.newSnackbarSuccess(["Região criada com sucesso"]);
        return Promise.resolve(RegionModel.fromJson(response.data));
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.create.bind(this),
      }));
  }

  async update(region) {
    return this.api.put({ path: `regions/${region.id}`, headers: this.headers(), body: { region: this.omitKeys(region, ["id"]) } })
      .then(response => {
        this.newSnackbarSuccess(["Região atualizada com sucesso"]);
        return Promise.resolve(RegionModel.fromJson(response.data));
      }
      ).catch(error => this.resolveErrorResponse({
        error,
        callback: this.update
      }));
  }

  async delete(id) {
    return this.api.delete({ path: `regions/${id}`, headers: this.headers() })
      .then(response => {
        this.newSnackbarSuccess([`Deletado com sucesso!`]);
        return Promise.resolve(response.data);
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.delete.bind(this),
      }));
  }
}
