import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { red } from "@material-ui/core/colors";
import { SaveButton } from "./saveButton.component";

const useStyles = makeStyles(theme => ({
  button: {
    borderRadius: "20px",
    backgroundColor: '#AD0B00',
    '&:hover': {
      backgroundColor: red[400],
    },
  },
}));
export function CreatePdfButton(props) {
  const classes = useStyles();
  const { onClick, disabled } = props;

  return (
    <SaveButton
      className={classes.button}
      onClick={onClick}
      disabled={disabled}
      startIcon={<FontAwesomeIcon icon={faFilePdf} />}
      {...props}
    >
      {props.children || "Baixar Pdf"}
    </SaveButton>
  );
}
