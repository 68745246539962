import React, { useState } from "react";

import {
  DeliveryLogsComponent,
  DeliveriesSearchComponent,
  DeliveriesDetailComponent,
  DeliveriesListComponent
} from "./components";
import { RegionsRepository, StoresRepository, UsersRepository } from "../../shared/repositories";
import { AdminLayout } from "../../shared/layouts/admin.layout";
import { useDrawerRightContext } from "../../shared/contexts/drawer_right.context";
import { removeSpecialChars, isValidDate } from "../../shared/helpers";

const INITIAL_FILTER = {
  clientName: "",
  driverName: "",
  legacyOrderId: "",
  expectedDeliveryInStart: null,
  expectedDeliveryInEnd: null,
  expectedDeliveryInFuture: false,
  coating: false,
  deliveredAtStart: null,
  deliveredAtEnd: null,
  status: "all",
  clientId: "all",
  parentId: "all",
  driverId: "all",
  storeId: "all",
  sellerId: "all",
  regionId: "all",
  freightType: "all",
  orderBy: "",
  order: ""
};

export function DeliveriesPage() {
  const [filters, setFilters] = useState(INITIAL_FILTER);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [stores, setStores] = useState([]);
  const [regions, setRegions] = useState([]);
  const [deliveries, setDeliveries] = useState([]);
  const usersRepository = new UsersRepository();
  const storesRepository = new StoresRepository();
  const regionsRepository = new RegionsRepository();
  const { drawerValue } = useDrawerRightContext();

  const update = (delivery) => {
    const index = deliveries.findIndex(item => item.id === delivery.id)
    if (index > -1) {
      deliveries[index] = delivery
      setDeliveries([...deliveries])
    } else {
      setDeliveries([delivery, ...deliveries])
    }
  }

  React.useEffect(() => {
    usersRepository.getAll({ perPage: 1000 }).then(result => {
      setUsers(result.data);
    })
    storesRepository.getAll({ perPage: 1000 }).then(result => {
      setStores(result.data);
    })
    regionsRepository.getAll({ perPage: 1000 }).then(result => {
      setRegions(result.data);
    })
  }, []);

  const resolveFilters = () => {
    let query = [];

    // legacyOrderId
    if (filters.legacyOrderId) {
      query.push(`legacy_order_id=${filters.legacyOrderId.trim()}`);
    }

    // expectedDeliveryIn
    if (filters.expectedDeliveryInFuture) {
      query.push("expected_delivery_in_future=true");
    } else {
      if (isValidDate(filters.expectedDeliveryInStart)) {
        query.push(`expected_delivery_in=${filters.expectedDeliveryInStart.toLocaleDateString("pt-BR")},${filters.expectedDeliveryInEnd ? filters.expectedDeliveryInEnd.toLocaleDateString("pt-BR") : ""}`);
      }

      // deliveredAt
      if (isValidDate(filters.deliveredAtStart)) {
        query.push(`delivered_at=${filters.deliveredAtStart.toLocaleDateString("pt-BR")}${isValidDate(filters.deliveredAtEnd) ? `,${filters.deliveredAtEnd.toLocaleDateString("pt-BR")}` : ""}`);
      }
    }

    // Coating
    if (filters.coating) {
      query.push("coating=true");
    }

    // Tipo de Frete
    if (filters.freightType !== "all") {
      query.push(`freight_type=${filters.freightType}`);
    }

    // status
    if (filters.status !== "all") {
      query.push(`status=${filters.status}`);
    }

    // client
    if (filters.clientName && filters.clientName.length > 0) {
      query.push(`client_name=${removeSpecialChars(filters.clientName)}`);
    }

    // driverId
    if (filters.driverId !== "all") {
      query.push(`driver_id=${filters.driverId}`);
    } else {
      // parentId
      if (filters.parentId !== "all") {
        query.push(`parent_id=${filters.parentId}`);
      }
    }

    // storeId
    if (filters.storeId !== "all") {
      query.push(`store_id=${filters.storeId}`);
    }

    // sellerId
    if (filters.sellerId !== "all") {
      query.push(`seller_id=${filters.sellerId}`);
    }

    // regionId
    if (filters.regionId !== "all") {
      query.push(`region_id=${filters.regionId}`);
    }

    return query.join("|");
  }

  return (
    <AdminLayout
      DrawerRightComponent={
        typeof drawerValue === "string" ?
          <DeliveriesDetailComponent
            update={update}
            loading={loading}
            users={users}
            stores={stores}
            regions={regions}
          /> :
          (typeof drawerValue === "object") ?
            <DeliveryLogsComponent
              loading={loading}
            /> : <></>}
      HeaderComponent={
        <DeliveriesSearchComponent
          setFilters={setFilters}
          INITIAL_FILTER={INITIAL_FILTER}
          setUsers={setUsers}
          users={users}
          stores={stores}
          regions={regions}
          setLoading={setLoading}
          resolveFilters={resolveFilters}
        />
      }
      BodyComponent={<DeliveriesListComponent
        filters={filters}
        setFilters={setFilters}
        deliveries={deliveries}
        setDeliveries={setDeliveries}
        loading={loading}
        setLoading={setLoading}
        resolveFilters={resolveFilters}
      />
      }
    />
  );

}

