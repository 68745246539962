import React from "react";

import { AdminLayout } from "../../shared/layouts/admin.layout";
import { SeparationModel } from "../../shared/models";

import {
  SeparationsSearchComponent,
  SeparationsDetailComponent,
  SeparationsListComponent
} from "./components";

const INITIAL_FILTER = {
  legacyOrderId: "",
  startedAt: null,
  finishedAt: null,
  orderDate: null,
  orderBy: 'started_at',
  orderDirection: 'desc'
}

export function SeparationsPage() {
  const [filters, setFilters] = React.useState(INITIAL_FILTER);
  const [separations, setSeparations] = React.useState(SeparationModel.fromArray([]));
  const [loading, setLoading] = React.useState(false);

  const update = (separation) => {
    setSeparations(separations.map(s => s.id === separation.id ? separation : s))
  }

  return (
    <AdminLayout
      title="Separações"
      DrawerRightComponent={
        <SeparationsDetailComponent
          update={update} />
      }
      HeaderComponent={
        <SeparationsSearchComponent
          title="Separações"
          setFilters={setFilters}
          INITIAL_FILTER={INITIAL_FILTER}
          setLoading={setLoading} />
      }
      BodyComponent={
        <SeparationsListComponent
          filters={filters}
          setFilters={setFilters}
          separations={separations}
          setSeparations={setSeparations}
          loading={loading}
          setLoading={setLoading} />
      }
    />
  );

}

