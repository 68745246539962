import { combineReducers } from "redux";
import auth from './auth';
import calendars from './calendars';
import client from './client';
import clients from './clients';
import company from './company';
import companies from './companies';
import dashboard from './dashboard';
import deliveries from './deliveries';
import delivery from './delivery';
import loading from './loading';
import logs from './logs';
import me from './me';
import snackbar from './snackbar';
import region from './region';
import regions from './regions';
import restrictedDate from './restrictedDate';
import restrictedDates from './restrictedDates';
import stores from './stores';
import store from './store';
import user from './user';
import users from './users';
import vehicle from './vehicle';
import vehicles from './vehicles';
import { connectRouter } from 'connected-react-router';

export default (history) => combineReducers({
  router: connectRouter(history),
  auth,
  calendars,
  client,
  clients,
  company,
  companies,
  dashboard,
  deliveries,
  delivery,
  loading,
  logs,
  me,
  snackbar,
  region,
  regions,
  restrictedDate,
  restrictedDates,
  stores,
  store,
  user,
  users,
  vehicle,
  vehicles,
})
