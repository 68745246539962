
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Switch, Tooltip, Typography } from '@material-ui/core';
import clsx from "clsx";

import { DateInputFieldComponent } from './';

const useStyles = makeStyles(theme => ({
  gridItem: {
    padding: theme.spacing(1),
  },
  inline: {
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      display: "inline-flex",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "-webkit-fill-available",
    }
  },
  dateInput: {
    border: "1px solid #ced4da",
    borderRadius: "4px",
    margin: theme.spacing(1),
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  dateInputLabel: {
    position: "absolute",
    pointerEvents: "none",
    transform: "translate(14px, -6px) scale(0.75)",
    transformOrigin: "top left",
    transition: "200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
    color: "black",
    fontSize: "16px",
    lineHeight: "1",
    left: "16px",
    backgroundColor: "white",
    zIndex: "1",
    padding: "0 4px 0 4px",
  },
  errorText: {
    color: "red",
    fontSize: "12px",
    marginLeft: "4px",
  },
  switch: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    justifySelf: "center",
    [theme.breakpoints.down('sm')]: {
      justifyContent: "flex-end",
    }
  },
}));

export const BetweenDateInputFieldComponent = ({
  id, label, startDate, endDate, onChangeStart,
  onChangeEnd, error, errorText, disabledDate, onDisabledChange, disabledTooltipText,
  onKeyPress = () => { }
}) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.dateInput}>
      <Grid item xs={12}>
        <Typography variant="body1" gutterBottom className={classes.dateInputLabel}>{label}</Typography>
      </Grid>
      <Grid item xs={12} className={classes.inline}>
        {onDisabledChange !== undefined &&
          <Tooltip title={disabledTooltipText}>
            <Grid item xs={12} sm={12} md={1} className={clsx(classes.gridItem, classes.switch)}>
              <Switch
                className={classes.switch}
                color="primary"
                size="small"
                checked={disabledDate}
                onChange={() => onDisabledChange({ target: { value: !disabledDate } })}
              />
            </Grid>
          </Tooltip>
        }
        <Grid item xs={12} md={6} className={classes.gridItem}>
          <DateInputFieldComponent
            id={`${id}-start`}
            label="Início"
            value={startDate}
            onChange={onChangeStart}
            disabled={disabledDate}
            onKeyPress={onKeyPress}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.gridItem}>
          <DateInputFieldComponent
            id={`${id}-end`}
            label="Final"
            value={endDate}
            onChange={onChangeEnd}
            disabled={disabledDate}
            onKeyPress={onKeyPress}
          />
        </Grid>
      </Grid>
      {error && <Typography variant="body1" gutterBottom color="error" className={classes.errorText}>{errorText}</Typography>}
    </Grid>
  )
}
