import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import Clear from "@material-ui/icons/Clear";
import { IconButton } from "./";

const useStyles = makeStyles(theme => ({
  button: {
    color: red[500],
    '&:hover': {
      backgroundColor: red[700],
      color: "white",
    },
  },
}));

export function ClearIconButton(props) {
  const classes = useStyles();
  return (
    <IconButton {...props} className={classes.button}><Clear /></IconButton>
  );
}
