import { routerMiddleware } from 'connected-react-router';
import { createStore, applyMiddleware } from 'redux';
import createRootReducer from './ducks';
import { HistoryRoutes } from '../../Routes';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'track',
  storage,
  blacklist: ['snackbar', 'loading'],
}
const persistedReducer = persistReducer(persistConfig, createRootReducer(HistoryRoutes))

const middleware = routerMiddleware(HistoryRoutes)

const store = createStore(
  persistedReducer,
  applyMiddleware(middleware)
)

const persistor = persistStore(store)

export { store, persistor };
