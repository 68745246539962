
export const isObject = (obj) => {
  return obj && typeof obj === "object";
}

export const omitKeys = (obj, keys) => {
  const newObj = {};
  Object.keys(obj).forEach(key => {
    if (!keys.includes(key)) {
      newObj[key] = obj[key];
    }
  });
  return newObj;
}

export const removeKeys = (obj, keys) => {
  const newObj = { ...obj };
  keys.forEach(key => delete newObj[key]);
  return newObj;
}

export const isEquals = (obj1, obj2) => {
  if (!isObject(obj1) || !isObject(obj2)) return false;
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) return false;
  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) return false;
  }
  return true;
}

export const getDiff = (obj1, obj2) => {
  if (!isObject(obj1) || !isObject(obj2)) return {};
  const diff = {};
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  const keys = [...keys1, ...keys2];

  keys.forEach(key => {
    if (obj1[key] !== obj2[key]) {
      diff[key] = { from: obj1[key], to: obj2[key] };
    }
  });
  return diff;
}
