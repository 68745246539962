import { DeliveryModel, LogModel } from "../models";
import { Repository } from "./repository";

export class DeliveriesRepository extends Repository {

  async getAll({ queryParams = "", page = 1, perPage = 10, orderBy = "created_at", orderDirection = "asc", type = "json", title = "Relatório de entregas" } = {}) {
    let options = { path: `deliveries.${type}?q=${queryParams}&page=${page}&per_page=${perPage}&order_by=${orderBy}&order_direction=${orderDirection}&title=${title}`, headers: this.headers() }
    if (type !== "json")
      options["responseType"] = "blob"
    return this.api.get(options)
      .then(response => {
        if (type !== "json")
          return this.resolveResponseBlob({ response, type, fileName: `${title}_${new Date().getTime()}` });
        else {
          return Promise.resolve({ data: DeliveryModel.fromArray(response.data), total: response.headers['total'] });
        }
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.getAll.bind(this),
        callbackOnError: () => { }
      }));
  }

  async get(id) {
    return this.api.get({ path: `deliveries/${id}`, headers: this.headers() })
      .then(response => {
        return Promise.resolve(DeliveryModel.fromJSON(response.data));
      })
      .catch(error =>
        this.resolveErrorResponse({
          error,
          callback: this.get.bind(this),
          callbackOnError: () => { }
        }));
  }

  async getLogs(delivery, type = "json", page = 1, perPage = 25) {
    let options = { path: `deliveries/${delivery.id}/logs.${type}?page=${page}&per_page=${perPage}`, headers: this.headers() }
    if (type !== "json")
      options["responseType"] = "blob"
    return this.api.get(options)
      .then(response => {
        if (type !== "json")
          return this.resolveResponseBlob({ response, type, fileName: `logs_${delivery.legacyOrderId}_${new Date().getTime()}` });
        else {
          return Promise.resolve({ data: LogModel.fromArray(response.data), total: response.headers['total'] });
        }
      })
      .catch(error =>
        this[type !== "json" ? "resolveErrorResponseBlob" : "resolveErrorResponse"]({
          error,
          callback: this.get.bind(this),
          callbackOnError: () => { }
        }));
  }

  async toBeDeliveredToday({ page = 1, perPage = 25, orderBy = "created_at", orderDirection = "asc" }) {
    return this.api.get({ path: `deliveries/to_be_delivered_today?page=${page}&per_page=${perPage}&order_by=${orderBy}&order_direction=${orderDirection}`, headers: this.headers() })
      .then(response => {
        return Promise.resolve({ data: DeliveryModel.fromArray(response.data), total: response.headers['total'] });
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.toBeDeliveredToday.bind(this),
        callbackOnError: () => { }
      }));
  }

  async sendSmsWillBeDeliveredToday() {
    return this.api.post({ path: "deliveries/to_be_delivered_today/send_notifications", headers: this.headers() })
      .then(response => {
        this.newSnackbarSuccess(["Sms enviados!"]);
        return Promise.resolve(DeliveryModel.fromJSON(response.data));
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.sendSmsWillBeDeliveredToday.bind(this),
      }));
  }

  async sendSmsWillBeDeliveredTodayOne(id) {
    return this.api.post({ path: `deliveries/${id}/send_notification`, headers: this.headers() })
      .then(response => {
        this.newSnackbarSuccess(["Sms enviados!"]);
        return Promise.resolve(DeliveryModel.fromJSON(response.data));
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.sendSmsWillBeDeliveredToday.bind(this),
      }));
  }

  async getQueryDownload({ query, type = "xlsx", title = "Relatório de entregas", fileName = `entregas_${new Date().getTime()}` }) {
    return this.api.get({ path: `deliveries.${type}?q=${query}&title=${title}`, headers: this.headers(), responseType: "blob" })
      .then(response => {
        this.resolveResponseBlob({ response, type, fileName });
        return Promise.resolve('');
      })
      .catch(error => this.resolveErrorResponseBlob({
        error,
        callback: this.getQuery.bind(this),
      }));
  }

  async duplicateDelivery(delivery, reasons) {
    return this.api.post({ path: `deliveries/${delivery.id}/duplicate`, body: { delivery: { ...reasons } }, headers: this.headers() })
      .then(response => {
        this.newSnackbarSuccess(["Entrega duplicada!"]);
        return Promise.resolve(DeliveryModel.fromJSON(response.data));
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.update.bind(this)
      }));
  }

  async addImage(id, formData) {
    return this.api.post({ path: `deliveries/${id}/images`, headers: this.headers(), body: formData })
      .then(response => {
        this.newSnackbarSuccess(["Imagem adicionada!"]);
        return Promise.resolve(response.data);
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.addImage.bind(this),
      }));
  }

  async deleteImage(id, image) {
    return this.api.delete({ path: `deliveries/${id}/images/${image}`, headers: this.headers() })
      .then(response => {
        this.newSnackbarSuccess(["Imagem deletada!"]);
        return Promise.resolve(response.data);
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.deleteImage.bind(this),
      }));
  }

  async deleteAllImages(id) {
    return this.api.delete({ path: `deliveries/${id}/images`, headers: this.headers() })
      .then(response => {
        this.newSnackbarSuccess(["Imagens deletadas!"]);
        return Promise.resolve(response.data);
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.deleteAllImages.bind(this),
      }));
  }

  async downloadImages(id) {
    return this.api.get({ path: `deliveries/${id}/download_images`, headers: this.headers(), responseType: "blob" })
      .then(response => {
        this.resolveResponseBlob({ response, type: "zip", fileName: `imagens_${id}` });
        this.newSnackbarSuccess(["Imagens baixadas!"]);
        return Promise.resolve('')
      }).catch(error => this.resolveErrorResponse({
        error,
        callback: this.downloadImages.bind(this),
      }));
  }

  async update(delivery) {
    return this.api.put({ path: `deliveries/${delivery.id}`, headers: this.headers(), body: this.omitKeys(delivery, ["id"]) })
      .then(response => {
        this.newSnackbarSuccess(["Entrega atualizada!"]);
        return Promise.resolve(DeliveryModel.fromJSON(response.data));
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.update.bind(this)
      }));
  }

  async delete(id) {
    return this.api.delete({ path: `deliveries/${id}`, headers: this.headers() })
      .then(response => {
        this.newSnackbarSuccess(["Entrega deletada!"]);
        return Promise.resolve(response.data);
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.delete.bind(this)
      }));
  }
}
