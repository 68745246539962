import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Chip, Fab, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { IconButton } from './';
import { Constants } from '../../../helpers';
import { CompaniesRepository } from '../../../repositories';

const useStyles = makeStyles((theme) => ({
	fabIcon: {
		position: 'fixed',
		bottom: theme.spacing(2),
		right: theme.spacing(2),
	},
}));

export const CompanySelectorIconButton = () => {
	const classes = useStyles()
	const history = useHistory()
	const { auth } = useSelector(state => state)
	const dispatch = useDispatch()
	const companyRepository = new CompaniesRepository()
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleCompany = (cid) => {
		const company = auth.companies.find(comp => comp.cid === cid)
		companyRepository.get(company.id)
			.then(result => {
				dispatch({ type: 'AUTH_SIGN_IN', value: { ...auth, cid: cid, companies: auth.companies.map(comp => comp.id === result.id ? result : comp) } })
			})
			.finally(_ => {
				history.go(0)
			})
	};

	const getLogo = () => {
		const company = auth.companies.find(c => c.cid === auth.cid)
		if (!company) return '/assets/img/logo.png';
		const logo = company.logo
		if (logo && logo.url)
			if (process.env.NODE_ENV === "development") {
				return `${Constants.development.apiUrl}${logo.url}`;
			} else {
				return logo.url;
			}
		return '/assets/img/logo.png'
	}

	return [Constants.User.Role.System, Constants.User.Role.Owner].includes(auth.role) ? <>
		<IconButton className={classes.fabIcon} onClick={handleClick}>
			<Fab>
				<Avatar alt="company-button-logo" src={getLogo()} imgProps={{ style: { height: "auto" } }} />
			</Fab>
		</IconButton>
		<Menu
			id="simple-menu"
			anchorEl={anchorEl}
			keepMounted
			open={Boolean(anchorEl)}
			onClose={handleClose}
		>
			{auth.companies.map(company => <MenuItem
				key={company.cid}
				disabled={auth.cid === company.cid}
				onClick={e => handleCompany(company.cid)}
			>
				<Chip avatar={<Avatar alt={company.cid} src={getLogo(company)} />} label={company.name} />
			</MenuItem>)}
		</Menu>
	</> : <></>;
};
