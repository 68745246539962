import React from 'react';
import ReactInputMask from 'react-input-mask';
import { TextInputFieldComponent } from '../';

export const NumberZipCodeInputFieldComponent = (props) => {
  const { value, inputProps = {} } = props;

  return (
    <ReactInputMask
      mask="99999-999"
      maskChar=""
      {...props}
      value={value || ""}
    >
      {(ip) => <TextInputFieldComponent
        id="addressZipCode-select"
        label="CEP"
        value={value}
        {...ip}
        {...inputProps}
      />}
    </ReactInputMask>
  );
}
