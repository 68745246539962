
import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  set_vehicle: [
    'id', 'brand', 'model', 'plate', 'color', 'year',
    'category', 'status', 'driver', 'height', 'width',
    'depth', 'capacity', 'regions',
    'created_at', 'updated_at'
  ],
  change_vehicle: [
    'brand', 'model', 'plate', 'color', 'year', 'category', 'status', 'driver',
    'height', 'width', 'depth', 'capacity', 'regions'
  ],
  clear_vehicle: []
})

const INITIAL_STATE = {
  id: '',
  brand: '',
  model: '',
  plate: '',
  color: '',
  year: '',
  category: '',
  status: '',
  driver_id: '',
  height: '',
  width: '',
  depth: '',
  capacity: '',
  regions: [],
  driver: {},
  created_at: '',
  updated_at: '',
}

const set_vehicle = (state = INITIAL_STATE, action) => {
  return action.value
}

const change_vehicle = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.value
  }
}

const clear_vehicle = (state = INITIAL_STATE, action) => {
  return INITIAL_STATE
}

export default createReducer(INITIAL_STATE,
  {
    [Types.SET_VEHICLE]: set_vehicle,
    [Types.CHANGE_VEHICLE]: change_vehicle,
    [Types.CLEAR_VEHICLE]: clear_vehicle,
  })
