import React from 'react';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import {
  makeStyles
} from '@material-ui/core/styles';
import clsx from 'clsx';
import moment from 'moment';
import 'moment/locale/pt-br';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';

import { TextInputFieldComponent } from '../';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  card: {
    flexGrow: 1,
    marginTop: theme.spacing(3)
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  container: {
    borderRadius: "5px",
    justify: "center",
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

export const DateTimeInputFieldComponent = (props) => {
  const classes = useStyles();
  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale="pt-BR">
      <KeyboardDateTimePicker
        variant="inline"
        size="small"
        format={props.format || "DD/MM/YYYY hh:mm:ss"}
        disabled={props.disabled}
        readOnly={props.readOnly}
        disableFuture={props.disableFuture}
        disablePast={props.disablePast}
        className={clsx(classes.textField, classes.fieldDate)}
        label={props.label}
        inputVariant="outlined"
        value={props.value ? moment(props.value) : null}
        fullWidth
        autoOk
        onChange={props.onChange}
        invalidDateMessage="Data inválida"
        invalidLabel="Inválido"
        maxDateMessage="Data deve ser menor que a data maxima"
        minDateMessage="Data deve ser maior que a data minima"
        TextFieldField={TextInputFieldComponent}
        {...props}
      />
    </MuiPickersUtilsProvider>
  )
}
