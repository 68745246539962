import React from "react";
import { IconButton } from "./";
import { Timeline } from "@material-ui/icons";
import { Tooltip, makeStyles } from "@material-ui/core";
import { purple } from "@material-ui/core/colors";

const useStyles = makeStyles(theme => ({
  button: {
    color: purple[500],
    '&:hover': {
      backgroundColor: purple[700],
      color: 'white',
    },
  },
}));

export function LogsIconButton(props) {
  const classes = useStyles()

  return (
    <IconButton
      className={classes.button}
      {...props}
    >
      <Tooltip title="logs"><Timeline /></Tooltip>
    </IconButton>
  );
}
