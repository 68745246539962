/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import {
  VehiclesDetailComponent,
  VehiclesSearchComponent,
  VehiclesListComponent
} from "./components";
import { AdminLayout } from "../../shared/layouts/admin.layout";

const INITIAL_FILTER = {
  plate: "",
  driverName: "",
  brand: "all",
  model: "all",
  category: "all",
  status: "all",
  orderBy: 'plate',
  orderDirection: 'asc',
}

export function VehiclesPage() {
  const [filters, setFilters] = React.useState(INITIAL_FILTER);
  const [vehicles, setVehicles] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const update = (vehicle) => {
    setVehicles(vehicles.map(s => s.id === vehicle.id ? vehicle : s))
  }

  return (
    <AdminLayout
      DrawerRightComponent={<VehiclesDetailComponent update={update} />}
      HeaderComponent={
        <VehiclesSearchComponent
          setFilters={setFilters}
          INITIAL_FILTER={INITIAL_FILTER}
          setLoading={setLoading} />
      }
      BodyComponent={<VehiclesListComponent
        filters={filters}
        setFilters={setFilters}
        vehicles={vehicles}
        setVehicles={setVehicles}
        loading={loading}
        setLoading={setLoading} />
      }
    />
  );

}

