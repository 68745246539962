
import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  loading: []
})

const INITIAL_STATE = false

const loading_change = (state = INITIAL_STATE, action) => {
  return action.value
}

export default createReducer(INITIAL_STATE,
  {
    [Types.LOADING]: loading_change,
  })