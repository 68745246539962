
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { PieChart } from 'react-minimal-pie-chart';
import { AccountTree } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  gridCicle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },
  title: {
    color: "#377CC9"
  },
  ulList: {
    // [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(1),
    // }
  },
  gridList: {
    // [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(5),
    // },
  },
  graphSkeleton: {
    [theme.breakpoints.down('sm')]: {
      width: "280px",
      height: "280px",
    },
    [theme.breakpoints.up('sm')]: {
      width: "150px",
      height: "150px",
    },
    [theme.breakpoints.up('md')]: {
      width: "200px",
      height: "200px",
    },
    [theme.breakpoints.up('lg')]: {
      width: "300px",
      height: "300px",
    },
  }
}));

export function RealTimeChartComponent(props) {
  const { loading, separations, sectors } = props;
  const classes = useStyles();
  const [separationsStatus, setSeparationsStatus] = React.useState([]);

  useEffect(() => {
    if (separations.length > 0 && sectors) {
      setSeparationsStatus(getSeparationsStatus())
    }
  }, [separations, sectors])

  const randomColor = (index) => {
    const colors = ["#377CC9", "#FFC107", "#4CAF50", "#F44336", "#9C27B0", "#E91E63", "#3F51B5", "#00BCD4", "#FF5722", "#607D8B", "#795548", "#FFEB3B", "#CDDC39", "#009688", "#8BC34A", "#FF9800", "#FFC107", "#FFEB3B", "#CDDC39", "#4CAF50", "#8BC34A", "#00BCD4", "#03A9F4", "#2196F3", "#3F51B5", "#673AB7", "#9C27B0", "#E91E63", "#F44336", "#FF5722", "#607D8B", "#795548"]
    return colors[index];
  }

  const getSeparationsStatus = () => {
    const seps = []
    sectors.map(sector => {
      const color = randomColor(sector.legacySectorId - 1);
      const separationsBySector = separations.filter(separation => separation.sector.id === sector.id)
      seps.push({
        label: sector.name,
        title: sector.name,
        value: separationsBySector.length,
        color: color,
        icon: <AccountTree style={{ color: color }} />
      })
    });
    return seps
  }

  const totalValue = () => separationsStatus.reduce((total, separation) => total + separation.value, 0)

  return <Grid container className={classes.container}>
    <Grid container className={classes.gridCicle}>
      <Grid item xs={12} md={5}>
        {loading ?
          <Skeleton variant="circle" className={classes.graphSkeleton} /> :
          <PieChart
            data={separationsStatus}
            labelStyle={(index) => ({
              fill: "black",
              fontSize: '10px',
              fontFamily: 'sans-serif',
            })}
            style={{
              height: '317px',
            }}
            totalValue={totalValue()}
            startAngle={-180}
            lineWidth={20}
            paddingAngle={1}
            labelPosition={50}
            lengthAngle={360}
            radius={50}
            viewBoxSize={[100, 100]}
          />}
      </Grid>
      {loading ?
        <Grid item xs={12} md={6} direction="column" alignItems="center" justifyContent="center" className={classes.gridList}>
          <ul className={classes.ulList}>
            {[...Array(separationsStatus.length)].map((_, index) => (
              <li key={index} style={{ paddingTop: "5px", listStyleType: "none" }}>
                <Skeleton variant="rect" width={'100px'} style={{ paddingLeft: "10px" }} />
              </li>
            ))}
          </ul>
        </Grid>
        :
        <Grid item xs={12} md={6}>
          <ul style={{
            display: 'flex',
            flexDirection: "column",
            alignItems: "flex-start",
            alignContent: 'center',
            flexWrap: 'wrap'
          }}>
            {separationsStatus?.filter(separation => separation.value > 0).map((separation, index) => (
              <li key={index} style={{ paddingTop: "5px", listStyleType: "none" }}>
                <span style={{ color: separation.color, paddingRight: "10px" }}>{separation.icon}</span>
                <span style={{ paddingRight: "10px" }}>{separation.title}</span>
                <span style={{ paddingRight: "10px" }}>{separation.value}</span>
              </li>
            ))}
          </ul>
        </Grid>
      }
    </Grid>
  </Grid>
}
