import { removeSpecialCharsLowerCase } from "./string.helpers";

export const splitArray = (array, size) => {
  if (!array || !Array.isArray(array) || array.length === 0) return [];
  if (!size || typeof size !== "number" || size <= 0) return [];
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
}

export const sortBy = (array, key, order = "asc") => {
  if (!array || !Array.isArray(array) || array.length === 0) return array;
  if (!key || typeof key !== "string") return array;
  if (!order || typeof order !== "string") return array;
  const result = [...array];
  // sort by key, can be a string, number, boolean or date (asc null last, desc null first)
  result.sort((a, b) => {
    let aKey = a[key];
    let bKey = b[key];
    if (typeof aKey === "string") {
      aKey = removeSpecialCharsLowerCase(aKey);
      bKey = removeSpecialCharsLowerCase(bKey);
    }
    if (aKey === bKey) return 0;
    if (aKey === null) return order === "asc" ? 1 : -1;
    if (bKey === null) return order === "asc" ? -1 : 1;
    if (aKey < bKey) return order === "asc" ? -1 : 1;
    return order === "asc" ? 1 : -1;
  });
  return result;
}

export const removeEmpty = (array) => {
  if (!array || !Array.isArray(array) || array.length === 0) return array;
  return array.filter(item => item);
}

export const groupByKey = (array, key, options) => {
  if (!array || !Array.isArray(array) || array.length === 0) return array;
  if (!key || typeof key !== "string") return array;
  const result = [];
  const { concat, sum } = options || {};
  array.forEach(item => {
    const itemKey = item[key];
    const resultItem = result.find(resultItem => resultItem[key] === itemKey);
    if (resultItem) {
      if (concat && Array.isArray(resultItem[concat])) {
        resultItem[concat] = [...resultItem[concat], item[concat]];
      } else {
        resultItem[concat] = [item[concat]];
      }
      if (sum && typeof resultItem[sum] === "number") {
        resultItem[sum] += item[sum];
      } else {
        resultItem[sum] = item[sum];
      }
    } else {
      if (concat) {
        item[concat] = [item[concat]];
      }
      result.push(item);
    }
  });
  return result;
}
