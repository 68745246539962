import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { green } from "@material-ui/core/colors";
import { SaveButton } from "./saveButton.component";

const useStyles = makeStyles(theme => ({
  button: {
    borderRadius: "20px",
    backgroundColor: green[600],
    '&:hover': {
      backgroundColor: green[400],
    },
  },
}));

export function CreateCsvButton(props) {
  const classes = useStyles();
  const { onClick, disabled } = props;

  return (
    <SaveButton
      className={classes.button}
      onClick={onClick}
      disabled={disabled}
      startIcon={<FontAwesomeIcon icon={faFileCsv} />}
      {...props}
    >
      {props.children || "Baixar CSV"}
    </SaveButton>
  );
}
