import React from 'react';
import NumberFormat from 'react-number-format';

export const NumberCashInputFieldComponent = (props) => {
  const { inputRef, prefix, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      fixedDecimalScale
      isNumericString
      thousandSeparator={'.'}
      decimalSeparator={','}
      maxLength="20"
      prefix="R$ "
    />
  );
}
