
import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  set_calendars: [],
  update_calendars_item: [],
  clear_calendars: [],
})

const INITIAL_STATE = []

const set_calendars = (state = INITIAL_STATE, action) => {
  return action.value
}

const update_calendars_item = (state = INITIAL_STATE, action) => {
  return state.map(calendar => {
    if (calendar.date === action.value.date) {
      return { ...calendar, ...action.value }
    }
    return calendar
  })
}


const clear_calendars = (state = INITIAL_STATE, action) => {
  return INITIAL_STATE
}

export default createReducer(INITIAL_STATE,
  {
    [Types.SET_CALENDARS]: set_calendars,
    [Types.UPDATE_CALENDARS_ITEM]: update_calendars_item,
    [Types.CLEAR_CALENDARS]: clear_calendars,
  })
