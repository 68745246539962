
import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  set_company: ["id", "name", "email_address", "document", "max_delivery_in_day", "max_weight_in_day", "updated_at", "created_at",],
  change_company: ["id", "name", "email_address", "document", "max_delivery_in_day", "max_weight_in_day", "updated_at", "created_at",],
  clear_company: ["id", "name", "email_address", "document", "max_delivery_in_day", "max_weight_in_day", "updated_at", "created_at",],
})

const INITIAL_STATE = {
  id: "",
  name: "",
  email_address: "",
  document: "",
  max_delivery_in_day: "",
  max_weight_in_day: "",
  updated_at: "",
  created_at: "",
}

const set_company = (state = INITIAL_STATE, action) => {
  return action.value
}

const change_company = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.value
  }
}

const clear_company = (state = INITIAL_STATE, action) => {
  return INITIAL_STATE
}

export default createReducer(INITIAL_STATE,
  {
    [Types.SET_COMPANY]: set_company,
    [Types.CHANGE_COMPANY]: change_company,
    [Types.CLEAR_COMPANY]: clear_company,
  })
