import React from "react";
import { Table, TableRow, TableBody, TableCell, TableHead, TableContainer, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Swal from "sweetalert2";

import {
  AddIconButton,
  DeleteIconButton,
  EditIconButton,
  TablePaginationComponent,
  TableRowLoadingComponent,
} from "../../../shared/components";
import { RegionsRepository } from "../../../shared/repositories";
import { RegionModel } from "../../../shared/models";
import { useDrawerRightContext } from "../../../shared/contexts/drawer_right.context";
import { removeSpecialCharsLowerCase } from "../../../shared/helpers";

const useStyles = makeStyles(theme => ({
  tableCell: {
    whiteSpace: "nowrap"
  },
  tableBody: {
    overflowX: "auto",
    maxHeight: 500
  }
}));

export function RegionsListComponent({ loading, regions = [], filters = {}, setRegions = () => { }, setLoading = () => { } }) {
  const [pagination, setPagination] = React.useState({ page: 1, perPage: 10, total: 0 });
  const [filteredRegions, setFilteredRegions] = React.useState([]);
  const regionsRepository = new RegionsRepository()
  const classes = useStyles();
  const { setNewDrawerValue } = useDrawerRightContext();

  const viewMore = company => e => {
    e.preventDefault();
    setNewDrawerValue(company.toState())
  }

  const newItem = e => {
    e.preventDefault();
    setNewDrawerValue(new RegionModel().toState())
  }

  React.useEffect(() => {
    setLoading(true);
    regionsRepository.getAll({
      page: pagination.page,
      perPage: pagination.perPage,
      orderBy: filters.orderBy,
      orderDirection: filters.orderDirection
    })
      .then(result => {
        setRegions(RegionModel.fromArray(result.data))
        setPagination({ ...pagination, total: result.total })
        setLoading(false)
      })
  }, []);

  React.useEffect(() => {
    filter()
  }, [filters, regions]);

  const filter = () => {
    setFilteredRegions([])
    let resp = regions.filter(region =>
      (!filters.name || removeSpecialCharsLowerCase(region.name && region.name).includes(removeSpecialCharsLowerCase(filters.name))) &&
      (!filters.document || removeSpecialCharsLowerCase(region.document && region.document).includes(removeSpecialCharsLowerCase(filters.document)))
    )
    setFilteredRegions(resp)
  }

  const deleteRow = region => e => {
    return Swal.fire({
      title: 'Confirmar?',
      showDenyButton: true,
      showCancelButton: true,
      icon: 'warning',
      confirmButtonText: 'Deletar',
      denyButtonText: 'Cancelar',
      confirmButtonColor: "#DD6B55",
      html: `<b>Você não poderá reverter esta ação!</b>`,
    }).then((result) => {
      if (result.isConfirmed)
        return regionsRepository.delete(region.id)
      else
        return Promise.reject('')
    })
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left" colSpan={1}><AddIconButton onClick={newItem} /></TableCell>
            <TableCell align="right" colSpan={5}>{filteredRegions.length} itens</TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="center">Nome</TableCell>
            <TableCell align="center">CEP Inicio</TableCell>
            <TableCell align="center">CEP Fim</TableCell>
            <TableCell align="center">Peso máximo</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {loading ?
            <TableRowLoadingComponent cols={5} />
            : filteredRegions.map(row => (
              <TableRow key={row.id} className={classes.tableRow}>
                <TableCell className={classes.tableCell}>
                  <DeleteIconButton onClick={deleteRow(row)} />
                  <EditIconButton onClick={viewMore(row)} />
                </TableCell>
                <TableCell align="center" className={classes.tableCell}><b>{row.name}</b></TableCell>
                <TableCell align="center" className={classes.tableCell}>{row.beginningZipcode}</TableCell>
                <TableCell align="center" className={classes.tableCell}>{row.endZipcode}</TableCell>
                <TableCell align="center" className={classes.tableCell}>{row.maxWeightInDayFormatted}</TableCell>
              </TableRow>
            ))}
          {!loading && !filteredRegions[0] ? <TableRow key="none" className={classes.tableRow}>
            <TableCell component="th" scope="row" colSpan="5" align="center" style={{ height: "300px" }}>
              <b>Não há empresas</b>
            </TableCell>
          </TableRow> : null}
        </TableBody>
        <TablePaginationComponent
          colSpan={5}
          count={pagination.total}
          rowsPerPage={pagination.perPage}
          page={pagination.page}
          loading={loading}
          onPageChange={value => { setPagination({ ...pagination, page: value }) }}
          onRowsPerPageChange={value => { setPagination({ ...pagination, page: 1, perPage: value }) }}
        />
      </Table>
    </TableContainer >)
}
