import React from "react";
import { IconButton } from "./";
import * as Colors from "@material-ui/core/colors";
import { Tooltip } from "@material-ui/core";
import { Lock } from "@material-ui/icons";

export function PasswordIconButton(props) {
  return (
    <IconButton
      className={{
        color: Colors.grey[300],
        '&:hover': {
          backgroundColor: Colors.grey[700],
          color: 'white',
        },
      }}
      {...props}>
      <Tooltip title="Alterar Senha"><Lock /></Tooltip>
    </IconButton>
  );
}
