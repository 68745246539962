
import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  set_store: ["id", "name", "document", "phone_number", "email_address", "updated_at", "created_at"],
  change_store: ["id", "name", "document", "phone_number", "email_address", "updated_at", "created_at"],
  clear_store: ["id", "name", "document", "phone_number", "email_address", "updated_at", "created_at"],
})

const INITIAL_STATE = {
  id: "",
  name: "",
  document: "",
  phone_number: "",
  email_address: "",
  updated_at: "",
  created_at: "",
}

const set_store = (state = INITIAL_STATE, action) => {
  return action.value
}

const change_store = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.value
  }
}

const clear_store = (state = INITIAL_STATE, action) => {
  return INITIAL_STATE
}

export default createReducer(INITIAL_STATE,
  {
    [Types.SET_STORE]: set_store,
    [Types.CHANGE_STORE]: change_store,
    [Types.CLEAR_STORE]: clear_store,
  })
