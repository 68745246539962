/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Paper, Typography } from "@material-ui/core";

import { SectorsRepository, SeparationsRepository } from "../../shared/repositories";
import { SeparationModel } from "../../shared/models";
import { RealTimeCardListComponent, RealTimeChartComponent } from "./components";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'row',
    flexWrap: 'wrap',
    height: "80vh",
    justifyContent: "center",
  },
  container: {
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      width: "95%",
      marginLeft: theme.spacing(6),
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(0),
      width: "100%",
      paddingLeft: '0px',
    }
  },
}));

const time = 5;

export function SeparationsRealTimePage() {
  const classes = useStyles();
  const [separations, setSeparations] = useState(SeparationModel.fromArray([]));
  const [loading, setLoading] = useState(false);
  const separationsRepository = new SeparationsRepository();
  const [sectors, setSectors] = useState(null);
  const [timer, setTimer] = useState(1);
  const sectorsRepository = new SectorsRepository();
  const [isTabVisible, setIsTabVisible] = useState(true);

  const handleVisibilityChange = useCallback(() => {
    setIsTabVisible(document.visibilityState === 'visible');
  }, []);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (timer === 1) {
      setLoading(true)
      separationsRepository.getRealTime()
        .then(respSeparation => {
          sectorsRepository.getAll({ page: 1, perPage: 1000 })
            .then(respSectors => {
              setSectors(respSectors.data)
              setSeparations(respSeparation.data)
            })
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => setLoading(false))
    }
  }, [timer]);

  useEffect(() => {
    if (isTabVisible) {
      let waitSec = setTimeout(() => {
        if (timer === 1) {
          setTimer(time)
        } else {
          setTimer(timer - 1)
        }
      }, 1 * 1000);
      return () => clearTimeout(waitSec);
    }
  }, [timer, isTabVisible]);

  return (
    <Container maxWidth="false" className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Paper className={classes.paper}>
            <Typography component="h2" variant="h6" color="primary" gutterBottom style={{ whiteSpace: "nowrap" }}>
              Em Separção
            </Typography>
            <RealTimeChartComponent separations={separations} sectors={sectors} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper className={classes.paper}>
            <RealTimeCardListComponent separations={separations} loading={loading} />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );

}

