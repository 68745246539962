
export class PhoneNumberHelper {
  constructor(phoneNumber) {
    this._phoneNumber = phoneNumber ? phoneNumber.replace(/[^0-9]/g, "") : "";
  }

  get phoneNumber() {
    return this._phoneNumber;
  }

  set phoneNumber(phoneNumber) {
    this._phoneNumber = phoneNumber;
  }

  get phoneNumberWithoutDDI() {
    return this._phoneNumber.substring(2);
  }

  get phoneNumberWithoutDDD() {
    return this._phoneNumber.substring(4);
  }

  get formatted() {
    var phone = this._phoneNumber;
    if (phone.startsWith("55")) {
      phone = phone.replace("55", "");
    }

    if (phone.length === 10) {
      phone = `(${phone.substr(0, 2)}) ${phone.substr(2, 4)}-${phone.substr(6, 10)}`;
    }

    if (phone.length === 11) {
      phone = `(${phone.substring(0, 2)}) ${phone.substring(2, 7)}-${phone.substring(7, 11)}`;
    }
    return phone;
  }
}
