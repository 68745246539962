import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import App from '../App';
import { SignInPage } from '../pages';

function PrivateRoutes({ children, ...rest }) {
  const auth = useSelector(state => state.auth)
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth['token'] && auth['token'].length ? children : <Redirect to={{ pathname: "/signIn", state: { from: location } }} />
      }
    />
  );
}

export function Routes() {
  return <BrowserRouter>
    <Switch>
      <Route exact path="/signIn" component={SignInPage}></Route>
      <PrivateRoutes path="/">
        <App />
      </PrivateRoutes>
    </Switch>
  </BrowserRouter>
}
