import Entity from "./entity.model";

const SUPPORTED_ATTRS = [
  "id",
  "street",
  "number",
  "neighborhood",
  "complement",
  "zip_code",
  "city",
  "state",
  "clientId",
  "phoneId",
  "emailId",
  "_destroy",
];

export class AddressModel extends Entity {
  constructor(attrs = {}) {
    super(attrs, SUPPORTED_ATTRS);
  }

  get zipCodeFormatted() {
    return `${this.zip_code}`.replace(/\D/g, "");
  }

  toJSON() {
    return {
      id: this.id,
      street: this.street,
      number: this.number,
      neighborhood: this.neighborhood,
      complement: this.complement,
      zip_code: this.zipCode,
      city: this.city,
      state: this.state,
      phone_id: this.phoneId,
      email_id: this.emailId,
      _destroy: this._destroy,
    };
  }

  toState() {
    return super.toState({
      zipCode: this.zipCodeFormatted,
    });
  }
}
