import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { faArrowTurnUp } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles(theme => ({
  listItem: {
    "&:hover": {
      borderRadius: "10px",
      backgroundColor: "#fff",
    }
  }
}));

export function SubItemsNavbarComponent() {
  const classes = useStyles();
  const items = [
    { name: "Entregas", link: "entregas" },
  ];

  const resolveListItem = (item) => {
    return <>
    <ListItem button component={Link} to={`/relatorios/${item.link}`} className={classes.listItem}>
      <Grid container spacing={1} alignItems="center" justifyContent="center">
        <Grid item xs={3}>
          <ListItemIcon><FontAwesomeIcon icon={faArrowTurnUp} size={'lg'} rotation={90} /></ListItemIcon>
        </Grid>
        <Grid item xs={9}>
          <ListItemText primary={item.name} />
        </Grid>
      </Grid>
    </ListItem>
    </>
  }

  return (<List>
    {items.map(item => resolveListItem(item))}
  </List>
  );
}
