import React, { useEffect } from "react";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Container, Drawer, Grid, useMediaQuery } from "@material-ui/core";
import clsx from "clsx";
import { useDrawerRightContext } from "../contexts/drawer_right.context";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    paddingTop: theme.spacing(2)
  },
  drawer: {
    minWidth: '300px',
  },
  drawerPaper: {
    width: '520px',
    height: 'calc(100% - 64px)',
    top: 64,
    [theme.breakpoints.down('xs')]: {
      minWidth: "100%",
      marginLeft: theme.spacing(6),
    }
  },
  container: {
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      width: "95%",
      marginLeft: theme.spacing(6),
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(0),
      width: "100%",
      paddingLeft: '0px',
    }
  },
  content: {
    [theme.breakpoints.up("md")]: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginRight: "0",
    }
  },
  contentShift: {
    [theme.breakpoints.up("md")]: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: '500px',
    }
  },
}));

export const AdminLayout = ({ HeaderComponent, BodyComponent, FooterComponent, DrawerRightComponent }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { isDrawerRightOpen, toggleDrawerRightOpen } = useDrawerRightContext();

  // on change path close drawer
  useEffect(() => {
    isDrawerRightOpen && toggleDrawerRightOpen();
  }, [window.location.pathname]);

  return (
    <Container className={classes.container} maxWidth="false">
      {DrawerRightComponent && <Drawer
        anchor="right"
        open={isDrawerRightOpen}
        onClose={toggleDrawerRightOpen}
        variant={isSmallScreen ? "temporary" : "persistent"}
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {isDrawerRightOpen && DrawerRightComponent}
      </Drawer>}
      <main className={clsx(classes.content, { [classes.contentShift]: isDrawerRightOpen, })}>
        <Grid container spacing={2} className={classes.root}>
          {HeaderComponent && <Grid item xs={12} md={12} >
            {HeaderComponent}
          </Grid>}
          {BodyComponent && <Grid item xs={12} md={12} >
            {BodyComponent}
          </Grid>}
          {FooterComponent && <Grid item xs={12} md={12} >
            {FooterComponent}
          </Grid>}
        </Grid >
      </main>
    </Container >
  )
}
