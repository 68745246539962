import { AuthRepository } from "./auth.repository";
import { Repository } from "./repository";

export class CalendarsRepository extends Repository {
  constructor() {
    super();
    this.authRepository = new AuthRepository();
  }

  async getAll(month, year) {
    return this.api.get({ path: `calendars/${year}/${month}`, headers: this.headers() })
      .then(response => {
        this.dispatch({ type: "SET_CALENDARS", value: response.data });
        return Promise.resolve(response.data);
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.getAll.bind(this),
        callbackOnError: () => { this.dispatch({ type: "CLEAR_CALENDARS" }) }
      }));
  }
}
