import Entity from "./entity.model";

const SUPPORTED_ATTRS = [
  "id",
  "name",
  "friendlyName",
  "document",
  "maxDeliveryInDay",
  "maxWeightInDay",
  "emailAddress",
  "cid",
  "slug",
  "colors",
  "logo",
  "icon",
  "logoGif",
  "createdAt",
  "updatedAt"
];

export class CompanyModel extends Entity {
  constructor(attrs = {}) {
    super(attrs, SUPPORTED_ATTRS);
  }

  get iconBlob() {
    if (this.icon?.name)
      return this.icon;
  }

  get logoBlob() {
    if (this.logo?.name)
      return this.logo;
  }

  get logoGifBlob() {
    if (this.logoGif?.name)
      return this.logoGif;
  }

  get emailAddress() {
    return this.emails && this.emails.length > 0 ? this.emails[0].address : null;
  }

  toJSON() {
    return {
      id: this.id,
      name: this.name,
      document: this.document,
      max_delivery_in_day: this.maxDeliveryInDay,
      max_weight_in_day: this.maxWeightInDay,
      email_address: this.emailAddress,
      cid: this.cid,
      slug: this.slug,
      icon: this.iconBlob,
      logo: this.logoBlob,
      logo_gif: this.logoGifBlob,
    };
  }
}
