import React from "react";
import { Table, TableRow, TableBody, TableCell, TableHead, Typography } from "@material-ui/core";
import { TextInputFieldComponent } from "../Fields";
import { AddIconButton, DeleteIconButton, EditIconButton, HistoryIconButton } from "../Buttons";
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx";
import { green, red } from "@material-ui/core/colors";

const useStyles = makeStyles(theme => ({
  root: {
  },
  inputAdd: {
    color: green[900],
  },
  inputDelete: {
    textDecoration: 'line-through',
    color: red[900],
  }
}));

export function EmailList(props) {
  const { emails } = props;
  const handleChangeModel = props.handleChange;
  const classes = useStyles();

  const handleChangeEmails = (emails) => {
    handleChangeModel(emails);
  }

  const handleChange = (index, name) => event => {
    const newEmailList = [...emails];
    newEmailList[index][name] = event.target.value;
    handleChangeEmails(newEmailList);
  }

  const addEmail = () => {
    handleChangeEmails([...(emails || []), { edit: true }]);
  }

  const editEmail = (index) => {
    const newEmailList = [...emails];
    delete newEmailList[index]._destroy;
    newEmailList[index].edit = true;
    handleChangeEmails(newEmailList);
  }

  const rollbackEmail = (index) => {
    const newEmailList = [...emails];
    delete newEmailList[index]._destroy;
    handleChangeEmails(newEmailList);
  }

  const removeEmail = (index) => {
    const newEmailList = [...emails];
    newEmailList[index].id ? newEmailList[index]._destroy = true : newEmailList.splice(index, 1);
    handleChangeEmails(newEmailList);
  }

  return (
    <Table className={classes.root}>
      <TableHead>
        <TableRow>
          <TableCell><Typography variant="body1" gutterBottom>Emails</Typography></TableCell>
          <TableCell colSpan={2} align="right">
            <AddIconButton onClick={addEmail} />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {emails?.map((email, index) => (
          <TableRow key={index}>
            <TableCell colSpan={2}>
              {email.edit ? (
                <TextInputFieldComponent
                  value={email.address}
                  onChange={handleChange(index, 'address')}
                  disabled={email._destroy}
                  error={email._destroy}
                  help="Marcado para deleção"
                  classNameInput={clsx({ [classes.inputAdd]: !email.id })}
                />
              ) : (
                <Typography variant="body1" gutterBottom className={clsx({ [classes.inputDelete]: email._destroy })}>{email.address}</Typography>
              )}
            </TableCell>
            <TableCell align="right">
              {!email.edit && <EditIconButton onClick={() => editEmail(index)} />}
              {email._destroy ?
                <HistoryIconButton onClick={() => rollbackEmail(index)} ></HistoryIconButton>
                :
                <DeleteIconButton onClick={() => removeEmail(index)} ></DeleteIconButton>
              }
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table >

  );
}
