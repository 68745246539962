import { Constants } from "./constants.helper";

export function authorize(auth, roles) {
  return roles.includes(auth.role);
}

export function authorizeOwner(auth) {
  return [Constants.User.Role.System, Constants.User.Role.Owner].includes(auth.role);
}

export function authorizeAdmin(auth) {
  return [Constants.User.Role.System, Constants.User.Role.Owner, Constants.User.Role.Admin].includes(auth.role);
}

export function authorizeChecker(auth) {
  return [Constants.User.Role.System, Constants.User.Role.Owner, Constants.User.Role.Admin, Constants.User.Role.Checker].includes(auth.role);
}

export function authorizedRoles(role) {
  const roles = Object.values(Constants.User.Role);
  switch (role) {
    case Constants.User.Role.System:
      return roles;
    case Constants.User.Role.Owner:
    case Constants.User.Role.Admin:
      return roles.filter((role) => role !== Constants.User.Role.System);
    case Constants.User.Role.Checker:
      return roles.filter((role) => role !== Constants.User.Role.Admin);
    case Constants.User.Role.ChiefDriver:
      return roles.filter((role) => role === Constants.User.Role.Driver);
    default:
      return [];
  }
}
