import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import { Image } from '@material-ui/icons';
import { useDropzone } from 'react-dropzone';
import { Constants } from '../../helpers';


export const DropzoneFileComponent = ({ parentId, showPreviewImage = false, maxFiles = 1, handleChange, defaultUrl, accept, name, label = 'Jogue seus arquivos aqui' }) => {
  const [value, setValue] = useState(null);
  const [preview, setPreview] = useState(defaultUrl || '');

  const resolveImagePath = (url) => {
    if (url)
      if (process.env.NODE_ENV === "development") {
        return `${Constants.development.apiUrl}${url}`;
      } else {
        return url;
      }
  }

  useEffect(() => {
    setValue(null)
    setPreview(resolveImagePath(defaultUrl) || '')
  }, [parentId])


  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (!file) {
      setPreview(null);
    } else {
      handleChange({ target: { value: file } })
      setValue(file);
      if (showPreviewImage) {
        const previewURL = URL.createObjectURL(file);
        setPreview(previewURL);
      }
    }
  }, [value]);

  const { getInputProps, getRootProps, isDragAccept, isDragReject } = useDropzone({ onDrop, accept, maxFiles: maxFiles });

  return (
    <Grid
      {...getRootProps()}
      container
      spacing={2}
      padding={2}
      style={{
        paddingRight: 0,
        cursor: 'pointer'
      }}>
      <input {...getInputProps()} name={name} />
      <Grid
        container
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundColor: (() => {
            if (isDragAccept)
              return green[300];
            else if (isDragReject)
              return red[300];
            return '';
          })(),
          border: '1px solid',
          margin: '15px',
          paddingTop: '10px',
          paddingBottom: '10px',
          width: '100%',
          '&:hover': {
            opacity: 0.6
          }
        }}>
        <Grid item style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center', alignContent: 'center' }} >
          <Image />
          <Typography variant="body2" component="div">{isDragReject ? 'Arquivo não suportado' : label}</Typography>
        </Grid>
        <Grid
          container
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Grid item style={{ height: showPreviewImage ? '200px' : '', maxWidth: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {showPreviewImage && <img src={preview || defaultUrl || '/assets/img/fallback.jpg'} alt="Preview" style={{ maxHeight: '100%', maxWidth: '100%' }} />}
          </Grid>
          <Typography variant="body2" component="div">Araste e solte</Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent='space-around'>
        {value?.name}
      </Grid>
    </Grid >

  );

};
