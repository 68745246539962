import Entity from "./entity.model";
import { OrderItemModel } from "./orderItem.model";

const SUPPORTED_ATTRS = [
  "id",
  "amount",
  "separatedAmount",
  "orderItem",
  "createdAt",
  "updatedAt",
];

export class OrderItemSeparationModel extends Entity {
  constructor(attrs = {}) {
    super(attrs, SUPPORTED_ATTRS);
  }

  toJSON() {
    return {
      id: this.id,
      amount: this.amount,
      separated_amount: this.separatedAmount,
      order_item_id: this.orderItem.id,
    };
  }

  static fromJSON(attrs) {
    return super.fromJSON({
      ...attrs,
      order_item: OrderItemModel.fromJSON(attrs.order_item),
    })
  }

  toState() {
    return super.toState({
      ...this.attrs,
      orderItem: this.orderItem.toState(),
    });
  }
}
