import { UserModel } from "../models";
import { AuthRepository } from "./auth.repository";
import { Repository } from "./repository";

export class UsersRepository extends Repository {
  constructor() {
    super();
    this.authRepository = new AuthRepository();
  }

  async getAll({ queryParams = "", page = 1, perPage = 10, orderBy = "name", orderDirection = "asc" }) {
    return this.api.get({ path: `users?q=${queryParams}&page=${page}&per_page=${perPage}&order_by=${orderBy}&order_direction=${orderDirection}`, headers: this.headers() })
      .then(response => {
        return Promise.resolve({ data: UserModel.fromArray(response.data), total: response.headers['total'] });
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.getAll.bind(this),
      }));
  }

  async getDrivers() {
    return this.api.get({ path: "users?roles=driver", headers: this.headers() })
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.getDrivers.bind(this),
      }));
  }

  async getCheckers() {
    return this.api.get({ path: "users?roles=checker", headers: this.headers() })
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.getCheckers.bind(this),
      }));
  }

  async getChiefDrivers() {
    return this.api.get({ path: "users?roles=chief_driver", headers: this.headers() })
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.getChiefDrivers.bind(this),
      }
      ));
  }

  async getFreightDriverReport(user, startDate, endDate, freightType, storeId, type = "pdf") {
    let filters = []
    if (freightType)
      filters = filters.concat(`freight_type=${freightType}`)
    if (storeId)
      filters = filters.concat(`store_id=${storeId}`)
    return this.api.get({ path: `users/${user.id}/freight/${startDate}/${endDate}.${type}?${filters.join("&")}`, headers: this.headers(), responseType: "blob" })
      .then(response => {
        this.resolveResponseBlob({ response, type: 'pdf', fileName: `entregas_${user.name.toLowerCase().replaceAll(' ', '-')}-${startDate}_${endDate}` });
      })
      .catch(error => this.resolveErrorResponseBlob({
        error,
        callback: this.getQuery.bind(this)
      }));
  }

  async create(user) {
    return this.api.post({ path: "users", headers: this.headers(), body: { ...user } })
      .then(response => {
        this.newSnackbarSuccess("Usuário criado com sucesso");
        return Promise.resolve(UserModel.fromJSON(response.data));
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.create.bind(this),
      }));
  }

  async update(user) {
    return this.api.put({ path: `users/${user.id}`, headers: this.headers(), body: this.omitKeys(user, ['id']) })
      .then(response => {
        this.newSnackbarSuccess("Usuário atualizado com sucesso");
        return Promise.resolve(UserModel.fromJSON(response.data));
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.update.bind(this),
      }));
  }

  async updatePassword(id, body) {
    return this.api.put({ path: `users/${id}/password`, headers: this.headers(), body })
      .then(() => {
        this.newSnackbarSuccess("Senha alterada com sucesso");
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.updatePassword.bind(this),
      }));
  }

  async delete(id) {
    return this.api.delete({ path: `users/${id}`, headers: this.headers() })
      .then(() => {
        this.newSnackbarSuccess("Usuário deletado com sucesso");
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.delete.bind(this),
      }));
  }
}
