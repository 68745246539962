import Axios from 'axios';
import { Constants } from './constants.helper';

export class ApiHelper {
  constructor() {
    this._endpoint = Constants[process.env.NODE_ENV].apiUrl
  }

  get endpoint() {
    return this._endpoint
  }

  async get({ path, headers, responseType = 'json' }) {
    return Axios({
      method: 'GET',
      url: `${this._endpoint}${path}`,
      headers,
      responseType
    })
  }

  async post({ path, body, headers }) {
    return Axios({
      method: 'POST',
      url: `${this._endpoint}${path}`,
      headers,
      data: body
    })
  }

  async put({ path, body, headers }) {
    return Axios({
      method: 'PUT',
      url: `${this._endpoint}${path}`,
      headers,
      data: body
    })
  }


  async patch({ path, body, headers }) {
    return Axios({
      method: 'PATCH',
      url: `${this._endpoint}${path}`,
      headers,
      data: body
    })
  }

  async delete({ path, headers }) {
    return Axios({
      method: 'DELETE',
      url: `${this._endpoint}${path}`,
      headers
    })
  }
}
