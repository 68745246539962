import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";

const useStyles = makeStyles(theme => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  button: {
    whiteSpace: "nowrap",
    borderRadius: "20px",
    backgroundColor: blue[900],
    '&:hover': {
      backgroundColor: blue[100],
    },
  },
}));
export function DefaultButton(props) {
  const classes = useStyles();
  const { onClick, disabled, children, label = true } = props;

  return (
  <div className={classes.wrapper}>
    <Button
      variant="contained"
      color="primary"
      className={classes.button}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {label && children}
    </Button>
  </div>
  );
}
