import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { PrivateRoutes } from './Routes';
import { CompanySelectorIconButton, NavbarComponent } from './shared/components';
import { Constants } from './shared/helpers';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    minWidth: 300,
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(10),
    },
    [theme.breakpoints.up('xs')]: {
      paddingLeft: "",
    }
  },
}));

const getFaviconEl = () => {
	return document.getElementById("favicon");
}

export default function App() {
  const { auth } = useSelector(state => state)
  const classes = useStyles();

	const resolveIconUrl = (company) => {
		if (company.icon && company.icon.url)
			if (process.env.NODE_ENV === "development") {
				return `${Constants.development.apiUrl}${company.icon.url}`;
			} else {
				return company.icon.url;
			}
		return '/assets/img/logo.ico'
	};

  useEffect(() => {
		const favicon = getFaviconEl();
    const company = auth.companies.find(i => i.cid === auth.cid)
		document.title = company?.name ? `${company.name} - Entregas` : 'Entregas';
		favicon.href = company?.icon?.url ? resolveIconUrl(company) : '/assets/img/logo.ico';
  }, [auth.cid])

  return (
    <div className={classes.root}>
      <NavbarComponent />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <PrivateRoutes />
        <CompanySelectorIconButton/>
      </main>
    </div>
  );
}
