import Entity from "./entity.model";
import { Constants, PhoneNumberHelper } from "../helpers";

const SUPPORTED_ATTRS = [
  "id",
  "legacyOrderId",
  "phoneNumber",
  "emailAddress",
  "addressZipCode",
  "addressStreet",
  "addressNumber",
  "addressComplement",
  "addressNeighborhood",
  "addressCity",
  "addressState",
  "fullAddress",
  "status",
  "problemDescription",
  "lastedStatus",
  "statusUpdatedAt",
  "freightType",
  "freight",
  "weight",
  "remark",
  "driver",
  "checker",
  "client",
  "checkedAt",
  "expectedDeliveryIn",
  "deliveredAt",
  "deliveredTo",
  "separatedAt",
  "smsWillBeDeliveredTodayAt",
  "smsOutForDeliveryAt",
  "smsDeliveredAt",
  "store",
  "seller",
  "region",
  "duplicateReason",
  "duplicateRemark",
  "orderValue",
  "images",
  "createdAt",
  "updatedAt",
];

export class DeliveryModel extends Entity {
  constructor(attrs = {}) {
    super(attrs, SUPPORTED_ATTRS);
  }

  get phoneNumberFormatted() {
    return new PhoneNumberHelper(this.phoneNumber).formatted;
  }

  get phoneNumberWithoutDDI() {
    return new PhoneNumberHelper(this.phoneNumber).phoneNumberWithoutDDI;
  }

  get resolvedStatusName() {
    var item = Constants.Delivery.Statuses.find(status => status.label === this.status)
    return item ? item.value : this.status;
  }

  get freightFormatted() {
    return this.freight ? new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2
    }).format(this.freight) : null;
  }

  get freightUnformatted() {
    return this.freight ? this.freight.replaceAll('R$', '').replaceAll('.', '').replaceAll(',', '.').trim() : null;
  }

  get weightFormatted() {
    return this.weight ? new Intl.NumberFormat("pt-BR", {
      style: "decimal",
      minimumFractionDigits: 2
    }).format(this.weight) : null;
  }

  get weightUnformatted() {
    return this.weight ? this.weight.replaceAll('Kg', '').replaceAll('.', '').replaceAll(',', '.').trim() : null;
  }

  get expectedDeliveryInFormatted() {
    return this.expectedDeliveryIn ? new Date(`${this.expectedDeliveryIn} `).toLocaleDateString('pt-br') : null;
  }

  get separatedAtFormatted() {
    return this.separatedAt ? new Date(this.separatedAt).toLocaleString('pt-br') : null;
  }

  get deliveredAtFormatted() {
    return this.deliveredAt ? new Date(this.deliveredAt).toLocaleString('pt-br') : null;
  }

  get checkedAtFormatted() {
    return this.checkedAt ? new Date(this.checkedAt).toLocaleString('pt-br') : null;
  }

  get smsWillBeDeliveredTodayAtFormatted() {
    return this.smsWillBeDeliveredTodayAt ? new Date(this.smsWillBeDeliveredTodayAt).toLocaleString('pt-br') : null;
  }

  get smsOutForDeliveryAtFormatted() {
    return this.smsOutForDeliveryAt ? new Date(this.smsOutForDeliveryAt).toLocaleString('pt-br') : null;
  }

  get smsDeliveredAtFormatted() {
    return this.smsDeliveredAt ? new Date(this.smsDeliveredAt).toLocaleString('pt-br') : null;
  }

  get regionName() {
    return this.region ? this.region.name : null;
  }

  toJSON() {
    return {
      id: this.id,
      legacy_order_id: this.legacyOrderId,
      phone_number: this.phoneNumber,
      email_address: this.emailAddress,
      address_zip_code: this.addressZipCode,
      address_street: this.addressStreet,
      address_number: this.addressNumber,
      address_complement: this.addressComplement,
      address_neighborhood: this.addressNeighborhood,
      address_city: this.addressCity,
      address_state: this.addressState,
      status: this.status,
      problem_description: this.problemDescription,
      freight_type: this.freightType,
      freight: this.freightUnformatted,
      weight: this.weightUnformatted,
      expected_delivery_in: this.expectedDeliveryInFormatted,
      separated_at: typeof this.separatedAt === 'string' ? this.separatedAt : this.separatedAt?.toISOString(),
      checked_at: typeof this.checkedAt === 'string' ? this.checkedAt : this.checkedAt?.toISOString(),
      delivered_at: this.deliveredAt,
      delivered_to: this.deliveredTo,
      remark: this.remark,
      store_id: this.store?.id,
      seller_id: this.seller?.id,
      driver_id: this.driver?.id,
      checker_id: this.checker?.id,
      client_id: this.client?.id,
      region_id: this.region?.id,
      duplicate_reason: this.duplicateReason,
      duplicate_remark: this.duplicateRemark,
    };
  }

  toXlsx() {
    return {
      'Pedido': this.legacyOrderId,
      'Status': this.resolvedStatusName,
      'Cliente': this.clientName,
      'Telefone': this.phoneNumberFormatted,
      'Email': this.emailAddress,
      'Motorista': this.driverName,
      'Data Prevista de Entrega': this.expectedDeliveryInFormatted,
      'Endereço': this.fullAddress,
      'Tipo do frete': this.freightType,
      'Frete': this.freight,
      'Peso': this.weight,
      'Observações': this.remark,
      'Conferente': this.checkerName,
      'Data de Separação': this.separatedAtFormatted,
      'Data de Entrega': this.deliveredAtFormatted,
      'Data da Conferencia': this.checkedAtFormatted,
    };
  }

  toState() {
    return super.toState({
      freight: this.freightFormatted,
      weight: this.weightFormatted,
      phoneNumber: this.phoneNumberWithoutDDI,
      smsWillBeDeliveredTodayAtFormatted: this.smsWillBeDeliveredTodayAtFormatted,
      smsOutForDeliveryAtFormatted: this.smsOutForDeliveryAtFormatted,
      smsDeliveredAtFormatted: this.smsDeliveredAtFormatted,
      regionName: this.regionName,
    });
  }
}
