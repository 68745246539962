
import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  auth_sign_in: ["id", "token", "expires_at", "name", "phone_number", "role", "cid", "companies"],
  auth_set_cid: ["cid"],
  auth_sign_out: [],
})

const INITIAL_STATE = {
  id: "",
  token: "",
  expires_at: "",
  name: "",
  phone_number: "",
  role: "",
  cid: "",
  companies: []
}

const sign_in = (state = INITIAL_STATE, action) => {
  return action.value
}

const set_cid = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    cid: action.value
  }
}

const sign_out = (state = INITIAL_STATE, action) => {
  return INITIAL_STATE
}


export default createReducer(INITIAL_STATE,
  {
    [Types.AUTH_SIGN_IN]: sign_in,
    [Types.AUTH_SET_CID]: set_cid,
    [Types.AUTH_SIGN_OUT]: sign_out,
  })
