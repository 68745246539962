
import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  set_restricted_dates: [],
  create_restricted_dates_item: [],
  update_restricted_dates_item: [],
  delete_restricted_dates_item: ['value'],
  clear_restricted_dates: [],
  delete_restricted_date: ['value'],
})

const INITIAL_STATE = []

const set_restricted_date = (state = INITIAL_STATE, action) => {
  return action.value
}

const create_restricted_date_item = (state = INITIAL_STATE, action) => {
  return [action.value, ...state]
}

const update_restricted_date_item = (state = INITIAL_STATE, action) => {
  return state.map(restricted_date => {
    if (restricted_date.id === action.value.id) {
      return action.value
    }
    return restricted_date
  })
}

const delete_restricted_date_item = (state = INITIAL_STATE, action) => {
  return state.filter(restricted_date => restricted_date.id !== action.value)
}

const clear_restricted_date = (state = INITIAL_STATE, action) => {
  return INITIAL_STATE
}

const delete_restricted_date = (state = INITIAL_STATE, action) => {
  return state.filter(restricted_date => restricted_date.id !== action.value)
}

export default createReducer(INITIAL_STATE,
  {
    [Types.SET_RESTRICTED_DATES]: set_restricted_date,
    [Types.CREATE_RESTRICTED_DATES_ITEM]: create_restricted_date_item,
    [Types.UPDATE_RESTRICTED_DATES_ITEM]: update_restricted_date_item,
    [Types.DELETE_RESTRICTED_DATES_ITEM]: delete_restricted_date_item,
    [Types.CLEAR_RESTRICTED_DATES]: clear_restricted_date,
    [Types.DELETE_RESTRICTED_DATE]: delete_restricted_date
  })
