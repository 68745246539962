import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import OfflinePinIcon from '@material-ui/icons/OfflinePin';
import StepConnector from '@material-ui/core/StepConnector';
import ErrorIcon from '@material-ui/icons/Error';

import { Constants } from '../../../../../shared/helpers';

const ColorLibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient(90deg, rgba(76,161,255,1) 0%, rgba(35,76,137,1) 50%, rgba(2,0,36,1) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundColor: 'rgba(76,161,255,1)'
    },
  },
  error: {
    '& $line': {
      backgroundColor: 'red'
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorLibStepIconStyles = makeStyles(theme => ({
  "@keyframes changeColor": {
    "0%": {
      backgroundColor: "#377cc9"
    },
    "50%": {
      backgroundColor: "#2f6ab1"
    },
    "100%": {
      backgroundColor: "#234c89"
    },
  },
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    animation: `$changeColor 1s infinite alternate`,
    // backgroundImage:
    //   'radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(35,76,137,1) 87%, rgba(55,124,201,1) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundColor: '#4ca1ff',
  },
  error: {
    backgroundColor: '#ff0000',
  }
}));

function ColorLibStepIcon(props) {
  const classes = useColorLibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    "open": <NewReleasesIcon />,
    "separation": <AccountTreeIcon />,
    "checking": <FormatListBulletedIcon />,
    "checked": <PlaylistAddCheckIcon />,
    "out_for_delivery": <LocalShippingIcon />,
    "delivered": <OfflinePinIcon />,
    "problem": <ErrorIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: !props.error && active,
        [classes.completed]: !props.error && completed,
        [classes.error]: props.error,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps(delivery) {
  let steps = ["open", "separation", "checking", "checked", "out_for_delivery", "delivered"];
  if (delivery.status === "problem")
    steps = steps.slice(0, steps.indexOf(delivery.lastedStatus) + 1).concat(["problem"]);
  return steps;
}

function getStepContent(step) {
  let item = Constants.Delivery.Statuses.filter(status => status.label === step);
  return item[0]?.value;
}
export function TableStatusSteppersComponent(props) {
  const { delivery } = props;
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(1);
  const steps = getSteps(delivery);

  React.useEffect(() => {
    if (delivery.status === "checking" && delivery.checked_at) {
      setActiveStep(3);
    } else
      setActiveStep(steps.indexOf(delivery.status));
  }, [delivery.checked_at, delivery.status, steps]);

  return (
    <div className={classes.root}>
      <Stepper alternativeLabel activeStep={activeStep} connector={<ColorLibConnector error={delivery.status === "problem"} />}>
        {steps.map((label) => (
          <Step key={label}>
            {delivery.status === "problem" ?
              <StepLabel key={delivery.status} StepIconComponent={ColorLibStepIcon} error icon={label}>
                {label === "checked" && delivery.checked_at ?
                  `${getStepContent(label)} ${new Date(delivery.checked_at).toLocaleString()}`
                  : getStepContent(label)}
              </StepLabel>
              :
              <StepLabel StepIconComponent={ColorLibStepIcon} icon={label}>
                {label === "checked" && delivery.checked_at ?
                  `${getStepContent(label)} ${new Date(delivery.checked_at).toLocaleString()}`
                  : getStepContent(label)}
              </StepLabel>
            }
          </Step>
        ))}
      </Stepper>
    </div >
  );
}
