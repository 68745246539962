import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Drawer, Grid, AppBar, Toolbar, Typography,
  Tooltip, CssBaseline, useMediaQuery
} from '@material-ui/core';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { Menu } from '@material-ui/icons';
import { IconButton } from '../Buttons';
import { LeftNavbarComponent, RightNavbarComponent } from './';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  grow: {
    display: 'flex',
  },
  appBar: {
    backgroundColor: "#377CC9",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: 280,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${280}px)`,
    }
  },
  menuButton: {
    [theme.breakpoints.up('sm')]: {
      marginRight: 36,
    }
  },
  hide: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    }
  },
  drawerPaper: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    }
  },
  drawer: {
    width: 280,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: 280,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8) + 1,
    },
  },
  typography: {
    paddingRight: theme.spacing(2),
  },
  title: {
    cursor: "pointer",
    display: 'block'
  },
  paper: {
    paddingBottom: 50,
  },
  floatLeft: {
    float: 'left'
  },
}));

export function NavbarComponent() {
  const classes = useStyles();
  const { auth } = useSelector(state => state)
  const [drawer, setDrawer] = React.useState(false);
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const drawerProps = {
    mobile: {
      variant: "temporary",
      anchor: 'left',
      classes: {
        paper: classes.drawerPaper,
      },
      ModalProps: {
        keepMounted: true,
      },
    },
    desktop: {
      variant: "permanent",
      className: clsx(classes.drawer, {
        [classes.drawerOpen]: drawer,
        [classes.drawerClose]: !drawer,
      }),
      classes: {
        paper: clsx({
          [classes.drawerOpen]: drawer,
          [classes.drawerClose]: !drawer,
        }),
      },
    }
  }

  const handleDrawer = () => {
    setDrawer(!drawer);
  };


  return (
    <div className={classes.grow}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, { [classes.appBarShift]: drawer, })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawer}
            edge="start"
            className={clsx(classes.menuButton, { [classes.hide]: drawer, })}
          >
            <Menu />
          </IconButton>
          <Grid item xs={6} md={6}>
            <div className={classes.floatLeft}>
              <Typography className={classes.title} variant="h6" noWrap>
                <Tooltip title="Dashboard" aria-label="reload">
                  <Typography variant="subtitle1" onClick={e => { e.preventDefault(); history.push('/') }} className={classes.typography}>{auth.companies.find(i => i.cid === auth.cid)?.name} - Entregas</Typography>
                </Tooltip>
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6} md={6}>
            <RightNavbarComponent />
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        open={drawer}
        onMouseEnter={e => { e.preventDefault(); setDrawer(true) }}
        onMouseLeave={e => { e.preventDefault(); setDrawer(false) }}
        onClick={e => { e.preventDefault(); setDrawer(false) }}
        {...drawerProps[isMobile ? 'mobile' : 'desktop']}>
        <LeftNavbarComponent open={drawer} handleDrawer={handleDrawer} />
      </Drawer>
    </div >
  );
}
