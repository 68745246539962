export function isEmptyString(str) {
  return str === undefined || str === null || str === "";
}

export function removeSpecialChars(str) {
  if (isEmptyString(str))
    return "";
  return str.normalize("NFD").replace(/\p{Diacritic}/gu, "").trim();
}

export function removeSpecialCharsLowerCase(str) {
  if (isEmptyString(str))
    return "";
  return removeSpecialChars(str).toLowerCase();
}
