import React from "react";
import { MenuItem } from "@material-ui/core";
import { TextInputFieldComponent } from "./";


export function SelectionInputFieldComponent(props) {
  const { options, nullable, acceptedAll } = props;
  const [localOptions, setLocalOptions] = React.useState(options);

  React.useEffect(() => {
    let opts = options;

    if (acceptedAll) {
      opts = [{ label: "all", value: "Todos" }, ...opts];
    }

    if (nullable) {
      opts = [{ label: "", value: "Nenhuma opção" }, ...opts];
    }

    setLocalOptions(opts);
  }, [acceptedAll, nullable, options]);


  return (
    <TextInputFieldComponent
      select
      {...props}
    >
      {localOptions.map((option) => (
        <MenuItem key={option.label} value={option.label}>
          {option.value}
        </MenuItem>))}
    </TextInputFieldComponent>
  );
}
