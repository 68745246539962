import React from "react";
import { Send } from "@material-ui/icons";
import { SaveButton } from "./saveButton.component";

export function SendSmsButton(props) {
  const { onClick, disabled } = props;

  return (
    <SaveButton
      onClick={onClick}
      startIcon={<Send />}
      disabled={disabled}
      {...props}
    >
      {props.children || "Enviar Sms"}
    </SaveButton>
  );
}
