
import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  set_user: ['id', 'name', 'username', 'email_address', 'phone_number', 'role', 'parent_id', "password", "passwordConfirmation", 'updated_at', 'created_at'],
  change_user: ['id', 'name', 'username', 'email_address', 'phone_number', 'role', 'parent_id', "password", "passwordConfirmation", 'updated_at', 'created_at'],
  clear_user: ['id', 'name', 'username', 'email_address', 'phone_number', 'role', 'parent_id', "password", "passwordConfirmation", 'updated_at', 'created_at']
})

const INITIAL_STATE = {
  id: "",
  name: "",
  username: "",
  email_address: "",
  phone_number: "",
  role: "",
  parent_id: "",
  password: "",
  passwordConfirmation: "",
  updated_at: "",
  created_at: "",
}

const set_user = (state = INITIAL_STATE, action) => {
  return action.value
}

const change_user = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.value
  }
}

const clear_user = (state = INITIAL_STATE, action) => {
  return INITIAL_STATE
}

export default createReducer(INITIAL_STATE,
  {
    [Types.SET_USER]: set_user,
    [Types.CHANGE_USER]: change_user,
    [Types.CLEAR_USER]: clear_user,
  })
