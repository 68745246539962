import { green, red, yellow } from "@material-ui/core/colors";
import { Constants } from "../helpers";
import Entity from "./entity.model";

const SUPPORTED_ATTRS = [
  "id",
  "brand",
  "model",
  "plate",
  "color",
  "year",
  "category",
  "status",
  "driver",
  "driverId",
  "height",
  "width",
  "depth",
  "capacity",
  "lastReview",
  "nextReview",
  "regions",
  "createdAt",
  "updatedAt",
];

export class VehicleModel extends Entity {
  constructor(attrs = {}) {
    super(attrs, SUPPORTED_ATTRS);
  }

  get driverId() {
    return this.driver_id;
  }

  get driverName() {
    return this.driver ? this.driver.name : null;
  }

  get rotationDay() {
    switch (this.plate.slice(-1)) {
      case '0':
      case '1':
        return 1
      case '2':
      case '3':
        return 2
      case '4':
      case '5':
        return 3
      case '6':
      case '7':
        return 4
      case '8':
      case '9':
        return 5
      default:
        return ""
    }
  }

  get rotationDayName() {
    return ["", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quita-feira", "Sexta-feira", ""][this.rotationDay]
  }

  get rotationDayColor() {
    const weekDay = new Date().getDay()
    if (this.rotationDay === weekDay)
      return red[600]
    else if (this.rotationDay === weekDay - 1)
      return yellow[900]

    return green[600]
  }

  get statusName() {
    var item = Constants.Vehicle.Statuses.find(status => status.label === this.status)
    return item ? item.value : this.status;
  }

  get statusColor() {
    if (this.status === Constants.Vehicle.Status.Unavailable)
      return red[600]
    return green[600]
  }

  get categoryName() {
    let cat = Constants.Vehicle.Categories.find(categoryItem => categoryItem.label === this.category)
    return cat && cat.value
  }

  get brandName() {
    let brand = Constants.Vehicle.Brands.find(brandItem => brandItem.label === this.brand)
    return brand && brand.value
  }

  get modelName() {
    let model = Constants.Vehicle.Models.find(modelItem => modelItem.label === this.model)
    return model && model.value
  }

  toJSON() {
    return {
      id: this.id,
      brand: this.brand,
      model: this.model,
      plate: this.plate,
      color: this.color,
      year: this.year,
      category: this.category,
      status: this.status,
      driver_id: this.driverId,
      height: this.height,
      width: this.width,
      depth: this.depth,
      capacity: this.capacity,
      last_review: this.lastReview,
      next_review: this.nextReview,
      region_ids: this.regions?.map(i => i.id)
    };
  }

  toState() {
    return super.toState({
      driverId: this.driverId,
    });
  }
}
