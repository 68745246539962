import React from "react";
import { Table, TableRow, TableBody, TableCell, TableHead, Typography, Card, CardActions, CardContent } from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx";

import {
  DeleteIconButton,
  HistoryIconButton,
  IconButton,
} from "../../../../shared/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '400px',
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '500px',
    }
  },
  title: {
    fontSize: 14,
  },
  card: {
    maxWidth: '100%',
  },
  cardDelete: {
    backgroundColor: red[900],
    color: 'white',
  },
  buttonPlus: {
    color: green[500],
  },
  buttonMinus: {
    color: red[500],
  },
  buttonDelete: {
    color: 'white',
    '&:hover': {
      color: 'white',
    }
  },
  noWrap: {
    flexWrap: 'nowrap',
  },
  inputSize: {
    maxWidth: '70px',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export function OrderItemSeparationComponent({ orderItemSeparations = [], handleChangeModel = () => { } }) {
  const classes = useStyles();
  const intervalRef = React.useRef(null);

  const handleChangeOrderItemSeparations = (orderItemSeparations) => {
    handleChangeModel(orderItemSeparations);
  }

  const changeSeparatedAmount = (index, value) => () => {
    const newOrderItemSeparationList = [...orderItemSeparations];
    delete newOrderItemSeparationList[index]._destroy;
    if (newOrderItemSeparationList[index].separatedAmount + value < 0 || newOrderItemSeparationList[index].separatedAmount + value > newOrderItemSeparationList[index].amount) return;
    newOrderItemSeparationList[index].separatedAmount = newOrderItemSeparationList[index].separatedAmount + value;
    handleChangeOrderItemSeparations(newOrderItemSeparationList);
  }

  const rollbackOrderItemSeparation = (index) => {
    const newOrderItemSeparationList = [...orderItemSeparations];
    delete newOrderItemSeparationList[index]._destroy;
    handleChangeOrderItemSeparations(newOrderItemSeparationList);
  }


  const removeOrderItemSeparation = (index) => {
    const newOrderItemSeparationList = [...orderItemSeparations];
    newOrderItemSeparationList[index].id ? newOrderItemSeparationList[index]._destroy = true : newOrderItemSeparationList.splice(index, 1);
    handleChangeOrderItemSeparations(newOrderItemSeparationList);
  }

  const TCardContent = ({ orderItemSeparation, index }) =>
    <CardContent>
      <Typography gutterBottom variant="subtitle2" component="h2">
        <b>Descrição</b> {orderItemSeparation?.orderItem?.description}
      </Typography>
      <Typography gutterBottom variant="subtitle2" component="h2">
        <b>EAN</b> {orderItemSeparation?.orderItem?.ean}
      </Typography>
      <Typography gutterBottom variant="subtitle1" component="h2">
        <b>separado</b> {orderItemSeparation?.separatedAmount || 0} de {orderItemSeparation?.amount} {orderItemSeparation?.orderItem?.unit}
      </Typography>
    </CardContent>

  const startCounter = (index, value) => () => {
    if (intervalRef.current) return;
    intervalRef.current = setInterval(() => {
      changeSeparatedAmount(index, value)();
    }, 100);
  };

  const stopCounter = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  return (
    <Table className={classes.root}>
      <TableHead>
        <TableRow>
          <TableCell><Typography variant="body1" gutterBottom>Itens</Typography></TableCell>
          <TableCell colSpan={2} align="right"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {orderItemSeparations.map((orderItemSeparation, index) => (
          <TableRow key={index}>
            <TableCell colSpan={3}>
              <Card className={clsx({
                [classes.card]: true,
                [classes.cardDelete]: orderItemSeparation?._destroy
              })}>
                <TCardContent index={index} orderItemSeparation={orderItemSeparation} />
                <CardActions className={classes.actions}>
                  <IconButton
                    onMouseDown={startCounter(index, 1)}
                    onMouseUp={stopCounter}
                    onMouseLeave={stopCounter}
                    onTouchStart={startCounter(index, 1)}
                    onTouchEnd={stopCounter}
                    onClick={changeSeparatedAmount(index, 1)}
                    className={classes.buttonPlus}>
                    <FontAwesomeIcon icon={faPlus} />
                  </IconButton>
                  <IconButton
                    onMouseDown={startCounter(index, -1)}
                    onMouseUp={stopCounter}
                    onMouseLeave={stopCounter}
                    onTouchStart={startCounter(index, -1)}
                    onTouchEnd={stopCounter}
                    onClick={changeSeparatedAmount(index, -1)}
                    className={classes.buttonMinus}>
                    <FontAwesomeIcon icon={faMinus} />
                  </IconButton>

                  {orderItemSeparation?._destroy ?
                    <HistoryIconButton onClick={() => rollbackOrderItemSeparation(index)} ></HistoryIconButton>
                    :
                    <DeleteIconButton className={classes.buttonDelete} onClick={() => removeOrderItemSeparation(index)} ></DeleteIconButton>
                  }
                  {orderItemSeparation?._destroy && <Typography variant="body2" className={classes.noWrap}>  Marcado para deleção</Typography>}
                </CardActions>
              </Card>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table >

  );
}
