import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Tooltip, Typography } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";

import { IconButton } from "../../../shared/components";
import { CalendarsRepository } from "../../../shared/repositories";
import { DashboardChartComponent } from "./dashboard_chart.component";
import { DashboardNextDaysComponent } from "./dashboard_next_days.component";

const useStyles = makeStyles(theme => ({
  fixedHeightPaper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'row',
    flexWrap: 'wrap',
    minHeight: '610px',
    // maxHeight: 400,
  },
  graphPaper: {
    justifyContent: "center",
  }
}));

const today = new Date();

export function DashboardInformativeComponent(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const calendarsRepository = new CalendarsRepository();
  const [monthYear, setMonthYear] = useState({ month: today.getMonth() + 1, year: today.getFullYear() })

  useEffect(() => {
    setLoading(true)
    calendarsRepository.getAll(monthYear.month, monthYear.year)
      .catch(error => {
        console.log(error);
      }).finally(() => {
        setLoading(false);
      });
  }, [monthYear]);

  const addMonth = () => {
    if (monthYear.month === 12)
      setMonthYear({ year: monthYear.year + 1, month: 1 })
    else
      setMonthYear({ ...monthYear, month: monthYear.month + 1 })
  }

  const removeMonth = () => {
    if (monthYear.month === 1)
      setMonthYear({ year: monthYear.year - 1, month: 12 })
    else
      setMonthYear({ ...monthYear, month: monthYear.month - 1 })
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Paper className={classes.fixedHeightPaper}>
          <DashboardChartComponent loading={loading} />
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper className={classes.fixedHeightPaper}>
          <Grid container display='flex' justifyContent="space-between">
            <Grid item>
              <Typography component="h2" variant="h6" color="primary" gutterBottom style={{ whiteSpace: 'nowrap' }}>
                Próximos dias
              </Typography>
            </Grid>
            <Grid item style={{ display: 'flex' }}>
              <IconButton onClick={() => { removeMonth() }} style={{ marginRight: '5px' }}><Tooltip title='Mês Anterior'><ChevronLeft /></Tooltip></IconButton>
              <Typography component="h2" variant="h6" color="primary" gutterBottom style={{ whiteSpace: 'nowrap' }}>
                {("0" + monthYear.month).slice(-2)}/{monthYear.year}
              </Typography>
              <IconButton onClick={() => { addMonth() }} style={{ marginLeft: '5px' }}><Tooltip title='Proximo Mês'><ChevronRight /></Tooltip></IconButton>
            </Grid>
          </Grid>
          <DashboardNextDaysComponent loading={loading} />
        </Paper>
      </Grid>
    </Grid>
  )
}
