
import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  set_companies: [],
  create_companies_item: [],
  update_companies_item: [],
  clear_companies: [],
  delete_company: ['value'],
})

const INITIAL_STATE = []

const set_companies = (state = INITIAL_STATE, action) => {
  return action.value
}

const update_companies_item = (state = INITIAL_STATE, action) => {
  return state.map(company => {
    if (company.id === action.value.id) {
      return action.value
    }
    return company
  })
}

const create_companies_item = (state = INITIAL_STATE, action) => {
  return [action.value, ...state]
}

const clear_companies = (state = INITIAL_STATE, action) => {
  return INITIAL_STATE
}

const delete_company = (state = INITIAL_STATE, action) => {
  return state.filter(company => company.id !== action.value)
}

export default createReducer(INITIAL_STATE,
  {
    [Types.SET_COMPANIES]: set_companies,
    [Types.CREATE_COMPANIES_ITEM]: create_companies_item,
    [Types.UPDATE_COMPANIES_ITEM]: update_companies_item,
    [Types.CLEAR_COMPANIES]: clear_companies,
    [Types.DELETE_COMPANY]: delete_company
  })
