
import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  set_client: ["id", "first_name", "last_name", "full_name", "document", "status", "updated_at", "created_at",],
  change_client: ["id", "first_name", "last_name", "full_name", "document", "status", "updated_at", "created_at",],
  clear_client: ["id", "first_name", "last_name", "full_name", "document", "status", "updated_at", "created_at",],
})

const INITIAL_STATE = {
  id: "",
  first_name: "",
  last_name: "",
  full_name: "",
  document: "",
  status: "",
  updated_at: "",
  created_at: "",
}

const set_client = (state = INITIAL_STATE, action) => {
  return action.value
}

const change_client = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.value
  }
}

const clear_client = (state = INITIAL_STATE, action) => {
  return INITIAL_STATE
}

export default createReducer(INITIAL_STATE,
  {
    [Types.SET_CLIENT]: set_client,
    [Types.CHANGE_CLIENT]: change_client,
    [Types.CLEAR_CLIENT]: clear_client,
  })
