/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Paper, Table, TableBody, TableHead } from "@material-ui/core";
import { CheckCircleOutline } from "@material-ui/icons";
import { green } from "@material-ui/core/colors";
import Swal from "sweetalert2";

import {
  TableHeadComponent,
  TableBodyComponent,
  TableSearchComponent
} from "./components";
import { SendSmsButton } from "../../../../shared/components";
import { DeliveriesRepository } from "../../../../shared/repositories";
import { authorizeChecker } from "../../../../shared/helpers";
import { AdminLayout } from "../../../../shared/layouts/admin.layout";

const INITIAL_FILTERS = {
  clientName: "",
  driverName: "",
  legacyOrderId: "",
  status: "all"
};

const useStyles = makeStyles(theme => ({
  title: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    whiteSpace: "nowrap",
  },
  paper: {
    padding: theme.spacing(2),
    // display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  tableRow: {
    backgroundColor: "#F5F5F5",
  },
  tableCell: {
    whiteSpace: "nowrap",
  },
}));

export function DashboardDeliveriesTableComponent(props) {
  const classes = useStyles();
  const { auth } = useSelector(state => state);
  const [loading, setLoading] = React.useState(false);
  const [deliveries, setDeliveries] = React.useState([]);
  const [filters, setFilters] = React.useState(INITIAL_FILTERS);
  const [date, setDate] = React.useState(null);
  const deliveriesRepository = new DeliveriesRepository();

  React.useEffect(() => {
    deliveriesRepository.toBeDeliveredToday({ perPage: 1000, orderBy: "status", orderDirection: "asc" })
      .then((result) => {
        setDeliveries(result.data);
      });
  }, []);

  const sendSms = () => {
    Swal.fire({
      title: 'Confirmar?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Enviar mesmo assim',
      denyButtonText: 'Cancelar',
      html: `Ao enviar o SMS, todos os clientes das entregas de hoje serão notificados.`,
    }).then((result) => {
      if (result.isConfirmed) {
        return deliveriesRepository.sendSmsWillBeDeliveredToday().then(() => { setDate(new Date()); });
      }
    })
  }

  const disabled = date || !deliveries?.filter(i => i.smsWillBeDeliveredTodayAt === null).length;

  return (
    <AdminLayout
      HeaderComponent={
        <Paper className={classes.paper}>
          <TableSearchComponent setFilters={setFilters} setLoading={setLoading} INITIAL_FILTERS={INITIAL_FILTERS} />
        </Paper>
      }
      BodyComponent={
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography component="h2" variant="h6" className={classes.title} gutterBottom>Resumo</Typography>
            </Grid>
            <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: "flex-end" }}>
              {
                authorizeChecker(auth) &&
                <SendSmsButton onClick={sendSms} disabled={disabled} >
                  Enviar SMS {disabled && <CheckCircleOutline style={{ color: green[600] }} />}
                </SendSmsButton>
              }
            </Grid>
          </Grid>
          <Table className={classes.table}>
            <TableHead>
              <TableHeadComponent />
            </TableHead>
            <TableBody>
              <TableBodyComponent deliveries={deliveries} filters={filters} loading={loading} fakeDate={date && date.toLocaleString('pt-br')} />
            </TableBody>
          </Table >
        </Paper>
      }
    />
  );
}
