import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, List, ListItem, Paper } from "@material-ui/core";

import {
  TextInputFieldComponent,
  BackButton,
  SaveButton
} from "../../shared/components";
import { MeRepository } from "../../shared/repositories";
import { AdminLayout } from "../../shared/layouts/admin.layout";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '400px',
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '500px',
    }
  },
  gridItem: {
  },
  textField: {
    paddingRight: theme.spacing(1),
  },
  textFieldLabel: {
    color: "black",
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    minHeight: 380,
    // maxHeight: 400,
  },
}));

export function MePasswordPage() {
  const history = useHistory();
  const classes = useStyles();
  const [me, setMe] = React.useState({
    oldPassword: '',
    newPassword: '',
    newPasswordConfirmation: '',
  });
  const meRepository = new MeRepository();

  const saveMe = _ => {
    return meRepository.updatePassword({
      old_password: me.oldPassword,
      password: me.newPassword,
      password_confirmation: me.newPasswordConfirmation,
    })
  }

  const handleChange = name => event => {
    setMe({ ...me, [name]: event.target.value });
  };

  const goBack = () => {
    history.goBack();
  }

  return (
    <AdminLayout
      BodyComponent={
        <Grid container spacing={3} className={classes.root}>
          <Grid item xs={12} md={12}>
            <Grid className={classes.root}>
              <Paper elevation={3} className={classes.paper}>
                <List>
                  <ListItem className={classes.gridItem}>
                    <Typography variant="h6" gutterBottom>Atualizar senha</Typography>
                  </ListItem >
                  <ListItem className={classes.gridItem}>
                    <TextInputFieldComponent
                      id="oldPassword"
                      label="Senha Atual"
                      value={me.oldPassword}
                      onChange={handleChange('oldPassword')}
                    />
                  </ListItem>
                  <ListItem className={classes.gridItem}>
                    <TextInputFieldComponent
                      id="newPassword"
                      label="Nova Senha"
                      value={me.newPassword}
                      onChange={handleChange('newPassword')}
                    />
                  </ListItem>
                  <ListItem className={classes.gridItem}>
                    <TextInputFieldComponent
                      id="newPasswordConfirmation"
                      label="Confirmar Nova Senha"
                      value={me.newPasswordConfirmation}
                      onChange={handleChange('newPasswordConfirmation')}
                    />
                  </ListItem>
                  <ListItem className={classes.gridItem} style={{ display: "flex", justifyContent: "space-between" }}>
                    <BackButton onClick={goBack} />
                    <SaveButton onClick={saveMe} />
                  </ListItem >
                </List >
              </Paper>
            </Grid >
          </Grid >
        </Grid >
      }
    />
  );

}

