/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { TableRow, TableCell } from "@material-ui/core";

import { TableRowLoadingComponent } from "../../../../../shared/components";
import { TableStatusSteppersComponent } from "./table_status_steppers.component";
import { isEmptyString, removeSpecialCharsLowerCase } from "../../../../../shared/helpers";


const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    // maxHeight: 500
  },
  tableRow: {
    backgroundColor: "#F5F5F5",
  },
  tableCell: {
    whiteSpace: "nowrap",
  },
  rowSize: {
    [theme.breakpoints.down('sm')]: {
      width: "100px"
    },
    [theme.breakpoints.up('sm')]: {
      width: "150px"
    },
    [theme.breakpoints.up('md')]: {
      width: "200px"
    },
    [theme.breakpoints.up('lg')]: {
      width: "300px"
    },
  }
}));

export function TableBodyComponent({ deliveries = [], filters, loading, fakeDate }) {
  const classes = useStyles();
  const [filteredDeliveries, setFilteredDeliveries] = React.useState([]);

  React.useEffect(() => {
    setFilteredDeliveries(deliveries.map(delivery => delivery.toState()));
  }, [deliveries]);

  React.useEffect(() => {
    filter()
  }, [filters]);

  const filter = (actualStatus = filters.status) => {
    setFilteredDeliveries(deliveries.filter(delivery => (isEmptyString(filters.clientName) || removeSpecialCharsLowerCase(delivery.clientName).includes(removeSpecialCharsLowerCase(filters.clientName))) &&
      (isEmptyString(filters.driverName) || removeSpecialCharsLowerCase(delivery.driverName).includes(removeSpecialCharsLowerCase(filters.driverName))) &&
      (isEmptyString(filters.legacyOrderId) || removeSpecialCharsLowerCase(delivery.legacyOrderId).includes(removeSpecialCharsLowerCase(filters.legacyOrderId))) &&
      (actualStatus === "all" || delivery.status === actualStatus)
    ));
  }

  return <>
    {loading ?
      <TableRowLoadingComponent cols={5} buttons={0} /> :
      filteredDeliveries.map(row => (
        <>
          <TableRow key={row.legacyOrderId || new Date().toISOString()} className={classes.tableRow}>
            <TableCell align="center"><b>{row.legacyOrderId}</b></TableCell>
            <TableCell align="center">{row.clientName}</TableCell>
            <TableCell align="center">{row.driverName}</TableCell>
            <TableCell align="center">{row.smsWillBeDeliveredTodayAtFormatted || fakeDate}</TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
          <TableRow key={`${row.legacyOrderId}-status`} >
            <TableCell align="center" colSpan="5">
              <TableStatusSteppersComponent delivery={row} statuses />
            </TableCell>
          </TableRow>
        </>
      ))}
    {
      !filteredDeliveries[0] ? <TableRow key="none" className={classes.tableRow}>
        <TableCell component="th" scope="row" colSpan="5" align="center"><b>Não há entregas previstas para hoje</b></TableCell>
      </TableRow> : null
    }
  </>
}
