import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, List, ListItem, MenuItem } from "@material-ui/core";


import {
  DateInputFieldComponent,
  TextInputFieldComponent,
  BackButton,
  SaveButton
} from "../../../shared/components";
import { RestrictedDatesAutoCompleteRegion } from "./restricted_dates_auto_complete_region.component";
import { RestrictedDatesRepository } from "../../../shared/repositories";
import { RestrictedDateModel } from "../../../shared/models";
import { Constants } from "../../../shared/helpers";
import { useDrawerRightContext } from "../../../shared/contexts/drawer_right.context";

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '400px',
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '500px',
    }
  },
  dateField: {
    paddingRight: theme.spacing(3),
  },
}));

export function RestrictedDatesDetailComponent({ update }) {
  const classes = useStyles();
  const [restrictedDate, setRestrictedDate] = useState({});
  const restrictedDatesRepository = new RestrictedDatesRepository();
  const { drawerValue, toggleDrawerRightOpen } = useDrawerRightContext();

  const saveRestrictedDate = _ => {
    const restrictedDateModel = new RestrictedDateModel(restrictedDate);
    return restrictedDatesRepository[restrictedDateModel.id ? "update" : "create"](restrictedDateModel.toJSON()).then(data => {
      const restrictedDateResp = RestrictedDateModel.fromJSON(data);
      update(restrictedDateResp)
      setRestrictedDate(restrictedDateResp.toState())
    })
  }

  useEffect(() => {
    setRestrictedDate(drawerValue)
  }, [drawerValue])

  const handleChange = name => event => {
    setRestrictedDate({ ...restrictedDate, [name]: event.target.value });
  };


  const handleDateChange = name => event => {
    if (event)
      setRestrictedDate({ ...restrictedDate, [name]: event._d });
  };

  const handleChangeRegion = options => {
    setRestrictedDate({ ...restrictedDate, regions: options });
  };

  const goBack = () => {
    toggleDrawerRightOpen()
  }

  return (
    <Grid className={classes.root}>
      <List>
        <ListItem>
          <Typography variant="h6" gutterBottom>Dados da Data Restrita</Typography>
        </ListItem>
        <ListItem>
          <TextInputFieldComponent
            id="reason-select"
            label="Motivo"
            value={restrictedDate.reason}
            onChange={handleChange('reason')}
          />
        </ListItem>
        <ListItem className={classes.dateField}>
          <DateInputFieldComponent
            id="startDate"
            label="Data inicio"
            value={restrictedDate.startDate}
            error={false}
            className={classes.textField}
            helperText={null}
            onChange={handleDateChange("startDate")}
          />
        </ListItem>
        <ListItem className={classes.dateField}>
          <DateInputFieldComponent
            id="endDate"
            label="Data fim"
            value={restrictedDate.endDate}
            error={false}
            className={classes.textField}
            helperText={null}
            onChange={handleDateChange("endDate")}
          />
        </ListItem>
        <ListItem>
          <TextInputFieldComponent
            id="status"
            select
            label="Status"
            value={restrictedDate.status}
            onChange={handleChange("status")}>
            {Constants.RestrictedDate.Statuses.filter(status => Object.values(Constants.RestrictedDate.Status).includes(status.label)).map((option) => (
              <MenuItem key={option.label} value={option.label}>
                {option.value}
              </MenuItem>
            ))}
          </TextInputFieldComponent>
        </ListItem>
        <ListItem>
          <TextInputFieldComponent
            id="description"
            label="Observações"
            value={restrictedDate.description}
            onChange={handleChange('description')}
          />
        </ListItem>
        <ListItem>
          <RestrictedDatesAutoCompleteRegion restrictedDate={restrictedDate} onChange={handleChangeRegion} />
        </ListItem>
        <ListItem style={{ display: "flex", justifyContent: "space-between" }}>
          <BackButton onClick={goBack} />
          <SaveButton onClick={saveRestrictedDate} />
        </ListItem>
      </List >
    </Grid >
  );

}

