import React, { useState } from "react";
import { Table, TableRow, TableBody, TableCell, TableHead, TableContainer, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Swal from "sweetalert2";

import {
  DeleteIconButton,
  EditIconButton,
  TablePaginationComponent,
  TableRowLoadingComponent
} from "../../../shared/components";
import { SeparationsRepository } from "../../../shared/repositories";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faSortAsc, faSortDesc } from "@fortawesome/free-solid-svg-icons";
import { useDrawerRightContext } from "../../../shared/contexts/drawer_right.context";

const useStyles = makeStyles(theme => ({
  noWrap: {
    overflow: 'hidden',
    whiteSpace: "nowrap",
    maxWidth: '100px',
    textOverflow: 'ellipsis'
  },
  tableBody: {
    overflowX: "auto",
    maxHeight: 500
  },
  tableCell: {
    whiteSpace: "nowrap",
  },
}));

export function SeparationsListComponent({ filters, setFilters = () => { }, loading, separations = [], setSeparations = () => { }, setLoading = () => { } }) {
  const classes = useStyles();
  const separationsRepository = new SeparationsRepository();
  const [pagination, setPagination] = React.useState({ page: 1, perPage: 25, total: 0 });
  const { setNewDrawerValue } = useDrawerRightContext();

  const viewMore = separation => e => {
    e.preventDefault();
    setNewDrawerValue(separation.toState())
  }

  React.useEffect(() => {
    setLoading(true)
    const timer = setTimeout(() => {
      getSeparations()
    }, 1000);
    return () => clearTimeout(timer);
  }, [filters]);

  const getSeparations = () => {
    separationsRepository.getAll({
      queryParams: resolveFilters(),
      page: filters.page,
      perPage: filters.perPage,
      orderBy: filters.orderBy,
      orderDirection: filters.orderDirection
    })
      .then(result => {
        setSeparations(result.data)
        setPagination({ ...pagination, total: result.total })
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => setLoading(false))
  }

  const resolveFilters = () => {
    let query = [];

    if (filters.legacyOrderId) {
      query.push(`legacy_order_id=${filters.legacyOrderId}`)
    }

    if (filters.startedAt) {
      query.push(`started_at=${filters.startedAt.toLocaleDateString("pt-BR")}`)
    }

    if (filters.finishedAt) {
      query.push(`finished_at=${filters.finishedAt.toLocaleDateString("pt-BR")}`)
    }

    if (filters.orderDate) {
      query.push(`order_date=${filters.orderDate.toLocaleDateString("pt-BR")}`)
    }

    return query.join("|");
  }

  const deleteRow = separation => e => {
    return Swal.fire({
      title: 'Confirmar?',
      showDenyButton: true,
      showCancelButton: true,
      icon: 'warning',
      confirmButtonText: 'Deletar',
      denyButtonText: 'Cancelar',
      confirmButtonColor: "#DD6B55",
      html: `<b>Você não poderá reverter esta ação!</b>`,
    }).then((result) => {
      if (result.isConfirmed)
        return separationsRepository.delete(separation.id)
      else
        return Promise.reject('')
    })
  }

  const orderBy = (orderBy) => e => {
    setFilters({ ...filters, orderBy: orderBy, orderDirection: filters.orderDirection === 'asc' ? 'desc' : 'asc' })
  }

  const SortIcon = ({ orderBy }) => {
    if (filters.orderBy === orderBy) {
      if (filters.orderDirection === 'asc') {
        return <FontAwesomeIcon icon={faSortAsc} />
      } else {
        return <FontAwesomeIcon icon={faSortDesc} />
      }
    } else {
      return <FontAwesomeIcon icon={faSort} />
    }
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="right" colSpan={9}>{separations.length} itens</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCell}></TableCell>
            <TableCell>Pedido</TableCell>
            <TableCell>Setor</TableCell>
            <TableCell>Data do Pedido</TableCell>
            <TableCell>Quantidade</TableCell>
            <TableCell onClick={orderBy('started_at')} className={classes.tableCell}>Iniciado em <SortIcon orderBy="started_at" /></TableCell>
            <TableCell onClick={orderBy('finished_at')} className={classes.tableCell}>Finalizado em <SortIcon orderBy="finished_at" /></TableCell>
            <TableCell>Duração</TableCell>
            <TableCell>Criado em</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {loading ?
            <TableRowLoadingComponent cols={9} buttons={2} />
            : separations.map((row, index) => (
              <TableRow key={index} className={classes.tableRow}>
                <TableCell className={classes.tableCell}>
                  <DeleteIconButton onClick={deleteRow(row)} />
                  <EditIconButton onClick={viewMore(row)} />
                </TableCell>
                <TableCell className={classes.tableCell}>{row.order.legacyOrderId}</TableCell>
                <TableCell className={classes.tableCell}>{row.sector?.legacySectorId} - {row.sector?.name}</TableCell>
                <TableCell className={classes.tableCell}>{row.order.createdAtFormatted}</TableCell>
                <TableCell className={classes.tableCell}>{row.totalAmount}</TableCell>
                <TableCell className={classes.tableCell}>{row.startedAtFormatted}</TableCell>
                <TableCell className={classes.tableCell}>{row.finishedAtFormatted}</TableCell>
                <TableCell className={classes.tableCell}>{row.durationFormatted}</TableCell>
                <TableCell className={classes.tableCell}>{row.createdAtFormatted}</TableCell>
              </TableRow>
            ))}
          {!loading && !separations[0] ? <TableRow key="none" className={classes.tableRow}>
            <TableCell align="center" component="th" scope="row" colSpan="9" style={{ height: "300px" }}>
              <b>Não há dados para exibir</b>
            </TableCell>
          </TableRow> : null}
        </TableBody>
        <TablePaginationComponent
          colSpan={9}
          count={pagination.total}
          rowsPerPage={pagination.perPage}
          page={pagination.page}
          loading={loading}
          onPageChange={value => { setPagination({ ...pagination, page: value }) }}
          onRowsPerPageChange={value => { setPagination({ ...pagination, page: 1, perPage: value }) }}
        />
      </Table>
    </TableContainer >)
}
