import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, List, ListItem, Paper } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import {
  BackButton,
  SaveButton,
  TextInputFieldComponent,
  NumberPhoneInputFieldComponent
} from "../../../shared/components";
import { MeRepository } from "../../../shared/repositories";


const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '400px',
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '500px',
    }
  },
  gridItem: {
  },
  textField: {
    paddingRight: theme.spacing(1),
  },
  textFieldLabel: {
    color: "black",
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    minHeight: 380,
    // maxHeight: 400,
  },
}));

export function MeDetailComponent() {
  const classes = useStyles();
  const history = useHistory();
  const [me, setMe] = React.useState({});
  const meRepository = new MeRepository();

  React.useEffect(() => {
    meRepository.get().then(data => {
      setMe(data);
    })
  }, [])

  const saveMe = _ => {
    return meRepository.update(me)
  }

  const handleChange = name => event => {
    setMe({ ...me, [name]: event.target.value });
  };

  const goBack = () => {
    history.push("/");
  }

  return (
    <Grid className={classes.root}>
      <Paper elevation={3} className={classes.paper}>
        <List>
          <ListItem className={classes.gridItem}>
            <Typography variant="h6" gutterBottom>Meus Dados</Typography>
          </ListItem >
          <ListItem className={classes.gridItem}>
            <TextInputFieldComponent
              id="username"
              label="Username"
              disabled
              value={me.username}
            />
          </ListItem>
          <ListItem className={classes.gridItem}>
            <TextInputFieldComponent
              id="name-select"
              label="Nome"
              value={me.name}
              onChange={handleChange('name')}
            />
          </ListItem >
          <ListItem className={classes.gridItem}>
            <TextInputFieldComponent
              id="emailAddress-select"
              label="Nome"
              value={me.emailAddress}
              onChange={handleChange('emailAddress')}
            />
          </ListItem >
          <ListItem className={classes.gridItem}>
            <NumberPhoneInputFieldComponent
              value={me.phoneNumber || ""}
              onChange={handleChange('phoneNumber')}
            />
          </ListItem >
          <ListItem className={classes.gridItem}>
            <Typography variant="body1" gutterBottom>Datas</Typography>
          </ListItem >
          <ListItem className={classes.gridItem}>
            <TextInputFieldComponent
              id="created_at-select"
              label="Criado em"
              disabled
              value={me.created_at ? new Date(me.created_at).toLocaleString() : ""}
            />
          </ListItem >
          <ListItem className={classes.gridItem}>
            <TextInputFieldComponent
              id="updated_at-select"
              label="Atualizado em"
              disabled
              value={me.updated_at ? new Date(me.updated_at).toLocaleString() : ""}
            />
          </ListItem >
          <ListItem className={classes.gridItem} style={{ display: "flex", justifyContent: "space-between" }}>
            <BackButton onClick={goBack} />
            <SaveButton onClick={saveMe} />
          </ListItem >
        </List >
      </Paper>
    </Grid >
  );

}

