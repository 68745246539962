import React from "react";

import { AdminLayout } from "../../shared/layouts/admin.layout";

import {
  DashboardInformativeComponent,
} from "./components";

export function DashboardPage() {

  return (
    <AdminLayout
      BodyComponent={
        <DashboardInformativeComponent />
      }
    />
  );

}

