/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import {
  RestrictedDatesListComponent,
  RestrictedDatesSearchComponent,
  RestrictedDatesDetailComponent
} from "./components";
import { RestrictedDateModel } from "../../shared/models";
import { AdminLayout } from "../../shared/layouts/admin.layout";

const INITIAL_FILTER = {
  name: "",
  startDate: "",
  endDate: "",
  status: "all",
  regionId: "all",
  orderBy: "start_date",
  orderDirection: "asc"
}

export function RestrictedDatesPage() {
  const [filters, setFilters] = React.useState(INITIAL_FILTER);
  const [restrictedDates, setRestrictedDates] = React.useState(RestrictedDateModel.fromArray([]));
  const [loading, setLoading] = React.useState(false);

  const updateOrCreate = (restrictedDate) => {
    const index = restrictedDates.findIndex(item => item.id === restrictedDate.id)
    if (index > -1) {
      restrictedDates[index] = restrictedDate
    } else {
      restrictedDates.unshift(restrictedDate)
    }
    setRestrictedDates([...restrictedDates])
  }

  return (
    <AdminLayout
      DrawerRightComponent={<RestrictedDatesDetailComponent update={updateOrCreate} />}
      HeaderComponent={
        <RestrictedDatesSearchComponent
          setFilters={setFilters}
          INITIAL_FILTER={INITIAL_FILTER}
          setLoading={setLoading} />
      }
      BodyComponent={<RestrictedDatesListComponent
        filters={filters}
        setFilters={setFilters}
        restrictedDates={restrictedDates}
        setRestrictedDates={setRestrictedDates}
        loading={loading}
        setLoading={setLoading} />
      }
    />
  );
}

