import { Repository } from "./repository";

export class AuthRepository extends Repository {

  async signIn(cid, username, password) {
    return this.api.post({ path: "auth", body: { username, password }, headers: { cid } })
      .then(response => {
        this.newSnackbarSuccess("Login realizado com sucesso.");
        this.dispatch({ type: "AUTH_SIGN_IN", value: response.data });
        return Promise.resolve(response.data);
      })
      .catch(error => {
        setTimeout(() => {
          try {
            if (error.response.status === 401)
              this.newSnackbarError("Usuário ou senha inválidos.");
            else
              this.newSnackbarError(error.response.data.errors)
          } catch (err) {
            this.newSnackbarError("Usuário não pode ser processado.")
          } finally {
            this.newSnackbarError()
            return Promise.reject(error);
          }
        }, 1000);
      });
  }

  async signOut() {
    this.dispatch({ type: "AUTH_SIGN_OUT" });
    return this.api.delete({ path: "auth", headers: this.headers() })
      .then(response => {
        return response.data;
      })
  }
}
