import Entity from "./entity.model";

const SUPPORTED_ATTRS = [
  "id",
  "name",
  "beginningZipcode",
  "endZipcode",
  "maxWeightInDay",
  "createdAt",
  "updatedAt",
];

export class RegionModel extends Entity {
  constructor(attrs = {}) {
    super(attrs, SUPPORTED_ATTRS);
  }

  get maxWeightInDayFormatted() {
    return this.maxWeightInDay ? `${new Intl.NumberFormat("pt-BR", {
      style: "decimal",
      minimumFractionDigits: 0
    }).format(this.maxWeightInDay)} Kg` : null;
  }

  get maxWeightInDayUnformatted() {
    return this.maxWeightInDay ? this.maxWeightInDay.replaceAll('Kg', '').replaceAll('.', '').replaceAll(',', '.').trim() : null;
  }

  toJSON() {
    return {
      id: this.id,
      name: this.name,
      beginning_zipcode: this.beginningZipcode,
      end_zipcode: this.endZipcode,
      max_weight_in_day: this.maxWeightInDayUnformatted,
    };
  }

  toState() {
    return super.toState({
      maxWeightInDay: this.maxWeightInDayFormatted,
    });
  }
}
