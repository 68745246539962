import { Constants } from "../helpers";
import Entity from "./entity.model";
import { OrderModel } from "./order.model";
import { OrderItemSeparationModel } from "./orderItemSeparation.model";
import { SectorModel } from "./sector.model";

const SUPPORTED_ATTRS = [
  "id",
  "separator",
  "order",
  "orderItemSeparations",
  "sector",
  "reason",
  "startedAt",
  "finishedAt",
  "createdAt",
  "updatedAt",
];

export class SeparationModel extends Entity {
  constructor(attrs = {}) {
    super(attrs, SUPPORTED_ATTRS);
  }

  get resolvedReasonName() {
    var item = Constants.Separation.Reasons.find(reason => reason.label === this.reason)
    return item ? item.value : this.reason;
  }

  get startedAtFormatted() {
    return this.startedAt ? new Date(`${this.startedAt}`).toLocaleString() : null;
  }

  get finishedAtFormatted() {
    return this.finishedAt ? new Date(`${this.finishedAt}`).toLocaleString() : null;
  }

  get duration() {
    return this.finishedAt && this.startedAt ? Math.floor((new Date(this.finishedAt) - new Date(this.startedAt)) / 1000) : 0;
  }

  get durationFormatted() {
    if (!this.duration) return "";
    var duration = this.duration;
    var hours = Math.floor(duration / 3600);
    var minutes = Math.floor((duration - (hours * 3600)) / 60);
    var seconds = duration - (hours * 3600) - (minutes * 60);
    return `${hours}h ${minutes}m ${seconds}s`;
  }

  get totalAmount() {
    return this.orderItemSeparations.reduce((acc, curr) => acc + curr.amount, 0);
  }

  get createdAtFormatted() {
    return this.createdAt ? new Date(`${this.createdAt}`).toLocaleString() : null;
  }

  get orderCreatedAtFormatted() {
    return this.order.createdAt ? new Date(`${this.order.createdAt}`).toLocaleString() : null;
  }

  get legacyOrderId() {
    return this.order.legacyOrderId;
  }

  toJSON() {
    return {
      id: this.id,
      separator: this.separator,
      order_item_separations_attributes: this.orderItemSeparations.map(orderItemSeparation => new OrderItemSeparationModel(orderItemSeparation).toJSON()),
      sector_id: this.sector.id,
      reason: this.reason,
      started_at: this.startedAt,
      finished_at: this.finishedAt,
    };
  }

  static fromJSON(attrs) {
    return super.fromJSON({
      ...attrs,
      order: OrderModel.fromJSON(attrs.order),
      sector: SectorModel.fromJSON(attrs.sector),
      order_item_separations: attrs.order_item_separations.map(orderItemSeparation => OrderItemSeparationModel.fromJSON(orderItemSeparation)),
    });
  }

  toState() {
    return super.toState({
      ...this,
      sectorId: this.sector.id,
      totalAmount: this.totalAmount,
      separatorId: this.separator?.id,
      legacyOrderId: this.legacyOrderId,
      orderCreatedAt: this.orderCreatedAtFormatted,
      orderItemSeparations: this.orderItemSeparations.map(orderItemSeparation => orderItemSeparation.toState()),
      sector: this.sector.toState(),
      order: this.order.toState(),
    })
  }
}
