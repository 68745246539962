

import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  set_dashboard: ['deliveries_to_separate_today', 'deliveries_to_deliver_today', 'deliveries_to_check_today'],
  clear_dashboard: ['deliveries_to_separate_today', 'deliveries_to_deliver_today', 'deliveries_to_check_today'],
})

const INITIAL_STATE = {
  deliveries_to_separate_today: [],
  deliveries_to_deliver_today: [],
  deliveries_to_check_today: [],
}

const set_dashboard = (state = INITIAL_STATE, action) => {
  return action.value
}


const clear_dashboard = (state = INITIAL_STATE, action) => {
  return INITIAL_STATE
}

export default createReducer(INITIAL_STATE,
  {
    [Types.SET_DASHBOARD]: set_dashboard,
    [Types.CLEAR_DASHBOARD]: clear_dashboard,
  })
