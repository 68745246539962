import Entity from "./entity.model";

const SUPPORTED_ATTRS = [
  "id",
  "name",
  "legacySectorId",
  "description",
  "createdAt",
  "updatedAt",
];

export class SectorModel extends Entity {
  constructor(attrs = {}) {
    super(attrs, SUPPORTED_ATTRS);
  }

  toJSON() {
    return {
      id: this.id,
      name: this.name,
      legacy_sector_id: this.legacySectorId,
      description: this.description,
    };
  }
}
