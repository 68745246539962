import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table, TableRow, TableBody, TableCell, TableHead, TableContainer, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faSortAsc, faSortDesc } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

import {
  AddIconButton,
  DeleteIconButton,
  EditIconButton,
  TablePaginationComponent,
  TableRowLoadingComponent
} from "../../../shared/components";
import { VehiclesRepository } from "../../../shared/repositories";
import { authorizeChecker, removeSpecialCharsLowerCase, sortBy } from "../../../shared/helpers";
import { useDrawerRightContext } from "../../../shared/contexts/drawer_right.context";

const useStyles = makeStyles(theme => ({
  noWrap: {
    whiteSpace: "nowrap"
  },
  tableCell: {
    whiteSpace: "nowrap"
  },
  tableBody: {
    overflowX: "auto",
    maxHeight: 500
  },
}));

export function VehiclesListComponent({ loading, vehicles = [], filters = {}, setFilters = () => { }, setVehicles = () => { }, setLoading = () => { } }) {
  const auth = useSelector(state => state.auth);
  const classes = useStyles();
  const [pagination, setPagination] = useState({ page: 1, perPage: 10, total: 0 });
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const vehiclesRepository = new VehiclesRepository()
  const { setNewDrawerValue } = useDrawerRightContext();

  const viewMore = vehicle => e => {
    e.preventDefault();
    setNewDrawerValue(vehicle.toState())
  }

  const newItem = e => {
    e.preventDefault();
    setNewDrawerValue({})
  }

  useEffect(() => {
    setLoading(true);
    vehiclesRepository.getAll({ page: pagination.page, perPage: pagination.perPage, })
      .then(result => {
        setVehicles(result.data)
        setPagination({ ...pagination, total: result.total })
      })
      .finally(() => setLoading(false))
  }, [pagination.page, pagination.perPage]);

  useEffect(() => {
    filter()
  }, [filters, vehicles]);

  const filter = () => {
    setFilteredVehicles([])
    let resp = vehicles.filter(vehicle =>
      removeSpecialCharsLowerCase(vehicle.driver && vehicle.driver.name).includes(removeSpecialCharsLowerCase(filters.driverName)) &&
      removeSpecialCharsLowerCase(vehicle.plate).includes(removeSpecialCharsLowerCase(filters.plate)) &&
      (filters.brand === "all" || vehicle.brand === filters.brand) &&
      (filters.model === "all" || vehicle.model === filters.model) &&
      (filters.status === "all" || vehicle.status === filters.status) &&
      (filters.category === "all" || vehicle.category === filters.category)
    )
    setFilteredVehicles(order(resp))
  }

  const order = (vehicles) => {
    return sortBy(vehicles, filters.orderBy, filters.orderDirection)
  }

  const orderBy = (orderBy) => e => {
    setFilters({ ...filters, orderBy: orderBy, orderDirection: filters.orderDirection === 'asc' ? 'desc' : 'asc' })
  }

  const SortIcon = ({ orderBy }) => {
    if (filters.orderBy === orderBy) {
      if (filters.orderDirection === 'asc') {
        return <FontAwesomeIcon icon={faSortAsc} />
      } else {
        return <FontAwesomeIcon icon={faSortDesc} />
      }
    } else {
      return <FontAwesomeIcon icon={faSort} />
    }
  }

  const deleteRow = vehicle => e => {
    return Swal.fire({
      title: 'Confirmar?',
      showDenyButton: true,
      showCancelButton: true,
      icon: 'warning',
      confirmButtonText: 'Deletar',
      denyButtonText: 'Cancelar',
      confirmButtonColor: "#DD6B55",
      html: `<b>Você não poderá reverter esta ação!</b>`,
    }).then((result) => {
      if (result.isConfirmed)
        return vehiclesRepository.delete(vehicle.id)
      else
        return Promise.reject('')
    })
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left" colSpan={1}><AddIconButton onClick={newItem} disabled={!authorizeChecker(auth)} /></TableCell>
            <TableCell align="right" colSpan={5}>{filteredVehicles.length} itens</TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="center" onClick={orderBy('brand')} className={classes.noWrap}>Marca / Modelo <SortIcon orderBy="brand" /></TableCell>
            <TableCell align="center" onClick={orderBy('plate')} className={classes.noWrap}>Placa / Dia de Rodízio <SortIcon orderBy="plate" /></TableCell>
            <TableCell align="center" onClick={orderBy('driverName')} className={classes.noWrap}>Motorista <SortIcon orderBy="driverName" /></TableCell>
            <TableCell align="center" onClick={orderBy('category')} className={classes.noWrap}>Categoria <SortIcon orderBy="category" /></TableCell>
            <TableCell align="center" onClick={orderBy('status')} className={classes.noWrap}>Status <SortIcon orderBy="status" /></TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {loading ?
            <TableRowLoadingComponent cols={6} buttons={2} />
            : filteredVehicles.map(row => (
              <TableRow key={row.id} className={classes.tableRow}>
                <TableCell className={classes.tableCell}>
                  <DeleteIconButton onClick={deleteRow(row)} disabled={!authorizeChecker(auth)} />
                  <EditIconButton onClick={viewMore(row)} />
                </TableCell>
                <TableCell align="center" className={classes.noWrap}><b>{row.brandName}</b> / {row.modelName}</TableCell>
                <TableCell align="center" className={classes.noWrap}>{row.plate} / <span style={{ color: row.rotationDayColor }}>{row.rotationDayName}</span></TableCell>
                <TableCell align="center" className={classes.noWrap}>{row.driverName}</TableCell>
                <TableCell align="center" className={classes.noWrap}>{row.categoryName}</TableCell>
                <TableCell align="center" className={classes.noWrap} style={{ color: row.statusColor }} >{row.statusName}</TableCell>
              </TableRow>
            ))}
          {!loading && !filteredVehicles[0] ? <TableRow key="none" className={classes.tableRow}>
            <TableCell component="th" scope="row" colSpan="7" align="center" style={{ height: "300px" }}>
              <b>Não há Veículos</b>
            </TableCell>
          </TableRow> : null}
        </TableBody>
        <TablePaginationComponent
          colSpan={6}
          count={pagination.total}
          rowsPerPage={pagination.perPage}
          page={pagination.page}
          loading={loading}
          onPageChange={value => { setPagination({ ...pagination, page: value }) }}
          onRowsPerPageChange={value => { setPagination({ ...pagination, page: 1, perPage: value }) }}
        />
      </Table>
    </TableContainer >)
}
