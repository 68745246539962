import React from "react";
import { Table, TableRow, TableBody, TableCell, TableHead, TableContainer, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faSortAsc, faSortDesc } from "@fortawesome/free-solid-svg-icons";

import {
  AddIconButton,
  DeleteIconButton,
  EditIconButton,
  TablePaginationComponent,
  TableRowLoadingComponent
} from "../../../shared/components";
import { StoreModel } from "../../../shared/models";
import { StoresRepository } from "../../../shared/repositories";
import { useDrawerRightContext } from "../../../shared/contexts/drawer_right.context";
import { removeSpecialCharsLowerCase, sortBy } from "../../../shared/helpers";
import Swal from "sweetalert2";

const useStyles = makeStyles(theme => ({
  noWrap: {
    whiteSpace: "nowrap"
  },
  tableBody: {
    overflowX: "auto",
    maxHeight: 500
  }
}));

export function StoresListComponent({ loading, stores = [], filters = {}, setFilters = () => { }, setStores = () => { }, setLoading = () => { } }) {
  const [pagination, setPagination] = React.useState({ page: 1, perPage: 10, total: 0 });
  const [filteredStores, setFilteredStores] = React.useState([]);
  const storesRepository = new StoresRepository()
  const classes = useStyles();
  const { setNewDrawerValue } = useDrawerRightContext();

  const viewMore = company => e => {
    e.preventDefault();
    setNewDrawerValue(company.toState())
  }

  const newItem = e => {
    e.preventDefault();
    setNewDrawerValue(new StoreModel().toState())
  }

  React.useEffect(() => {
    setLoading(true);
    storesRepository.getAll({
      page: pagination.page,
      perPage: pagination.perPage,
    })
      .then(result => {
        setStores(StoreModel.fromArray(result.data))
        setPagination({ ...pagination, total: result.total })
        setLoading(false)
      })
  }, [pagination.page, pagination.perPage]);

  React.useEffect(() => {
    filter()
  }, [filters, stores]);

  const filter = () => {
    setFilteredStores([])
    let resp = stores.filter(store =>
      (!filters.name || removeSpecialCharsLowerCase(store.name && store.name).includes(removeSpecialCharsLowerCase(filters.name))) &&
      (!filters.document || removeSpecialCharsLowerCase(store.document && store.document).includes(removeSpecialCharsLowerCase(filters.document)))
    )
    setFilteredStores(order(resp))
  }

  const order = (stores) => {
    return sortBy(stores, filters.orderBy, filters.orderDirection)
  }

  const orderBy = (orderBy) => e => {
    setFilters({ ...filters, orderBy: orderBy, orderDirection: filters.orderDirection === 'asc' ? 'desc' : 'asc' })
  }

  const SortIcon = ({ orderBy }) => {
    if (filters.orderBy === orderBy) {
      if (filters.orderDirection === 'asc') {
        return <FontAwesomeIcon icon={faSortAsc} />
      } else {
        return <FontAwesomeIcon icon={faSortDesc} />
      }
    } else {
      return <FontAwesomeIcon icon={faSort} />
    }
  }

  const deleteRow = store => e => {
    return Swal.fire({
      title: 'Confirmar?',
      showDenyButton: true,
      showCancelButton: true,
      icon: 'warning',
      confirmButtonText: 'Deletar',
      denyButtonText: 'Cancelar',
      confirmButtonColor: "#DD6B55",
      html: `<b>Você não poderá reverter esta ação!</b>`,
    }).then((result) => {
      if (result.isConfirmed)
        return storesRepository.delete(store.id)
      else
        return Promise.reject('')
    })
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left" colSpan={1}><AddIconButton onClick={newItem} /></TableCell>
            <TableCell align="right" colSpan={5}>{filteredStores.length} itens</TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="center" onClick={orderBy('name')} className={classes.noWrap}>Nome <SortIcon orderBy="name" /></TableCell>
            <TableCell align="center" onClick={orderBy('document')} className={classes.noWrap}>Documento <SortIcon orderBy="document" /></TableCell>
            <TableCell align="center">Telefone</TableCell>
            <TableCell align="center">E-mail</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {loading ?
            <TableRowLoadingComponent cols={5} />
            : filteredStores.map(row => (
              <TableRow key={row.id} className={classes.tableRow}>
                <TableCell className={classes.tableCell}>
                  <DeleteIconButton onClick={deleteRow(row)} />
                  <EditIconButton onClick={viewMore(row)} />
                </TableCell>
                <TableCell align="center" className={classes.noWrap}><b>{row.name}</b></TableCell>
                <TableCell align="center" className={classes.noWrap}>{row.document}</TableCell>
                <TableCell align="center" className={classes.noWrap}>{row.phoneNumberFormatted}</TableCell>
                <TableCell align="center" className={classes.noWrap}>{row.emailAddress}</TableCell>
              </TableRow>
            ))}
          {!loading && !filteredStores[0] ? <TableRow key="none" className={classes.tableRow}>
            <TableCell component="th" scope="row" colSpan="5" align="center" style={{ height: "300px" }}>
              <b>Não há empresas</b>
            </TableCell>
          </TableRow> : null}
        </TableBody>
        <TablePaginationComponent
          colSpan={5}
          count={pagination.total}
          rowsPerPage={pagination.perPage}
          page={pagination.page}
          loading={loading}
          onPageChange={value => { setPagination({ ...pagination, page: value }) }}
          onRowsPerPageChange={value => { setPagination({ ...pagination, page: 1, perPage: value }) }}
        />
      </Table>
    </TableContainer >)
}
