/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import {
  SectorsListComponent,
  SectorsSearchComponent,
  SectorsDetailComponent
} from "./components";
import { AdminLayout } from "../../shared/layouts/admin.layout";

const INITIAL_FILTER = {
  name: "",
  legalSectorId: "",
  page: 1,
  perPage: 10,
  orderBy: 'legacy_sector_id',
  orderDirection: 'asc',
}

export function SectorsPage() {
  const [filters, setFilters] = React.useState(INITIAL_FILTER);
  const [sectors, setSectors] = React.useState([]);
  const [loading, setLoading] = React.useState(false);


  const update = (sector) => {
    const index = sectors.findIndex(item => item.id === sector.id)
    if (index > -1) {
      sectors[index] = sector
      setSectors([...sectors])
    } else {
      setSectors([...sectors, sector])
    }
  }

  return (
    <AdminLayout
      DrawerRightComponent={<SectorsDetailComponent update={update} />}
      HeaderComponent={
        <SectorsSearchComponent
          setFilters={setFilters}
          INITIAL_FILTER={INITIAL_FILTER}
          setLoading={setLoading} />
      }
      BodyComponent={<SectorsListComponent
        filters={filters}
        setFilters={setFilters}
        sectors={sectors}
        setSectors={setSectors}
        loading={loading}
        setLoading={setLoading} />
      }
    />
  );
}

