import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, MenuItem, List, ListItem } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import {
  DateInputFieldComponent,
  NumberInputFieldComponent,
  SelectionInputFieldComponent,
  TextInputFieldComponent,
  BackButton,
  SaveButton
} from "../../../shared/components";
import {
  authorizeChecker,
  Constants
} from "../../../shared/helpers";
import {
  UsersRepository,
  VehiclesRepository
} from "../../../shared/repositories";
import { VehicleModel } from "../../../shared/models";
import { RestrictedDatesAutoCompleteRegion } from "../../RestrictedDates/components/restricted_dates_auto_complete_region.component";
import { useDrawerRightContext } from "../../../shared/contexts/drawer_right.context";

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '500px',
    [theme.breakpoints.up('sm')]: {
      minWidth: '400px',
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '500px',
    }
  },
  gridItem: {
  },
  textField: {
    paddingRight: theme.spacing(1),
  }
}));

export function VehiclesDetailComponent({ update }) {
  const auth = useSelector(state => state.auth);
  const classes = useStyles();
  const [vehicle, setVehicle] = useState({});
  const vehiclesRepository = new VehiclesRepository();
  const { drawerValue, toggleDrawerRightOpen } = useDrawerRightContext();
  const usersRepository = new UsersRepository();
  const [drivers, setDrivers] = useState([]);

  const saveVehicle = _ => {
    const vehicleToSave = new VehicleModel(vehicle);
    return vehiclesRepository[vehicleToSave.id ? 'update' : 'create'](vehicleToSave.toJSON())
      .then(vehicle => {
        update(vehicle)
        setVehicle(vehicle.toState())
      });
  }

  useEffect(() => {
    if (drivers.length == 0) {
      usersRepository.getAll({ queryParams: `roles=${Constants.User.Role.Driver}`, perPage: 1000 })
        .then(result => {
          setDrivers(result.data)
        })
    }
    setVehicle(drawerValue);
  }, [drawerValue]);

  const handleChange = name => event => {
    setVehicle({ ...vehicle, [name]: event.target.value });
  };

  const handleDateChange = name => date => {
    setVehicle({ ...vehicle, [name]: date ? date._d : null });
  };

  const handleChangeRegion = options => {
    setVehicle({ ...vehicle, regions: options });
  };

  const goBack = () => {
    toggleDrawerRightOpen()
  }

  const models = Constants.Vehicle.Models.filter(model => model.brand === vehicle.brand);

  return (
    <Grid className={classes.root}>
      <List>
        <ListItem className={classes.gridItem}>
          <Typography variant="h6" gutterBottom>Dados do Veículo</Typography>
        </ListItem >
        <ListItem className={classes.gridItem}>
          <TextInputFieldComponent
            id="driver-id-select"
            select
            label="Motorista"
            value={vehicle.driverId}
            onChange={handleChange("driverId")}>
            {drivers.map((option, index) => (
              <MenuItem key={index} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextInputFieldComponent>
        </ListItem >
        <ListItem className={classes.gridItem}>
          <DateInputFieldComponent
            id="lastReview"
            label="Última Revisão"
            value={vehicle.lastReview}
            error={false}
            className={classes.textField}
            helperText={null}
            onChange={handleDateChange('lastReview')}
          />
        </ListItem >
        <ListItem className={classes.gridItem}>
          <DateInputFieldComponent
            id="nextReview"
            label="Próxima Revisão"
            value={vehicle.nextReview}
            error={false}
            className={classes.textField}
            helperText={null}
            onChange={handleDateChange('nextReview')}
          />
        </ListItem >
        <ListItem className={classes.gridItem}>
          <TextInputFieldComponent
            label="Placa"
            value={vehicle.plate}
            onChange={handleChange('plate')}
          />
        </ListItem >
        <ListItem className={classes.gridItem}>
          <SelectionInputFieldComponent
            id="brand-select"
            label="Marca"
            value={vehicle.brand}
            nullable={true}
            onChange={handleChange("brand")}
            options={Constants.Vehicle.Brands}
          />
        </ListItem>
        <ListItem className={classes.gridItem}>
          <SelectionInputFieldComponent
            id="model-select"
            label="Modelo"
            disabled={!vehicle.brand}
            value={vehicle.model}
            onChange={handleChange("model")}
            options={models}
          />
        </ListItem >
        <ListItem className={classes.gridItem}>
          <SelectionInputFieldComponent
            id="color-select"
            label="Cor"
            value={vehicle.color}
            onChange={handleChange("color")}
            options={Constants.Vehicle.Colors}
          />
        </ListItem >
        <ListItem className={classes.gridItem}>
          <SelectionInputFieldComponent
            id="category-select"
            label="Categoria"
            value={vehicle.category}
            onChange={handleChange("category")}
            options={Constants.Vehicle.Categories}
          />
        </ListItem >
        <ListItem className={classes.gridItem}>
          <NumberInputFieldComponent
            id="height-input"
            label="Altura"
            value={vehicle.height}
            onChange={handleChange('height')}
            decimalSeparator=","
            thousandSeparator="."
            suffix=" cm"
            customInput={TextInputFieldComponent}
          />
        </ListItem >
        <ListItem className={classes.gridItem}>
          <NumberInputFieldComponent
            id="width-input"
            label="Largura"
            value={vehicle.width}
            onChange={handleChange('width')}
            decimalSeparator=","
            thousandSeparator="."
            suffix=" cm"
            customInput={TextInputFieldComponent}
          />
        </ListItem >
        <ListItem className={classes.gridItem}>
          <NumberInputFieldComponent
            id="depth-input"
            label="Profundidade"
            value={vehicle.depth}
            onChange={handleChange('depth')}
            decimalSeparator=","
            thousandSeparator="."
            suffix=" cm"
            customInput={TextInputFieldComponent}
          />
        </ListItem >
        <ListItem className={classes.gridItem}>
          <NumberInputFieldComponent
            id="capacity-input"
            label="Capacidade"
            value={vehicle.capacity}
            onChange={handleChange('capacity')}
            decimalSeparator=","
            thousandSeparator="."
            suffix=" Kg"
            customInput={TextInputFieldComponent}
          />
        </ListItem >
        <ListItem className={classes.gridItem}>
          <SelectionInputFieldComponent
            id="status-select"
            label="Status"
            value={vehicle.status}
            onChange={handleChange("status")}
            options={Constants.Vehicle.Statuses}
          />
        </ListItem >
        <ListItem>
          <RestrictedDatesAutoCompleteRegion restrictedDate={vehicle} onChange={handleChangeRegion} />
        </ListItem>
        <ListItem className={classes.gridItem}>
          <TextInputFieldComponent
            id="created_at"
            name="created_at"
            label="Data de criação"
            disabled
            value={vehicle.created_at ? new Date(vehicle.created_at).toLocaleString() : ""}
          />
        </ListItem >
        <ListItem className={classes.gridItem}>
          <TextInputFieldComponent
            id="updated_at"
            name="updated_at"
            label="Ultima atualização"
            disabled
            value={vehicle.updated_at ? new Date(vehicle.updated_at).toLocaleString() : ""}
          />
        </ListItem >
        <ListItem className={classes.gridItem} style={{ display: "flex", justifyContent: "space-between" }}>
          <BackButton onClick={goBack} />
          <SaveButton onClick={saveVehicle} disabled={!authorizeChecker(auth)} />
        </ListItem >
      </List >
    </Grid >
  );
}
