/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import { MeDetailComponent } from "./components";
import { AdminLayout } from "../../shared/layouts/admin.layout";

export function MePage() {

  return (
    <AdminLayout BodyComponent={<MeDetailComponent />} />
  );

}
