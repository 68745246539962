import React from "react";
import { Table, TableRow, TableBody, TableCell, TableHead, TableContainer, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faSortAsc, faSortDesc } from "@fortawesome/free-solid-svg-icons";

import {
  AddIconButton,
  EditIconButton,
  TablePaginationComponent,
  TableRowLoadingComponent
} from "../../../shared/components";
import { CompaniesRepository } from "../../../shared/repositories";
import { CompanyModel } from "../../../shared/models";
import { useDrawerRightContext } from "../../../shared/contexts/drawer_right.context";
import { removeSpecialChars } from "../../../shared/helpers";

const useStyles = makeStyles(theme => ({
  noWrap: {
    whiteSpace: "nowrap"
  },
  tableBody: {
    overflowX: "auto",
    maxHeight: 500
  }
}));

export function CompaniesListComponent({ loading, companies = [], filters = {}, setFilters = () => { }, setCompanies = () => { }, setLoading = () => { } }) {
  const companiesRepository = new CompaniesRepository();
  const [pagination, setPagination] = React.useState({ page: 1, perPage: 10, total: 0 });
  const classes = useStyles();
  const { setNewDrawerValue } = useDrawerRightContext();

  const viewMore = company => e => {
    e.preventDefault();
    setNewDrawerValue(company.toState())
  }

  const newItem = e => {
    e.preventDefault();
    setNewDrawerValue(new CompanyModel().toState())
  }

  React.useEffect(() => {
    setLoading(true)
    const timer = setTimeout(() => {
      getCompanies()
    }, 1000);
    return () => clearTimeout(timer);
  }, [pagination.page, pagination.perPage, filters]);

  const getCompanies = () => {
    companiesRepository.getAll({
      queryParams: resolveFilters(),
      page: pagination.page,
      perPage: pagination.perPage,
      orderBy: filters.orderBy,
      orderDirection: filters.orderDirection
    })
      .then(result => {
        setCompanies(CompanyModel.fromArray(result.data))
        setPagination({ ...pagination, total: result.total })
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => setLoading(false))
  }

  const resolveFilters = () => {
    let query = [];

    if (filters.name) {
      query.push(`name=${removeSpecialChars(filters.name)}`)
    }

    if (filters.document) {
      query.push(`document=${filters.document}`)
    }

    return query.join("|");
  }

  const orderBy = (orderBy) => e => {
    setFilters({ ...filters, orderBy: orderBy, orderDirection: filters.orderDirection === 'asc' ? 'desc' : 'asc' })
  }

  const SortIcon = ({ orderBy }) => {
    if (filters.orderBy === orderBy) {
      if (filters.orderDirection === 'asc') {
        return <FontAwesomeIcon icon={faSortAsc} />
      } else {
        return <FontAwesomeIcon icon={faSortDesc} />
      }
    } else {
      return <FontAwesomeIcon icon={faSort} />
    }
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left" colSpan={1}><AddIconButton onClick={newItem} /></TableCell>
            <TableCell align="right" colSpan={5}>{companies.length} itens</TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="center" onClick={orderBy('name')} className={classes.noWrap}>Nome <SortIcon orderBy="name" /></TableCell>
            <TableCell align="center">Documento</TableCell>
            <TableCell align="center">Max entregas/dia</TableCell>
            <TableCell align="center">Max peso/dia</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {loading ?
            <TableRowLoadingComponent cols={5} />
            : companies.map(row => (
              <TableRow key={row.id} className={classes.tableRow}>
                <TableCell className={classes.tableCell}>
                  <EditIconButton onClick={viewMore(row)} />
                </TableCell>
                <TableCell align="center" className={classes.noWrap}><b>{row.name}</b></TableCell>
                <TableCell align="center" className={classes.noWrap}>{row.document}</TableCell>
                <TableCell align="center" className={classes.noWrap}>{row.maxDeliveryInDay}</TableCell>
                <TableCell align="center" className={classes.noWrap}>{row.maxWeightInDay}</TableCell>
              </TableRow>
            ))}
          {!loading && !companies[0] ? <TableRow key="none" className={classes.tableRow}>
            <TableCell component="th" scope="row" colSpan="5" align="center" style={{ height: "300px" }}>
              <b>Não há empresas</b>
            </TableCell>
          </TableRow> : null}
        </TableBody>
        <TablePaginationComponent
          colSpan={5}
          count={pagination.total}
          rowsPerPage={pagination.perPage}
          page={pagination.page}
          loading={loading}
          onPageChange={value => { setPagination({ ...pagination, page: value }) }}
          onRowsPerPageChange={value => { setPagination({ ...pagination, page: 1, perPage: value }) }}
        />
      </Table>
    </TableContainer >)
}
