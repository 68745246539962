import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Menu, MenuItem, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {
  ClientsRepository,
  DeliveriesRepository,
  StoresRepository,
  UsersRepository
} from '../../../shared/repositories';
import {
  BetweenDateInputFieldComponent,
  TextInputFieldComponent,
  ClearButton,
  CreatePdfButton
} from '../../../shared/components';
import { Constants } from '../../../shared/helpers';
import { CreatePdfOrXlsxButton } from '../../../shared/components/Buttons/createPdfOrXlsxButton.component';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeightPaper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    minHeight: 380,
  },
  gridItem: {
    padding: theme.spacing(1),
  },
  gridItemDate: {
    paddingRight: theme.spacing(3),
  },
}));

export function ReportsDeliveriesDriversComponent(props) {
  const classes = useStyles();
  const deliveriesRepository = new DeliveriesRepository();
  const usersRepository = new UsersRepository();
  const storesRepository = new StoresRepository();
  const [chiefDrivers, setChiefDrivers] = React.useState([]);
  const [stores, setStores] = React.useState([]);
  const [disabledDriver, setDisabledDriver] = React.useState(true);
  const [disabled, setDisabled] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filters, setFilters] = React.useState({
    startDate: null,
    endDate: null,
    parent: null,
    storeId: "all",
    freightType: "all",
    responseType: "pdf"
  });

  const handleMenu = (event) => {
    console.log(event.currentTarget);
    setAnchorEl(event.currentTarget);
  };

  const handleChange = name => event => {
    setFilters({ ...filters, [name]: event.target.value });
  }

  const handleChangeDate = name => date => {
    handleChange(name)({ target: { value: date ? date._d : null } });
  };

  React.useEffect(() => {
    if (isValidDate(filters.startDate)) {
      setDisabled(false);
      if (filters.parent) {
        setDisabledDriver(false);
      } else {
        setDisabledDriver(true);
      }
    } else {
      setDisabledDriver(true);
      setDisabled(true);
    }
  }, [filters]);

  React.useEffect(() => {
    storesRepository.getAll({ perPage: 1000 }).then(result => {
      setStores(result.data);
    })
    usersRepository.getAll({ queryParams: `roles=${Constants.User.Role.ChiefDriver}` }).then(result => {
      setChiefDrivers(result.data);
    })
  }, []);

  const handleSubmitNotDelivered = e => {
    return deliveriesRepository.getQueryDownload({ query: resolveFilters(true), type: 'pdf', title: 'Entregas em aberto', fileName: `entregas-em-aberto-${resolveDate(filters.startDate)}-${resolveDate(filters.endDate)}.pdf` });
  }

  const handleSubmitDelivered = e => {
    return deliveriesRepository.getQueryDownload({ query: resolveFilters(), type: 'pdf', title: 'Entregas Baixadas', fileName: `entregas-baixadas-${resolveDate(filters.startDate)}-${resolveDate(filters.endDate)}.pdf` });
  }

  const handleSubmitDriver = e => {
    return usersRepository.getFreightDriverReport(
      filters.parent,
      resolveDate(filters.startDate),
      filters.endDate ? resolveDate(filters.endDate) : resolveDate(filters.startDate),
      filters.freightType !== "all" ? filters.freightType : undefined,
      filters.storeId !== "all" ? filters.storeId : undefined,
      filters.responseType
    );
  }

  const resolveFilters = (notDelivered = false) => {
    let query = [];

    // deliveredAt
    if (notDelivered) {
      if (isValidDate(filters.startDate)) {
        query.push(`expected_delivery_in=${resolveDate(filters.startDate)},${`${resolveDate(filters[isValidDate(filters.endDate) ? "endDate" : "startDate"])}`}`);
      }
      query.push(`status=${Object.values(Constants.Delivery.Status).filter(status => !['delivered', 'cancelled'].includes(status))}`)
    } else {
      if (isValidDate(filters.startDate)) {
        query.push(`delivered_at=${resolveDate(filters.startDate)},${`${resolveDate(filters[isValidDate(filters.endDate) ? "endDate" : "startDate"])}`}`);
      }
    }

    if (filters.freightType !== "all") {
      query.push(`freight_type=${filters.freightType}`);
    }

    if (filters.parent) {
      query.push(`parent_id=${filters.parent.id}`);
    }

    if (filters.storeId !== "all") {
      query.push(`store_id=${filters.storeId}`);
    }

    return query.join("|");
  }

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  function resolveDate(date) {
    return date ? date.toLocaleDateString('pt-BR').replaceAll('/', '-') : null;
  }

  return (
    <Paper className={classes.paper}>
      <Grid container >
        <Grid item xs={12} md={12}>
          <Typography variant="h6" gutterBottom>Entregas</Typography>
        </Grid>
      </Grid>
      <Grid container >
        <Grid item xs={12} md={6} className={classes.gridItem}>
          <TextInputFieldComponent
            id="parent"
            variant="outlined"
            select
            label="Motorista Chefe"
            value={filters.parent}
            onChange={handleChange("parent")}>
            {chiefDrivers.map((option) => (
              <MenuItem key={option} value={option}>
                {option.name}
              </MenuItem>
            ))}
          </TextInputFieldComponent>
        </Grid>
        <Grid item xs={12} md={6} className={classes.gridItem}>
          <TextInputFieldComponent
            id="freightType"
            select
            label="Tipo de Frete"
            value={filters.freightType}
            onChange={handleChange("freightType")}>
            {Constants.Delivery.FreightTypes.map((option) => (
              <MenuItem key={option.label} value={option.label}>
                {option.value}
              </MenuItem>
            ))}
          </TextInputFieldComponent>
        </Grid>
        <Grid item xs={12} md={6} className={classes.gridItem}>
          <TextInputFieldComponent
            id="storeId"
            select
            label="Loja"
            value={filters.storeId}
            onChange={handleChange("storeId")}>
            <MenuItem value="all">Todos</MenuItem>
            {stores?.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextInputFieldComponent>
        </Grid>
        <Grid item xs={12} md={12} className={classes.gridItemDate}>
          <BetweenDateInputFieldComponent
            id="delivery-at"
            label="Escolha um período"
            startDate={filters.startDate}
            endDate={filters.endDate}
            onChangeStart={handleChangeDate("startDate")}
            onChangeEnd={handleChangeDate("endDate")}
            error={disabled}
            errorText="Escolha um período"
          />
        </Grid>
        <Grid item xs={12} md={12} style={{ display: "flex", justifyContent: "flex-end" }}>
          <CreatePdfButton disabled={disabled} onClick={handleSubmitNotDelivered} >PDF Entregas em aberto</CreatePdfButton>
          <CreatePdfButton disabled={disabled} onClick={handleSubmitDelivered} >PDF Entregas Baixadas</CreatePdfButton>
          <CreatePdfOrXlsxButton disabled={disabledDriver} onClick={handleSubmitDriver} handleMenu={handleMenu}>Frete por Motorista</CreatePdfOrXlsxButton>
          <Menu keepMounted anchorOrigin={{ vertical: 'top', horizontal: 'right' }} transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
            <MenuItem onClick={handleSubmitDriver} >Frete por Motorista (XLSX)</MenuItem>
          </Menu>
          <ClearButton onClick={() => setFilters({ startDate: null, endDate: null, parent: null })} />
        </Grid>
      </Grid>
    </Paper >
  )
}
