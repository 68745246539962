/* eslint-disable no-use-before-define */
import React from 'react';
import { useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

import { TextInputFieldComponent } from '../../../shared/components';
import { RegionsRepository } from '../../../shared/repositories';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}));

export const RestrictedDatesAutoCompleteRegion = ({ restrictedDate, onChange }) => {
  const [regions, setRegions] = React.useState([]);
  const regionsRepository = new RegionsRepository();
  const classes = useStyles();

  React.useEffect(() => {
    if (regions.length === 0)
      regionsRepository.getAll({ page: 1, perPage: 1000 }).then(result => {
        setRegions(result.data)
      })
  }, [restrictedDate])

  return (
    <div className={classes.root}>
      <Autocomplete
        size="small"
        multiple
        id="tags-outlined"
        options={regions.filter(region => !(restrictedDate.regions?.map(region => region.id) || []).includes(region.id))}
        getOptionLabel={(option) => option.name}
        value={restrictedDate.regions?.map(region => ({ id: region.id, name: region.name })) || []}
        filterSelectedOptions
        onChange={(_, options) => onChange(options)}
        renderInput={(params) => (
          <TextInputFieldComponent
            {...params}
            variant="outlined"
            label="Regiões"
            InputLabelProps={{ shrink: true }}
          />
        )}
      />
    </div>
  );
}
