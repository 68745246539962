/* eslint-disable no-use-before-define */
import React from 'react';
import { useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

import { TextInputFieldComponent } from '../../../shared/components';
import { SectorsRepository } from '../../../shared/repositories';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}));

export const UsersAutoCompleteSector = ({ user, onChange }) => {
  const [sectors, setSectors] = React.useState([]);
  const sectorsRepository = new SectorsRepository();
  const classes = useStyles();

  React.useEffect(() => {
    if (sectors.length === 0)
      sectorsRepository.getAll({ page: 1, perPage: 1000, orderBy: 'legacy_sector_id' }).then(result => {
        setSectors(result.data)
      })
  }, [user]);

  return (
    <div className={classes.root}>
      <Autocomplete
        size="small"
        multiple
        id="tags-outlined"
        options={sectors.filter(sector => !(user.sectors?.map(sector => sector.id) || []).includes(sector.id))}
        getOptionLabel={(option) => `${option.legacySectorId} - ${option.name}`}
        value={user.sectors?.map(sector => ({ id: sector.id, name: sector.name })) || []}
        filterSelectedOptions
        onChange={(_, options) => onChange(options)}
        renderInput={(params) => (
          <TextInputFieldComponent
            {...params}
            variant="outlined"
            label="Setores"
            InputLabelProps={{ shrink: true }}
          />
        )}
      />
    </div>
  );
}
