export default class Entity {
	constructor(data, attrsNames) {
		this.data = data;
		attrsNames.map(attr => {
			this.bindAccessors(this, attr, data[attr]);
		})
	}

	bindAccessors(o, property, value) {
		Object.defineProperty(o, property, {
			get: function () {
				return value;
			},
			set: function (v) {
				value = v;
			}
		});
	}

	asJSON() {
		return this.data;
	}

	toJSON() {
		return this.data;
	}

	toState(customInput) {
		var obj = {};
		Object.keys(this.data).map(key => obj[key] = this.data[key]);
		if (customInput) {
			obj = Object.assign(obj, customInput);
		}
		return obj;
	}

	static fromJSON(data = {}) {
		var newData = {}
		Object.keys(data).map(key => { newData[capitalizeAssessor(key)] = data[key] });
		return new this(newData);
	}

	static fromArray(data = []) {
		return data.map(item => this.fromJSON(item));
	}
}


const capitalizeAssessor = (str) => {
	let firstWord = str.split("_")[0];
	let restWords = str.split("_").slice(1);
	return firstWord.toLowerCase() + restWords.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join("");
}
