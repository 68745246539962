import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Search from "@material-ui/icons/Search";
import { blue } from "@material-ui/core/colors";
import { DefaultButton } from "./";

const useStyles = makeStyles(theme => ({
  button: {
    borderRadius: "20px",
    backgroundColor: blue[500],
    '&:hover': {
      backgroundColor: blue[700],
    },
  },
}));
export function SearchButton({ onClick, disabled, ...props }) {
  const classes = useStyles();

  return (
    <DefaultButton
      className={classes.button}
      onClick={onClick}
      disabled={disabled}
      startIcon={<Search />}
      {...props}
    >
      {props.children || "Buscar"}
    </DefaultButton>
  );
}
