import { StoreModel } from "../models";
import { AuthRepository } from "./auth.repository";
import { Repository } from "./repository";

export class StoresRepository extends Repository {
  constructor() {
    super();
    this.authRepository = new AuthRepository();
  }

  async getAll({ queryParams = "", page = 1, perPage = 10, orderBy = "id", orderDirection = "asc" }) {
    return this.api.get({ path: `stores?${queryParams}&page=${page}&per_page=${perPage}&order_by=${orderBy}&order_direction=${orderDirection}`, headers: this.headers() })
      .then(response => {
        return Promise.resolve({ data: response.data, total: response.headers["total"] });
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.getAll.bind(this),
        callbackOnError: () => { this.dispatch({ type: "CLEAR_STORE" }) }
      }));
  }

  async create(store) {
    return this.api.post({ path: "stores", headers: this.headers(), body: { ...store } })
      .then(response => {
        this.newSnackbarSuccess(["Loja criada com sucesso"]);
        return Promise.resolve(StoreModel.fromJson(response.data));
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.create.bind(this),
      }));
  }

  async update(store) {
    return this.api.put({ path: `stores/${store.id}`, headers: this.headers(), body: this.omitKeys(store, ["id"]) })
      .then(response => {
        this.newSnackbarSuccess(["Loja atualizada com sucesso"]);
        return Promise.resolve(StoreModel.fromJson(response.data));
      }
      ).catch(error => this.resolveErrorResponse({
        error,
        callback: this.update
      }));
  }

  async delete(store) {
    return this.api.delete({ path: `stores/${store.id}`, headers: this.headers() })
      .then(response => {
        this.newSnackbarSuccess(["Loja deletada com sucesso"]);
        return Promise.resolve(response.data);
      }
      ).catch(error => this.resolveErrorResponse({
        error,
        callback: this.delete
      }));
  }
}
