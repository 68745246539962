import React from 'react';
import NumberFormat from 'react-number-format';


export const NumberInputFieldComponent = (props) => {
  const { inputRef, prefix, onChange, ...other } = props;
  const [value, setValue] = React.useState('')

  React.useEffect(() => {
    setValue(other.value || '')
  }, [other])

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      value={value}
      onValueChange={values => {
        setValue(values.value)
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      fixedDecimalScale
      isNumericString
      maxLength="20"
    />
  );
}
