import React from "react";
import * as Colors from "@material-ui/core/colors";
import DeleteForever from "@material-ui/icons/DeleteForever";
import { IconButton } from "./";
import { CircularProgress, Tooltip } from "@material-ui/core";

export function DeleteIconButton({ onClick = () => { }, disabled = false, ...props }) {
  const [status, setStatus] = React.useState('');

  const handleClick = e => {
    e.preventDefault();
    try {
      setStatus('loading');
      onClick().finally(_ => setStatus('success'))
    }
    catch (error) {
      setStatus('error');
    }
  }

  return (
    <IconButton
      style={{
        color: Colors.red[500],
        '&:hover': {
          backgroundColor: Colors.red[700],
          color: 'white',
        },
      }}
      {...props}
      disabled={disabled || status === 'loading'}
      onClick={handleClick}
      show={true}
    >
      <Tooltip title="Deletar">{status === 'loading' ? <CircularProgress size={24} /> : <DeleteForever />}</Tooltip>
    </IconButton>
  );
}
