import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { green } from "@material-ui/core/colors";
import { SaveButton } from "./saveButton.component";

const useStyles = makeStyles(theme => ({
  button: {
    borderRadius: "20px",
    backgroundColor: green[700],
    '&:hover': {
      backgroundColor: green[500],
    },
  },
}));

export function CreateXlsxButton(props) {
  const classes = useStyles();
  const { onClick } = props;

  return (
    <SaveButton
      className={classes.button}
      onClick={onClick}
      {...props}
      startIcon={<FontAwesomeIcon icon={faFileExcel} />}
    >
      {props.children || "Baixar Excel"}
    </SaveButton>
  );
}
