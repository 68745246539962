export default {
  "pt-br": {
    calendars: {
      statuses: {
        available: "Disponível",
        blocked: "Bloqueado",
        warning: "Alerta"
      }
    },
    delivery: {
      id: "ID",
      created_at: "Criado em",
      updated_at: "Atualizado em",
      legacy_order_id: "Pedido",
      status: "Status",
      lasted_status: "Status anterior",
      lasted_status_update: "Última atualização de status",
      client_id: "Cliente",
      client: "Cliente",
      client_name: "Cliente",
      phone_number: "Telefone",
      email_address: "E-mail",
      driver_id: "Motorista",
      driver: "Motorista",
      seller_id: "Vendedor",
      seller: "Vendedor",
      full_address: "Endereço",
      freight: "Frete",
      freight_type: "Tipo do Frete",
      weight: "Peso",
      remark: "Observação",
      problem_description: "Descrição do problema",
      checker_id: "Conferente",
      checker: "Conferente",
      separated_at: "Separado em",
      delivered_at: "Entregue em",
      checked_at: "Conferido em",
      delivered_by_id: "Entregue por",
      delivered_by: "Entregue por",
      delivered_to: "Entregue para",
      parent_id: "Responsável",
      parent: "Responsável",
      address_zip_code: "Cep",
      address_street: "Rua",
      address_number: "Número",
      address_complement: "Complemento",
      address_neighborhood: "Bairro",
      address_city: "Cidade",
      address_state: "Estado",
      region_id: "Região",
      expected_delivery_in: "Data de entrega prevista",
      expected_delivery_in_between: "Entrega prevista entre",
      store_id: "Loja",
      store: "Loja",
      duplicate_reason: "Motivo de Duplicar",
      duplicate_remark: "Motivo de Duplicar - Observações",
      status_updated_at: "Status atualizado em",
      sms_will_be_delivered_today_at: "Sms será entregue hoje em",
      sms_out_for_delivery_at: "Sms saiu para entrega em",
      sms_delivered_at: "Sms entregue em",
      out_for_delivery_at: "Saiu para entrega em",
      company_id: "Empresa",
      company: "Empresa",
    }
  }
}
