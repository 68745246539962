import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

import { TextInputFieldComponent } from '../../../shared/components';
import { RegionsRepository } from '../../../shared/repositories';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}));

export const RestrictedDatesAutoCompleteRegion = ({ vehicle, onChange }) => {
  const [regions, setRegions] = React.useState([]);
  const regionsRepository = new RegionsRepository();
  const classes = useStyles();

  React.useEffect(() => {
    regionsRepository.getAll({ page: 1, perPage: 1000 }).then(result => {
      setRegions(result.data)
    })
  }, [])

  const filteredRegions = () => {
    const regionsIds = vehicle.regions.map(region => region.id)
    return regions.filter(region => !regionsIds.includes(region.id))
  }

  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        size="small"
        id="tags-outlined"
        options={filteredRegions()}
        getOptionLabel={(option) => option.name}
        value={vehicle.regions}
        filterSelectedOptions
        onChange={(_, options) => onChange(options)}
        renderInput={(params) => (
          <TextInputFieldComponent
            {...params}
            variant="outlined"
            label="Regiões"
            InputLabelProps={{ shrink: true }}
          />
        )}
      />
    </div>
  );
}
