import { AuthRepository } from "./auth.repository";
import { Repository } from "./repository";

export class RestrictedDatesRepository extends Repository {
  constructor() {
    super();
    this.authRepository = new AuthRepository();
  }

  async getAll({ queryParams = "", page = 1, perPage = 10, orderBy = "start_date", orderDirection = "asc" }) {
    return this.api.get({ path: `restricted_dates?q=${queryParams}&page=${page}&per_page=${perPage}&order_by=${orderBy}&order_direction=${orderDirection}`, headers: this.headers() })
      .then(response => {
        return Promise.resolve({ data: response.data, total: response.headers['total'] });
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.getAll.bind(this)
      }));
  }

  async create(restrictedDates) {
    return this.api.post({ path: "restricted_dates", headers: this.headers(), body: { restricted_date: this.omitKeys(restrictedDates, ["id"]) } })
      .then(response => {
        this.newSnackbarSuccess("Criado com sucesso!");
        return Promise.resolve(response.data);
      }
      ).catch(error => this.resolveErrorResponse({
        error,
        callback: this.create.bind(this),
      }));
  }

  async update(restrictedDates) {
    return this.api.put({ path: `restricted_dates/${restrictedDates.id}`, headers: this.headers(), body: { restricted_date: this.omitKeys(restrictedDates, ["id"]) } })
      .then(response => {
        this.newSnackbarSuccess("Atualizado com sucesso!");
        return Promise.resolve(response.data);
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.update.bind(this),
      }));
  }

  async delete(id) {
    return this.api.delete({ path: `restricted_dates/${id}`, headers: this.headers() })
      .then(response => {
        this.newSnackbarSuccess([`Deletado com sucesso!`]);
        return Promise.resolve(response.data);
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.delete.bind(this),
      }));
  }

}
