
import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  set_restricted_date: [
    'id', 'reason', 'startDate', 'endDate', 'description', 'status',
    'regions', 'region_ids', 'createdAt', 'updatedAt'
  ],
  change_restricted_date: [
    'id', 'reason', 'startDate', 'endDate', 'description', 'status',
    'regions', 'region_ids', 'createdAt', 'updatedAt'
  ],
  clear_restricted_date: []
})

const INITIAL_STATE = {
  id: '',
  reason: '',
  startDate: '',
  endDate: '',
  description: '',
  status: '',
  regions: [],
  region_ids: '',
  createdAt: '',
  updatedAt: '',
}

const set_restricted_date = (state = INITIAL_STATE, action) => {
  return action.value
}

const change_restricted_date = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.value
  }
}

const clear_restricted_date = (state = INITIAL_STATE, action) => {
  return INITIAL_STATE
}

export default createReducer(INITIAL_STATE,
  {
    [Types.SET_RESTRICTED_DATE]: set_restricted_date,
    [Types.CHANGE_RESTRICTED_DATE]: change_restricted_date,
    [Types.CLEAR_RESTRICTED_DATE]: clear_restricted_date,
  })
