import React from 'react';
import ReactInputMask from 'react-input-mask';
import { omitKeys } from '../../../helpers';
import { TextInputFieldComponent } from '../';

export const NumberPhoneInputFieldComponent = (props) => {
  const { value } = props;

  return (
    <ReactInputMask
      mask="(99) 99999-9999"
      maskChar=" "
      {...props}
      value={value || ""}
    >
      {(i) => <TextInputFieldComponent
        id="phoneNumber"
        label="Telefone"
        {...omitKeys(props, ['onChange'])}
      />}
    </ReactInputMask>
  );
}
