/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Typography } from "@material-ui/core";

import {
  TextInputFieldComponent,
  DefaultButton
} from "../../../shared/components";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  gridItem: {
    padding: theme.spacing(1),
  },
  container: {
    marginTop: theme.spacing(1),
    overflowX: 'auto',
  },
  gridItemDate: {
    display: 'flex',
  },
}));

export function SectorsSearchComponent({ setFilters, INITIAL_FILTER, setLoading }) {
  const classes = useStyles();
  const [localFilters, setLocalFilters] = React.useState(INITIAL_FILTER)

  const clearFilters = () => {
    setLocalFilters(INITIAL_FILTER)
    setFilters(INITIAL_FILTER);
  }

  React.useEffect(() => {
    setLoading(true)
    let waitSec = setTimeout(() => {
      setFilters(localFilters);
    }, 1000);
    return () => clearTimeout(waitSec);
  }, [localFilters]);

  const handleChange = name => event => {
    setLocalFilters({ ...localFilters, [name]: event.target.value });
  };

  return (
    <Paper className={classes.paper}>
      <Grid container >
        <Grid item xs={12} md={12}>
          <Typography variant="h6" gutterBottom>Setores</Typography>
        </Grid>
      </Grid>
      <Grid container >
        <Grid item xs={12} md={6} className={classes.gridItem}>
          <TextInputFieldComponent
            id="legacySectorId-input"
            label="Codigo"
            value={localFilters.legacySectorId}
            onChange={handleChange("legacySectorId")}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.gridItem}>
          <TextInputFieldComponent
            id="name-input"
            label="Nome"
            value={localFilters.name}
            onChange={handleChange("name")}
          />
        </Grid>
        <Grid item xs={12} md={12} style={{ display: "flex", justifyContent: "flex-end" }}>
          <DefaultButton onClick={clearFilters}>Limpar Filtros</DefaultButton>
        </Grid>
      </Grid>
    </Paper >)
}
